.Nav--pagination {
    border-top: 2px solid $cc-black;
    width: calc(100% - #{u(120)});
    padding-top: u($spacing-unit * 2);

    .Nav {
        &-item {
            padding-right: u($spacing-unit * 2);
            &::after {
                display: none;
            }
        }

        &-link {
            font-weight: 600;

            &,
            &:active,
            &:focus,
            &:hover {
                color: rgba($cc-black, 0.35);
            }

            &:hover,
            &.is-active {
                color: $cc-black;
            }
        }
    }
}