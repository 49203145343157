/* Settings: Fonts */

// Default
$font-sans:                             "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-serif:                            Georgia, "Times New Roman", Times, serif !default;
$font-code:                             Menlo, Monaco, Consolas, monospace, serif !default;

// Custom
$font-gordita:                          "Gordita", sans-serif !default; // 400, 500, 700
$font-montserrat:                       "Montserrat", sans-serif !default; // 300, 400, 500, 600, 700
$font-droidserif:                       "DroidSerif", serif !default; // 400, 700
