.Block--maps {
    overflow: hidden;
    position: relative;
    padding-bottom: 0;

    .Bg-title {
        @include position(absolute, $top: 25%, $right: u($spacing-unit--lg * -1));
    }

    @include mq($from: $viewport--md) {
        padding-top: 0;

        .Block + & {
            margin-top: 0;
        }
    }
}

.Maps {

    @include mq($from: $viewport--md) {
        display: flex;

        &-body {
            flex-grow: 1;
            margin-right: u($spacing-unit--lg);
            padding-top: u(120);
            padding-bottom: u(120);
        }

        &-object {
            @include position(relative, $z: 0);
            max-width: u(505 - 87.5);
            width: 50%;
        }
    }

    &-object {

        @include mq($until: $viewport--md - 1) {
            margin-top: u($spacing-unit--lg);
            margin-right: u($structure-responsive-gutter--r * -1);
            margin-left: u($structure-responsive-gutter--l * -1);
        }
    }
}

.GoogleMaps {

    &-item {

        @include mq($until: $viewport--md - 1) {
            padding-bottom: (100% / 4 * 3);
        }
    }

    @include mq($from: $viewport--md) {
        @include position(absolute, $top: 0, $bottom: 0, $left: 0, $z: 1);

        @include mq($from: $viewport--md, $until: $structure-responsive-breakpoint - 1) {
            right: u($structure-gutter--l * -1);
        }

        @include mq($from: $structure-responsive-breakpoint) {
            right: calc((100vw - #{u($structure-width)}) / -2);
        }

        &-item {
            @include dimensions(100%);
        }
    }
}
