.Video--overlay {
    @include position(fixed, $top: 0, $left: 0, $z: 9999);
    @include transition(#{opacity, visibility});
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    padding-top: u($structure-header-height * 1.5);
    padding-bottom: u($structure-header-height * 1.5);

    .Container,
    &-object {
        height: 100%;
    }

    &-object {
        @include transition(#{opacity, visibility});
        opacity: 0;
        visibility: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        .Block.show-video-overlay & {
            opacity: 1;
            visibility: visible;
        }
    }

    video {
        @include transition(#{opacity, visibility});
        opacity: 0;
        visibility: hidden;
        width: 100%;
        max-height: 100%;

        .Block.show-video-overlay & {
            opacity: 1;
            visibility: visible;
            transition-delay: 1s;
        }

        .Block:not(.show-video-overlay) & {
            transition-duration: 0ms;
        }
    }

    .Close {
        @include dimensions($toggle-width, $toggle-height);
        @include position(absolute, $top: u((($structure-header-height * 1.5) - ($structure-header-height / 2)) * -1), $right: u($spacing-unit));
        cursor: pointer;
        transform: translateY(-50%);

        @include mq($from: $viewport--md) {
            right: u($spacing-unit--lg);
        }

        @include mq($from: $viewport--lg) {
            right: u(80);
        }

        &-item {
            @include dimensions(100%, $toggle-border-width);
            @include position(absolute);
            background-color: $cc-black;
            border-radius: px($toggle-border-radius);
            display: block;

            &:nth-child(1) {
                top: u($toggle-height / 2 - $toggle-border-width / 2);
                transform: rotate(135deg);
            }

            &:nth-child(2) {
                top: u($toggle-height / 2 - $toggle-border-width / 2);
                transform: rotate(-135deg);
            }
        }
    }

    &::before {
        @include transition(height, 500ms, ease-in-out);
        @include position(fixed, $top: 0, $right: 0, $left: 0, $z: -1);
        background-color: $cc-xperify-grey--light;

        content: '';
        height: 0;

        .Block.show-video-overlay & {
            height: 100vh;
        }
    }

    .Block.show-video-overlay & {
        opacity: 1;
        visibility: visible;
    }
}

html.show-video-overlay .Header {
    z-index: 1;
}