/* Main */
.Nav--main {

    .Header & {
        @include grid-area(1, 2, 2, 3);
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;

        @include mq($until: $viewport--md - 1) {
            align-self: start;
            margin-top: u($spacing-unit * 1.5);
            margin-bottom: u($spacing-unit * 1.5);
        }

        @include mq($from: $viewport--md) {
            @include font-size(40, false);
            @include grid-area(1, 2, 1, 3);
        }

        // Item
        .Nav-item {
            display: inline-flex;

            + .Nav-item {
                margin-top: u($spacing-unit--xs);

                @include mq($from: $viewport--md) {
                    margin-top: u($spacing-unit);
                }
            }

            .show-nav & {
                @include transition(#{opacity, transform}, #{200ms, 500ms}, #{linear, cubic-bezier(.19,1,.22,1)});

                @for $i from 1 through 10 {
                    $delay: (100ms * $i);

                    &:nth-child(#{$i}) {
                        transition-delay: $delay;
                    }
                }
            }

            html:not(.show-nav) & {
                opacity: 0;
                transform: translateY(#{u($spacing-unit)});
            }
        }

        // Link
        .Nav-link {
            @include transition(#{color, padding-right});
            display: inline-flex;
            align-items: center;

            &::before {
                @include transition(#{opacity, margin-left});
                @include dimensions(7);
                background-color: $white;
                border-radius: 100%;
                content: '';
                display: block;
                margin-right: u(14);

                .Site-Xperify & {
                    @include dimensions(14);
                    background-color: transparent;
                    background-image: url("../img/xperify/icons/nav--hover.svg");
                    border-radius: 0;
                }
            }

            &:not(:hover) {
                padding-right: u(21);

                &::before {
                    margin-left: u(-21);
                    opacity: 0;
                }
            }


            &.is-active {
                font-weight: 500;
            }
        }
    }

    // Link
    .Nav-link {

        &,
        &:active,
        &:focus,
        &:hover {
            color: currentColor;
        }

        .Footer & {

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
