.Block--textMedia {

    @include mq($from: $viewport--md) {

        &.is-3by4 {
            @include position(relative, $z: 0);

            &:not(.is-last) {
                padding-bottom: 0;
            }

            &::after {
                @include position(absolute, $right: 0, $bottom: 0, $left: 0, $z: -1);
                background-color: $white;
                content: '';
                height: u(90);
            }

            .TextMedia-body {
                margin-bottom: u(90);
            }

            & + .Block {
                margin-top: 0;
            }
        }
    }

    .ClipPath {
        height: 0rem;
        width: 0rem;
        display: block;
    }
}

.TextMedia {

    &.is-dictionary {

        .TextMedia-body {
            font-family: $font-droidserif;
        }

        .TextMedia-title {
            letter-spacing: 0;
        }
    }

    @include mq($until: $viewport--md - 1) {

        &-object {
            display: block;
            margin-top: u($spacing-unit--lg);
        }
    }

    @include mq($from: $viewport--md) {
        display: flex;
        align-items: center;
        margin-left: u(-60);

        @include mq($from: $viewport--lg) {
            margin-left: u(-120);
        }

        .Block:nth-child(even) &,
        .Block.media--alignLeft & {
            flex-direction: row-reverse;
        }

        .Block.media--alignRight & {
            flex-direction: row;
        }

        &-object,
        &-body {
            padding-left: u(60);
            flex-shrink: 0;
            max-width: u(505 + 60);
            width: 50%;

            @include mq($from: $viewport--lg) {
                padding-left: u(120);
                max-width: u(505 + 120);
            }
        }

        &-body {

            .is-3by4 & {
                margin-right: auto;
            }

            .Block:nth-child(even) & {
                // margin-left: u(70);
                margin-right: auto;
            }
        }

        &-object {

            .is-3by4 & {
                max-width: u(536 + 120);
                align-self: flex-end;
            }
        }
    }

    &-image {
        .Site-Xperify & {
            clip-path: url("#clip-text-media");
        }
    }
}
