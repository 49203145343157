/* List */
ol,
ul {
    margin-bottom: u($spacing-unit);
    padding-left: u($list-gutter--l);

    li {
        margin-bottom: u(2);
    }

    ol,
    ul {
        margin-top: u(4);
        margin-bottom: u(4);
    }
}

// Unordered List
ul {
    list-style-type: disc;

    .Site-Xperify & {
        list-style-type: none;

        li {
            position: relative;
            
            &::after {
                content: "";
                @include position(absolute, $top: 9px, $left: -16px);
                background-image: url("../img/xperify/icons/bullet.svg");
                height: u(10);
                width: u(6);
            }
        }
    }
}

ul ul {
    list-style-type: circle;

    .Site-Xperify & {
        li::after {
            display: none;
        }
    }
}

ul ul ul {
    list-style-type: square;
}

// Ordered List
ol {
    list-style-type: decimal;

    .Site-Xperify & {
        ::marker {
            font-weight: 600;
        }
    }
}

ol ol {
    list-style-type: upper-alpha;
}

ol ol ol {
    list-style-type: lower-roman;
}

ol ol ol ol {
    list-style-type: lower-alpha;
}

// Definition List
dt {
    font-weight: 700;
}