.Block--testimonials {
    .Container-Testimonials {
        &::after {
            content: "";
            display: block;
            clear: both;
        }
    }

    .Testimonials-title {
        margin-bottom: u(80);
    }

    .Testimonial{
        display: block;
        margin-bottom: u(40);
        text-decoration: none;
        color: inherit;
    
        &:active,
        &:focus,
        &:hover,
        &.is-active,
        &.is-hovered {
            text-decoration: none;
            color: inherit;

            .Testimonial-image-overlay {
                opacity: 1;
            }
        }
    
        .ClipPath {
            height: 0rem;
            width: 0rem;
            display: block;
        }
    
        &--picture {
            position: relative;
            margin-bottom: u($spacing-unit--md * 1.5);
        }
    
        &-image {
            clip-path: url("#clip-text-media");

            &-overlay {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                opacity: 0;
                transition: opacity 0.2s linear;
                width: 100%;
                height: 100%;
                max-width: 178px;

                path {
                    fill: none;
                    stroke:$cc-xperify-pink;
                    stroke-miterlimit:10;
                    stroke-width:20px;
                }
            }
        }
    
        &--name {
            margin: 0;
            @include font-size($font-size);
            font-weight: 600;
        }

        &--function,
        &--company {
            margin: 0;
            @include font-size($font-size - 2);
            font-weight: 300;
        }

        &--company {
            margin-bottom: u($spacing-unit--md);
        }
    }
}