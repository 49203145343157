/* Elements: Heading */

// Namespace
$heading-namespace:                   $namespace !default;

// Typography
$heading-font-stack:                  inherit !default;

// Sizes
$heading-size-giga:                   115 !default;
$heading-size-mega:                   72 !default;
$heading-size-kilo:                   48 !default;

$heading-size-1:                      45 !default;
$heading-size-1--Xperify:             48 !default;
$heading-size-2:                      30 !default;
$heading-size-2--Xperify:             32 !default;
$heading-size-3:                      18 !default;
$heading-size-3--Xperify:             28 !default;
$heading-size-4:                      18 !default;
$heading-size-4--Xperify:             21 !default;
$heading-size-5:                      16 !default;
$heading-size-5--Xperify:             18 !default;
$heading-size-6:                      14 !default;
$heading-size-6--Xperify:             16 !default;

$heading-size-milli:                  12 !default;
$heading-size-micro:                  10 !default;

// Spacing
$heading-spacing-giga:                true !default;
$heading-spacing-mega:                true !default;
$heading-spacing-kilo:                true !default;

$heading-spacing-1:                   true !default;
$heading-spacing-2:                   true !default;
$heading-spacing-3:                   false !default;
$heading-spacing-4:                   true !default;
$heading-spacing-5:                   true !default;
$heading-spacing-6:                   true !default;

$heading-spacing-milli:               true !default;
$heading-spacing-micro:               true !default;
