/* Style */
/* Settings: Base */
/* Settings: Colors */
/* Colors: Palette */
/* Colors: Custom */
/* Colors: Theme */
/* Settings: Fonts */
/* Elements: Heading */
/* Elements: HR */
/* Elements: Image */
/* Elements: Link */
/* Elements: List */
/* Elements: Paragraph */
/* Elements: Selection */
/* Objects: Alert */
/* Components: Button */
/* Objects: FlexEmbed */
/* Objects: Form */
/* Objects: Grid */
/* Objects: Nav */
/* Objects: Structure */
/* Objects: Table */
/* Objects: Toggle */
/* Components: Fields */
/* Components: Cookie */
/* Components: Modal */
/* Components: DigitalPulse */
/* Functions: Throw */
/* Functions: Checks */
/* Functions: Truncate list */
/* Functions: Maps */
/* Functions: Maps */
/* Functions: Order index */
/* Functions: Units */
/* Functions: String */
/* Functions: SVG */
/* Mixin: Arrow */
/* Mixin: Background SVG */
/* Mixin: Caret */
/* Mixin: Context */
/* Mixin: Dimensions */
/* Mixin: Family */
/* Mixin: Font-responsive */
/* Mixin: Font-size */
/* Mixin: Font-smoothing */
/* Mixin: Grid */
/* Mixin: Image rendering */
/* Mixin: Last-children */
/* Mixin: Line-height crop */
/* Mixin: Maps */
/* Mixin: ParentNthStatus */
/* Mixin: ParentState */
/* Mixin: ParentStatus */
/* Mixin: Position */
/* Mixin: Maps */
/* Mixin: Transition */
/* Mixin: Truncate */
/* Reset */
*, *::before, *::after {
  box-sizing: inherit; }

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 1.125em;
  line-height: 1.66667;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern" 1;
  font-kerning: normal; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0; }

iframe {
  border: 0; }

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

legend {
  display: block;
  width: 100%; }

option {
  font: inherit; }

textarea {
  resize: vertical; }

button {
  background: transparent;
  border: 0;
  padding: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

abbr[title],
dfn[title] {
  cursor: help; }

u,
ins {
  text-decoration: underline; }

img {
  font-style: italic; }

::selection {
  text-shadow: none; }

[tabindex="-1"]:focus {
  outline: none !important; }

/* HR */
hr {
  margin-top: 1.11111rem;
  margin-bottom: 1.05556rem;
  color: #bdbdbd;
  border: 0;
  border-bottom: 1px solid; }

/* Image */
img {
  max-width: 100%;
  height: auto; }

figure > img,
picture {
  display: block; }

/* Link */
a {
  transition-property: background-color, color;
  transition-duration: 200ms;
  transition-timing-function: linear;
  color: #0026ff;
  text-decoration: underline; }
  a:hover, a:focus, a:active {
    color: #0026ff;
    text-decoration: none; }

/* List */
ol,
ul {
  margin-bottom: 1.11111rem;
  padding-left: 1.11111rem; }
  ol li,
  ul li {
    margin-bottom: 0.11111rem; }
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-top: 0.22222rem;
    margin-bottom: 0.22222rem; }

ul {
  list-style-type: disc; }
  .Site-Xperify ul {
    list-style-type: none; }
    .Site-Xperify ul li {
      position: relative; }
      .Site-Xperify ul li::after {
        content: "";
        position: absolute;
        top: 9px;
        left: -16px;
        background-image: url("../img/xperify/icons/bullet.svg");
        height: 0.55556rem;
        width: 0.33333rem; }

ul ul {
  list-style-type: circle; }
  .Site-Xperify ul ul li::after {
    display: none; }

ul ul ul {
  list-style-type: square; }

ol {
  list-style-type: decimal; }
  .Site-Xperify ol ::marker {
    font-weight: 600; }

ol ol {
  list-style-type: upper-alpha; }

ol ol ol {
  list-style-type: lower-roman; }

ol ol ol ol {
  list-style-type: lower-alpha; }

dt {
  font-weight: 700; }

/* Selection */
::selection {
  color: #ffffff;
  background-color: #0026ff; }
  .Site-Xperify ::selection {
    background-color: #ff8000; }

/* Heading */
h1, .Alfa,
h2, .Bravo,
h3, .Charlie,
h4, .Delta,
h5, .Echo,
h6, .Foxtrot {
  color: #101010;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1.22222; }
  .fonts-loaded h1, .fonts-loaded .Alfa, .fonts-loaded
  h2, .fonts-loaded .Bravo, .fonts-loaded
  h3, .fonts-loaded .Charlie, .fonts-loaded
  h4, .fonts-loaded .Delta, .fonts-loaded
  h5, .fonts-loaded .Echo, .fonts-loaded
  h6, .fonts-loaded .Foxtrot {
    font-family: inherit; }
  .Site-Ocular h1 strong, .Site-Ocular .Alfa strong, .Site-Ocular
  h2 strong, .Site-Ocular .Bravo strong, .Site-Ocular
  h3 strong, .Site-Ocular .Charlie strong, .Site-Ocular
  h4 strong, .Site-Ocular .Delta strong, .Site-Ocular
  h5 strong, .Site-Ocular .Echo strong, .Site-Ocular
  h6 strong, .Site-Ocular .Foxtrot strong {
    color: transparent;
    font-weight: inherit;
    text-shadow: -1px -1px 0 rgba(16, 16, 16, 0.1), 1px -1px 0 rgba(16, 16, 16, 0.1), -1px 1px 0 rgba(16, 16, 16, 0.1), 1px 1px 0 rgba(16, 16, 16, 0.1); }
    @supports (-webkit-text-stroke: 1px black) {
      .Site-Ocular h1 strong, .Site-Ocular .Alfa strong, .Site-Ocular
      h2 strong, .Site-Ocular .Bravo strong, .Site-Ocular
      h3 strong, .Site-Ocular .Charlie strong, .Site-Ocular
      h4 strong, .Site-Ocular .Delta strong, .Site-Ocular
      h5 strong, .Site-Ocular .Echo strong, .Site-Ocular
      h6 strong, .Site-Ocular .Foxtrot strong {
        text-shadow: none;
        -webkit-text-stroke: 1px #101010; } }
  .Site-Xperify h1, .Site-Xperify .Alfa, .Site-Xperify
  h2, .Site-Xperify .Bravo, .Site-Xperify
  h3, .Site-Xperify .Charlie, .Site-Xperify
  h4, .Site-Xperify .Delta, .Site-Xperify
  h5, .Site-Xperify .Echo, .Site-Xperify
  h6, .Site-Xperify .Foxtrot {
    font-family: "Montserrat", sans-serif; }
    .Site-Xperify h1 strong, .Site-Xperify .Alfa strong, .Site-Xperify
    h2 strong, .Site-Xperify .Bravo strong, .Site-Xperify
    h3 strong, .Site-Xperify .Charlie strong, .Site-Xperify
    h4 strong, .Site-Xperify .Delta strong, .Site-Xperify
    h5 strong, .Site-Xperify .Echo strong, .Site-Xperify
    h6 strong, .Site-Xperify .Foxtrot strong {
      font-weight: inherit;
      color: #ff8000; }
  h1 small, .Alfa small,
  h2 small, .Bravo small,
  h3 small, .Charlie small,
  h4 small, .Delta small,
  h5 small, .Echo small,
  h6 small, .Foxtrot small {
    font-weight: normal; }

h1, .Alfa {
  letter-spacing: 1.5px;
  margin-bottom: 1.11111rem; }
  @media screen and (max-width: 19.9375em) {
    h1, .Alfa {
      font-size: 1.5rem; } }
  @media screen and (min-width: 20em) and (max-width: 63.9375em) {
    h1, .Alfa {
      font-size: calc(1.5rem + 18 * ((100vw - 17.77778rem) / 704)); } }
  @media screen and (min-width: 64em) {
    h1, .Alfa {
      font-size: 2.5rem; } }
  @media screen and (max-width: 19.9375em) {
    .Site-Xperify h1, .Site-Xperify .Alfa {
      font-size: 1.6rem; } }
  @media screen and (min-width: 20em) and (max-width: 63.9375em) {
    .Site-Xperify h1, .Site-Xperify .Alfa {
      font-size: calc(1.6rem + 19.2 * ((100vw - 17.77778rem) / 704)); } }
  @media screen and (min-width: 64em) {
    .Site-Xperify h1, .Site-Xperify .Alfa {
      font-size: 2.66667rem; } }

h2, .Bravo {
  letter-spacing: inherit;
  margin-bottom: 1.11111rem; }
  @media screen and (max-width: 19.9375em) {
    h2, .Bravo {
      font-size: 1.25rem; } }
  @media screen and (min-width: 20em) and (max-width: 63.9375em) {
    h2, .Bravo {
      font-size: calc(1.25rem + 7.5 * ((100vw - 17.77778rem) / 704)); } }
  @media screen and (min-width: 64em) {
    h2, .Bravo {
      font-size: 1.66667rem; } }
  @media screen and (max-width: 19.9375em) {
    .Site-Xperify h2, .Site-Xperify .Bravo {
      font-size: 1.06667rem; } }
  @media screen and (min-width: 20em) and (max-width: 63.9375em) {
    .Site-Xperify h2, .Site-Xperify .Bravo {
      font-size: calc(1.06667rem + 12.8 * ((100vw - 17.77778rem) / 704)); } }
  @media screen and (min-width: 64em) {
    .Site-Xperify h2, .Site-Xperify .Bravo {
      font-size: 1.77778rem; } }

h3, .Charlie {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.55556rem;
  line-height: 1.22; }
  .Site-Xperify h3, .Site-Xperify .Charlie {
    font-size: 1.55556rem;
    font-weight: 700; }

h4, .Delta {
  margin-bottom: 1.66667rem;
  font-size: 1rem;
  font-weight: 500; }
  .Site-Xperify h4, .Site-Xperify .Delta {
    font-size: 1.16667rem;
    font-weight: 700; }

h5, .Echo {
  margin-bottom: 1.66667rem;
  font-size: 0.88889rem; }
  .Site-Xperify h5, .Site-Xperify .Echo {
    font-size: 1rem;
    font-weight: 600; }

h6, .Foxtrot {
  margin-bottom: 1.66667rem;
  font-size: 0.77778rem; }
  .Site-Xperify h6, .Site-Xperify .Foxtrot {
    font-size: 0.88889rem;
    font-weight: 600; }

@media screen and (max-width: 19.9375em) {
  .Giga {
    font-size: 3.19444rem; } }

@media screen and (min-width: 20em) and (max-width: 63.9375em) {
  .Giga {
    font-size: calc(3.19444rem + 57.5 * ((100vw - 17.77778rem) / 704)); } }

@media screen and (min-width: 64em) {
  .Giga {
    font-size: 6.38889rem; } }

html:not(.site-Xperify) .Giga {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; }

.Mega {
  margin-bottom: 1.66667rem;
  font-size: 4rem;
  line-height: 1.1; }

.Kilo {
  margin-bottom: 1.66667rem;
  font-size: 2.66667rem;
  line-height: 1.2; }

.Milli {
  margin-bottom: 1.66667rem;
  font-size: 0.66667rem; }

.Micro {
  margin-bottom: 1.66667rem;
  font-size: 0.55556rem; }

/* Paragraph */
p {
  margin-bottom: 1.66667rem; }
  p:only-of-type {
    margin-bottom: 1.11111rem; }

.Lead {
  color: #000000;
  font-weight: 500;
  margin-bottom: 1.66667rem; }

/* Main */
html {
  background-color: #f7f7f7;
  color: #555c62;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 100%; }
  html:not(.Site-Xperify) {
    font-family: "Gordita", sans-serif; }
  html.Site-Xperify {
    background-color: inherit;
    font-family: "Montserrat", sans-serif;
    color: #101010; }
  html.hide-scroll {
    overflow: hidden; }

body {
  min-height: 100%; }

/* Alert */
.Alert {
  position: relative;
  margin-bottom: 1.11111rem;
  padding: 0.55556em 0.74074em 0.55556em 0.74074em;
  border: 1px solid;
  border-radius: 2px; }
  .Alert.is-dismissable {
    padding-right: 1.48148rem; }
  .Alert p,
  .Alert ul {
    margin-bottom: 0; }
    .Alert p + p,
    .Alert p + ul,
    .Alert ul + p,
    .Alert ul + ul {
      margin-top: 1.11111rem; }
  .Alert a:not(.Button--close) {
    font-weight: bold; }
    .Alert a:not(.Button--close):hover {
      text-decoration: underline; }

/* Button */
/*
 * 1. Corrects inability to style clickable `input` types in iOS.
 * 2. Normalize `box-sizing` across all elements that this component could be
 *    applied to.
 * 3. Inherit text color from ancestor.
 * 4. Inherit font styles from ancestor.
 * 5. Normalize `line-height`. For `input`, it can't be changed from `normal`
 *    in Firefox 4+.
 * 6. Prevent button text from being selectable.
 * 7. Make sure `input` will wrap text across multiple lines.
 *
 */
.Button {
  appearance: none;
  background: transparent;
  border-color: transparent;
  border-radius: 0;
  border-style: solid;
  border-width: 0px;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0.83333em 1.94444em 0.83333em 1.94444em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: normal; }
  .Button::-moz-focus-inner {
    border: 0;
    padding: 0; }
  .Button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color; }
  .Button:active, .Button:focus, .Button:hover, .Button.is-active {
    color: inherit;
    text-decoration: none; }
  .Button:disabled, .Button.is-disabled {
    cursor: default;
    opacity: 0.6;
    pointer-events: none; }
  .Site-Xperify .Button {
    border: none !important;
    background: none !important;
    font-weight: 600; }
    .Site-Xperify .Button .Button-masks {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      width: 100%;
      height: 100%; }
      .Site-Xperify .Button .Button-masks .Button-mask {
        display: block;
        height: 100%; }
        .Site-Xperify .Button .Button-masks .Button-mask--left {
          flex-grow: 0;
          flex-shrink: 0;
          width: 1.94444em; }
        .Site-Xperify .Button .Button-masks .Button-mask--center {
          flex-grow: 1;
          flex-shrink: 1;
          width: 100%; }
        .Site-Xperify .Button .Button-masks .Button-mask--right {
          flex-grow: 0;
          flex-shrink: 0;
          width: 1.94444em; }
        .Site-Xperify .Button .Button-masks .Button-mask--fill {
          transition: fill 0.2s linear; }
    .Site-Xperify .Button.Button--outline .Button-mask--fill {
      opacity: 0; }
    .Site-Xperify .Button.Button--outline:active .Button-mask--fill, .Site-Xperify .Button.Button--outline:focus .Button-mask--fill, .Site-Xperify .Button.Button--outline:hover .Button-mask--fill, .Site-Xperify .Button.Button--outline.is-active .Button-mask--fill {
      opacity: 1; }
    .Site-Xperify .Button:not(.Button--outline) .Button-mask--fill {
      opacity: 1; }
    .Site-Xperify .Button:not(.Button--outline):active .Button-mask--fill, .Site-Xperify .Button:not(.Button--outline):focus .Button-mask--fill, .Site-Xperify .Button:not(.Button--outline):hover .Button-mask--fill, .Site-Xperify .Button:not(.Button--outline).is-active .Button-mask--fill {
      opacity: 0; }
    .Site-Xperify .Button .Button-text {
      position: relative;
      z-index: 1; }
    .Site-Xperify .Button .Button-object {
      transition: fill 0.2s linear; }

.Site-Xperify .ReadMore {
  display: inline-flex;
  align-items: center;
  padding-bottom: 6px;
  font-weight: 600;
  text-decoration: none;
  color: inherit; }
  .Site-Xperify .ReadMore-text {
    position: relative; }
    .Site-Xperify .ReadMore-text::after {
      content: "";
      position: absolute;
      bottom: -6px;
      left: 0;
      opacity: 0;
      transition: opacity 0.2s linear;
      width: 100%;
      height: 0.11111rem;
      background-color: #101010; }
  .Site-Xperify .ReadMore:active, .Site-Xperify .ReadMore:focus, .Site-Xperify .ReadMore:hover, .Site-Xperify .ReadMore.is-active {
    color: inherit;
    text-decoration: none; }
    .Site-Xperify .ReadMore:active .ReadMore-text::after, .Site-Xperify .ReadMore:focus .ReadMore-text::after, .Site-Xperify .ReadMore:hover .ReadMore-text::after, .Site-Xperify .ReadMore.is-active .ReadMore-text::after {
      opacity: 1; }

/* FlexEmbed */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative; }
  .FlexEmbed::before {
    content: '';
    display: block;
    padding-bottom: 100%;
    width: 100%; }

.FlexEmbed-content,
.FlexEmbed-content > iframe,
.FlexEmbed-content > object,
.FlexEmbed-content > embed,
.FlexEmbed-content > video {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

img.FlexEmbed-content {
  object-fit: cover; }

.FlexEmbed--2by1:before {
  padding-bottom: 50%; }

.FlexEmbed--3by1:before {
  padding-bottom: 33.33333%; }

.FlexEmbed--3by2:before {
  padding-bottom: 66.66667%; }

.FlexEmbed--4by3:before {
  padding-bottom: 75%; }

.FlexEmbed--16by9:before {
  padding-bottom: 56.25%; }

/* Form */
.Form-item:not(.Form-item--action) {
  margin-bottom: 1.66667rem; }

.Form-label {
  display: inline-block;
  line-height: 1.79; }
  .Form-item:not(.Form-item--input) .Form-label {
    margin-bottom: 0.55556rem; }
  .Form-item--input .Form-label {
    position: absolute;
    transform: translateY(0.69444rem) scale(1);
    transform-origin: top left;
    transition: transform .2s;
    z-index: 2; }
  .Form-item:not(.is-required) .Form-label[data-optional]::after {
    font-size: 65%;
    content: attr(data-optional);
    margin-left: 0.27778rem;
    text-transform: lowercase; }
  .Form-item--input.is-active .Form-label {
    transform: translateY(0) scale(0.75); }
  .Form-item.is-error .Form-label {
    color: #f44336; }
  .Form-item.is-success .Form-label {
    color: #4caf50; }

.Form-help {
  margin-top: 0.27778rem;
  color: #bdbdbd;
  font-size: 0.75rem; }
  .Form-help:first-child {
    margin-top: 0;
    margin-bottom: 0.55556rem; }
  .Form-help :last-child {
    margin-bottom: 0; }
  .Form-help + .Form-help {
    margin-top: 0.55556rem;
    padding-top: 0.55556rem;
    border-top: 1px solid #e0e0e0; }

.Form-item.is-error .Form-help--validation {
  color: #f44336; }

.Form-item.is-success .Form-help--validation {
  color: #4caf50; }

.Form-controls {
  position: relative; }

@media screen and (min-width: 37.5em) {
  .Form--horizontal .Form-item {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start; }
  .Form--horizontal .Form-label {
    margin-top: 0.27778rem;
    margin-bottom: 0;
    padding-right: 1.11111rem;
    width: 33.33333%; }
  .Form--horizontal .Form-controls {
    width: 66.66667%; }
  .Form--horizontal .Form-item--action {
    padding-left: 33.33333%; } }

.Media-offscreen {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal; }

.Media-container {
  background: #000;
  box-sizing: border-box;
  font-family: 'Helvetica', Arial, serif;
  position: relative;
  text-align: left;
  text-indent: 0;
  vertical-align: top; }

.Media-container * {
  box-sizing: border-box; }

/* Hide native play button and control bar from iOS to favor plugin button */
.Media-container video::-webkit-media-controls,
.Media-container video::-webkit-media-controls-panel,
.Media-container video::-webkit-media-controls-panel-container,
.Media-container video::-webkit-media-controls-start-playback-button {
  -webkit-appearance: none;
  display: none !important; }

.Media-fill-container,
.Media-fill-container .Media-container {
  height: 100%;
  width: 100%; }

.Media-fill-container {
  background: transparent;
  margin: 0 auto;
  overflow: hidden;
  position: relative; }

.Media-container:focus {
  outline: none; }

.Media-iframe-overlay {
  height: 100%;
  position: absolute;
  width: 100%; }

.Media-embed,
.Media-embed body {
  background: #000;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

.Media-fullscreen {
  overflow: hidden !important; }

.Media-container-fullscreen {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000; }

.Media-container-fullscreen .Media-mediaelement,
.Media-container-fullscreen video {
  height: 100% !important;
  width: 100% !important; }

/* Start: LAYERS */
.Media-background {
  left: 0;
  position: absolute;
  top: 0; }

.Media-mediaelement {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0; }

.Media-poster {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1; }

:root .Media-poster-img {
  display: none; }

.Media-poster-img {
  border: 0;
  padding: 0; }

.Media-overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0; }

.Media-layer {
  z-index: 1; }

.Media-overlay-play {
  cursor: pointer; }

.Media-overlay-button {
  background: url("../img/media/controls.svg") no-repeat;
  background-position: 0 -39px;
  height: 80px;
  width: 80px; }

.Media-overlay:hover > .Media-overlay-button {
  background-position: -80px -39px; }

.Media-overlay-loading {
  height: 80px;
  width: 80px; }

.Media-overlay-loading-bg-img {
  animation: Media-loading-spinner 1s linear infinite;
  background: transparent url("../img/media/controls.svg") -160px -40px no-repeat;
  display: block;
  height: 80px;
  width: 80px;
  z-index: 1; }

@keyframes Media-loading-spinner {
  100% {
    transform: rotate(360deg); } }

/* End: LAYERS */
/* Start: CONTROL BAR */
.Media-controls {
  bottom: 0;
  display: flex;
  height: 40px;
  left: 0;
  list-style-type: none;
  margin: 0;
  padding: 0 10px;
  position: absolute;
  width: 100%;
  z-index: 3; }

.Media-controls:not([style*='display: none']) {
  background: rgba(255, 0, 0, 0.7);
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.35)); }

.Media-button,
.Media-time,
.Media-time-rail {
  font-size: 10px;
  height: 40px;
  line-height: 10px;
  margin: 0;
  width: 32px; }

.Media-button > button {
  background: transparent url("../img/media/controls.svg");
  border: 0;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 20px;
  line-height: 0;
  margin: 10px 6px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-decoration: none;
  width: 20px; }

/* :focus for accessibility */
.Media-button > button:focus {
  outline: dotted 1px #999; }

.Media-container-keyboard-inactive a,
.Media-container-keyboard-inactive a:focus,
.Media-container-keyboard-inactive button,
.Media-container-keyboard-inactive button:focus,
.Media-container-keyboard-inactive [role=slider],
.Media-container-keyboard-inactive [role=slider]:focus {
  outline: 0; }

/* End: CONTROL BAR */
/* Start: Time (Current / Duration) */
.Media-time {
  box-sizing: content-box;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  height: 24px;
  overflow: hidden;
  padding: 16px 6px 0;
  text-align: center;
  width: auto; }

/* End: Time (Current / Duration) */
/* Start: Play/Pause/Stop */
.Media-play > button {
  background-position: 0 0; }

.Media-pause > button {
  background-position: -20px 0; }

.Media-replay > button {
  background-position: -160px 0; }

/* End: Play/Pause/Stop */
/* Start: Progress Bar */
.Media-time-rail {
  direction: ltr;
  flex-grow: 1;
  height: 40px;
  margin: 0 10px;
  padding-top: 10px;
  position: relative; }

.Media-time-total,
.Media-time-buffering,
.Media-time-loaded,
.Media-time-current,
.Media-time-float,
.Media-time-hovered,
.Media-time-float-current,
.Media-time-float-corner,
.Media-time-marker {
  border-radius: 2px;
  cursor: pointer;
  display: block;
  height: 10px;
  position: absolute; }

.Media-time-total {
  background: rgba(255, 255, 255, 0.3);
  margin: 5px 0 0;
  width: 100%; }

.Media-time-buffering {
  animation: buffering-stripes 2s linear infinite;
  background: linear-gradient(-45deg, rgba(255, 255, 255, 0.4) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.4) 75%, transparent 75%, transparent);
  background-size: 15px 15px;
  width: 100%; }

@keyframes buffering-stripes {
  from {
    background-position: 0 0; }
  to {
    background-position: 30px 0; } }

.Media-time-loaded {
  background: rgba(255, 255, 255, 0.3); }

.Media-time-current,
.Media-time-handle-content {
  background: rgba(255, 255, 255, 0.9); }

.Media-time-hovered {
  background: rgba(255, 255, 255, 0.5);
  z-index: 10; }

.Media-time-hovered.negative {
  background: rgba(0, 0, 0, 0.2); }

.Media-time-current,
.Media-time-buffering,
.Media-time-loaded,
.Media-time-hovered {
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: 0.15s ease-in all;
  width: 100%; }

.Media-time-buffering {
  transform: scaleX(1); }

.Media-time-hovered {
  transition: height 0.1s cubic-bezier(0.44, 0, 1, 1); }

.Media-time-hovered.no-hover {
  transform: scaleX(0) !important; }

.Media-time-handle,
.Media-time-handle-content {
  border: 4px solid transparent;
  cursor: pointer;
  left: 0;
  position: absolute;
  transform: translateX(0);
  z-index: 11; }

.Media-time-handle-content {
  border: 4px solid rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  height: 10px;
  left: -7px;
  top: -4px;
  transform: scale(0);
  width: 10px; }

.Media-time-rail:hover .Media-time-handle-content,
.Media-time-rail .Media-time-handle-content:focus,
.Media-time-rail .Media-time-handle-content:active {
  transform: scale(1); }

.Media-time-float {
  background: #eee;
  border: solid 1px #333;
  bottom: 100%;
  color: #111;
  display: none;
  height: 17px;
  margin-bottom: 9px;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  width: 36px; }

.Media-time-float-current {
  display: block;
  left: 0;
  margin: 2px;
  text-align: center;
  width: 30px; }

.Media-time-float-corner {
  border: solid 5px #eee;
  border-color: #eee transparent transparent;
  border-radius: 0;
  display: block;
  height: 0;
  left: 50%;
  line-height: 0;
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
  width: 0; }

.Media-long-video .Media-time-float {
  margin-left: -23px;
  width: 64px; }

.Media-long-video .Media-time-float-current {
  width: 60px; }

.Media-broadcast {
  color: #fff;
  height: 10px;
  position: absolute;
  top: 15px;
  width: 100%; }

/* End: Progress Bar */
/* Start: Fullscreen */
.Media-fullscreen-button > button {
  background-position: -80px 0; }

.Media-unfullscreen > button {
  background-position: -100px 0; }

/* End: Fullscreen */
/* Start: Mute/Volume */
.Media-mute > button {
  background-position: -60px 0; }

.Media-unmute > button {
  background-position: -40px 0; }

.Media-volume-button {
  position: relative; }

.Media-volume-button > .Media-volume-slider {
  -webkit-backface-visibility: hidden;
  background: rgba(50, 50, 50, 0.7);
  border-radius: 0;
  bottom: 100%;
  display: none;
  height: 115px;
  left: 50%;
  margin: 0;
  position: absolute;
  transform: translateX(-50%);
  width: 25px;
  z-index: 1; }

.Media-volume-button:hover {
  border-radius: 0 0 4px 4px; }

.Media-volume-total {
  background: rgba(255, 255, 255, 0.5);
  height: 100px;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 8px;
  transform: translateX(-50%);
  width: 2px; }

.Media-volume-current {
  background: rgba(255, 255, 255, 0.9);
  left: 0;
  margin: 0;
  position: absolute;
  width: 100%; }

.Media-volume-handle {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 1px;
  cursor: ns-resize;
  height: 6px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 16px; }

.Media-horizontal-volume-slider {
  display: block;
  height: 36px;
  position: relative;
  vertical-align: middle;
  width: 56px; }

.Media-horizontal-volume-total {
  background: rgba(50, 50, 50, 0.8);
  border-radius: 2px;
  font-size: 1px;
  height: 8px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 16px;
  width: 50px; }

.Media-horizontal-volume-current {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 2px;
  font-size: 1px;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.Media-horizontal-volume-handle {
  display: none; }

/* End: Mute/Volume */
/* Start: Track (Captions and Chapters) */
.Media-captions-button,
.Media-chapters-button {
  position: relative; }

.Media-captions-button > button {
  background-position: -140px 0; }

.Media-chapters-button > button {
  background-position: -180px 0; }

.Media-captions-button > .Media-captions-selector,
.Media-chapters-button > .Media-chapters-selector {
  background: rgba(50, 50, 50, 0.7);
  border: solid 1px transparent;
  border-radius: 0;
  bottom: 100%;
  margin-right: -43px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 50%;
  visibility: visible;
  width: 86px; }

.Media-chapters-button > .Media-chapters-selector {
  margin-right: -55px;
  width: 110px; }

.Media-captions-selector-list,
.Media-chapters-selector-list {
  list-style-type: none !important;
  margin: 0;
  overflow: hidden;
  padding: 0; }

.Media-captions-selector-list-item,
.Media-chapters-selector-list-item {
  color: #fff;
  cursor: pointer;
  display: block;
  list-style-type: none !important;
  margin: 0 0 6px;
  overflow: hidden;
  padding: 0; }

.Media-captions-selector-list-item:hover,
.Media-chapters-selector-list-item:hover {
  background-color: #c8c8c8 !important;
  background-color: rgba(255, 255, 255, 0.4) !important; }

.Media-captions-selector-input,
.Media-chapters-selector-input {
  clear: both;
  float: left;
  left: -1000px;
  margin: 3px 3px 0 5px;
  position: absolute; }

.Media-captions-selector-label,
.Media-chapters-selector-label {
  cursor: pointer;
  float: left;
  font-size: 10px;
  line-height: 15px;
  padding: 4px 10px 0;
  width: 100%; }

.Media-captions-selected,
.Media-chapters-selected {
  color: #21f8f8; }

.Media-captions-translations {
  font-size: 10px;
  margin: 0 0 5px; }

.Media-captions-layer {
  bottom: 0;
  color: #fff;
  font-size: 16px;
  left: 0;
  line-height: 20px;
  position: absolute;
  text-align: center; }

.Media-captions-layer a {
  color: #fff;
  text-decoration: underline; }

.Media-captions-layer[lang=ar] {
  font-size: 20px;
  font-weight: normal; }

.Media-captions-position {
  bottom: 15px;
  left: 0;
  position: absolute;
  width: 100%; }

.Media-captions-position-hover {
  bottom: 35px; }

.Media-captions-text,
.Media-captions-text * {
  background: rgba(20, 20, 20, 0.5);
  box-shadow: 5px 0 0 rgba(20, 20, 20, 0.5), -5px 0 0 rgba(20, 20, 20, 0.5);
  padding: 0;
  white-space: pre-wrap; }

.Media-container.Media-hide-cues video::-webkit-media-text-track-container {
  display: none; }

/* End: Track (Captions and Chapters) */
/* Start: Error */
.Media-overlay-error {
  position: relative; }

.Media-overlay-error > img {
  left: 0;
  max-width: 100%;
  position: absolute;
  top: 0;
  z-index: -1; }

.Media-cannotplay,
.Media-cannotplay a {
  color: #fff;
  font-size: 0.8em; }

.Media-cannotplay {
  position: relative; }

.Media-cannotplay p,
.Media-cannotplay a {
  display: inline-block;
  padding: 0 15px;
  width: 100%; }

/* Grid */
/**
 * DO NOT apply dimension or offset utilities to the `Grid` element. All cell
 * widths and offsets should be applied to child grid cells.
 */
.Grid {
  box-sizing: border-box;
  display: flex;
  /* 1 */
  flex-flow: row wrap;
  margin: 0;
  /* 1 */
  padding: 0;
  /* 1 */ }

/**
    * No explicit width by default. Rely on combining `Grid-cell` with a dimension
    * utility or a component class that extends 'Grid'.
    *
    * 1. Set flex items to full width by default
    * 2. Fix issue where elements with overflow extend past the
    *      `Grid-cell` container - https://git.io/vw5oF
    */
.Grid-cell {
  box-sizing: inherit;
  flex-basis: 100%;
  /* 1 */
  min-width: 0;
  /* 2 */ }

/**
    * Modifiers
    */
.Grid--alignCenter {
  justify-content: center; }

.Grid--alignRight {
  justify-content: flex-end; }

.Grid--alignMiddle {
  align-items: center; }

.Grid--alignBottom {
  align-items: flex-end; }

.Grid--fill > .Grid-cell {
  flex: 1 1 0%;
  /* 1 */ }

.Grid--fit > .Grid-cell {
  flex-basis: auto; }

.Grid--equalHeight > .Grid-cell {
  display: flex; }

.Grid--withExtraSmallGutter {
  margin-left: -0.27778rem; }
  .Grid--withExtraSmallGutter > .Grid-cell {
    padding-left: 0.27778rem; }

.Grid--withSmallGutter {
  margin-left: -0.55556rem; }
  .Grid--withSmallGutter > .Grid-cell {
    padding-left: 0.55556rem; }

.Grid--withGutter {
  margin-left: -1.11111rem; }
  .Grid--withGutter > .Grid-cell {
    padding-left: 1.11111rem; }

.Grid--withLargeGutter {
  margin-left: -2.22222rem; }
  .Grid--withLargeGutter > .Grid-cell {
    padding-left: 2.22222rem; }

.Grid--withExtraLargeGutter {
  margin-left: -3.33333rem; }
  .Grid--withExtraLargeGutter > .Grid-cell {
    padding-left: 3.33333rem; }

/* Icon */
.Icon {
  display: inline-flex;
  align-self: center;
  flex-shrink: 0; }
  .Icon svg {
    fill: currentColor;
    height: 1em;
    width: 1em; }
  .Icon.Icon--baseline svg {
    top: 0.125em;
    position: relative; }

/* Nav */
.Nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto; }

.Nav-item {
  margin: 0; }
  .Header .Nav-item::after,
  .Footer-bottom .Nav-item::after,
  .Nav--socialMedia .Nav-item::after {
    display: none; }

.Nav-link {
  user-select: none;
  display: block; }
  .Nav-link, .Nav-link:active, .Nav-link:focus, .Nav-link:hover {
    text-decoration: none; }

.Nav--stacked .Nav-list {
  flex-direction: column;
  flex-wrap: nowrap; }

.Nav--fit:not(.Nav--stacked) .Nav-item {
  flex: 1 1 auto; }

.Nav--alignCenter {
  text-align: center; }
  .Nav--alignCenter:not(.Nav--stacked):not(.Nav--fit) .Nav-list {
    justify-content: center; }

.Nav--alignRight {
  text-align: right; }
  .Nav--alignRight:not(.Nav--stacked):not(.Nav--fit) .Nav-list {
    justify-content: flex-end; }

/* Structure */
.Container {
  margin-right: auto;
  margin-left: auto;
  max-width: 62.77778rem;
  padding-right: 1.11111rem;
  padding-left: 1.11111rem;
  position: relative;
  width: 100%; }
  .Container--xl {
    max-width: 91.11111rem; }
  .Container--lg {
    max-width: 72.5rem; }
  .Container--md {
    max-width: 62.77778rem; }
  .Container--sm {
    max-width: 60.55556rem; }
  .Container--xs {
    max-width: 50.55556rem; }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .Container {
      max-width: 65rem;
      padding-right: 2.22222rem;
      padding-left: 2.22222rem; }
      .Container--xl {
        max-width: 93.33333rem; }
      .Container--lg {
        max-width: 74.72222rem; }
      .Container--md {
        max-width: 65rem; }
      .Container--sm {
        max-width: 62.77778rem; }
      .Container--xs {
        max-width: 52.77778rem; } }
  @media screen and (min-width: 64em) {
    .Container {
      max-width: 69.44444rem;
      padding-right: 4.44444rem;
      padding-left: 4.44444rem; }
      .Container--xl {
        max-width: 97.77778rem; }
      .Container--lg {
        max-width: 79.16667rem; }
      .Container--md {
        max-width: 69.44444rem; }
      .Container--sm {
        max-width: 67.22222rem; }
      .Container--xs {
        max-width: 57.22222rem; } }

body {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "header" "main" "footer"; }

.Header {
  position: relative;
  z-index: 3;
  grid-area: header;
  height: 6.55556rem; }
  .Header > .Container {
    height: 100%; }

.Footer {
  position: relative;
  z-index: 1;
  grid-area: footer; }
  .Footer > .Container {
    height: 100%; }

.Main {
  position: relative;
  z-index: 2;
  grid-area: main;
  padding-top: 6.55556rem; }
  .HomePage .Main {
    padding-top: 0; }
  .Main > .Container {
    padding-top: 2.22222rem;
    padding-bottom: 2.22222rem; }
    @media screen and (min-width: 48em) {
      .Main > .Container {
        padding-top: 6.66667rem;
        padding-bottom: 6.66667rem; } }
    @media screen and (min-width: 48em) {
      .Main > .Container {
        grid-gap: 2.22222rem; }
        .Main > .Container.has-leftSide {
          display: grid;
          grid-template-columns: 33.33333% 1fr;
          grid-template-rows: auto;
          grid-template-areas: "left content"; }
        .Main > .Container.has-rightSide {
          display: grid;
          grid-template-columns: 1fr 33.33333%;
          grid-template-rows: auto;
          grid-template-areas: "content right"; }
        .Main > .Container.has-sides {
          display: grid;
          grid-template-columns: 33.33333% auto 33.33333%;
          grid-template-rows: auto;
          grid-template-areas: "left content right"; } }

.Side {
  position: relative; }
  @media screen and (max-width: 47.9375em) {
    .Side {
      margin-top: 1.11111rem; } }
  @media screen and (min-width: 48em) {
    .Side--left {
      grid-area: left; }
    .Side--right {
      grid-area: right; } }

.Content {
  grid-area: content;
  position: relative; }

/* Table */
.Table {
  background-color: transparent;
  border: 0;
  border-spacing: 0;
  font-size: 0.88889rem;
  table-layout: fixed;
  text-align: left;
  width: 100%; }
  .Table th,
  .Table td {
    padding-top: 0.83333rem;
    padding-bottom: 0.83333rem; }
    .Table th:not(:first-child),
    .Table td:not(:first-child) {
      padding-left: 0.55556rem; }
    .Table th:not(:last-child),
    .Table td:not(:last-child) {
      padding-right: 0.55556rem; }
  .Table td {
    vertical-align: middle; }
  .Table th {
    padding-top: 1.11111rem;
    padding-bottom: 1.11111rem;
    vertical-align: top; }
  .Table thead {
    background-color: transparent;
    border-bottom: 2px solid rgba(180, 180, 180, 0.4); }
  .Table tbody td {
    border-top: 1px solid rgba(180, 180, 180, 0.4); }
  @media screen and (min-width: 48em) {
    .Table tbody tr:last-child > td {
      border-bottom: 1px solid rgba(180, 180, 180, 0.4); } }
  .Table tfoot {
    background-color: transparent;
    border-top: 2px solid rgba(180, 180, 180, 0.4); }
  @media screen and (max-width: 47.9375em) {
    .Table.is-responsive {
      border-width: 0; }
      .Table.is-responsive th,
      .Table.is-responsive td {
        padding: 0; }
      .Table.is-responsive tr + tr {
        border-top: 1px solid rgba(180, 180, 180, 0.4);
        margin-top: 1.11111rem;
        padding-top: 1.11111rem; }
      .Table.is-responsive td + td {
        margin-top: 0.55556rem; }
      .Table.is-responsive,
      .Table.is-responsive tbody,
      .Table.is-responsive tfoot,
      .Table.is-responsive tr {
        display: block; }
      .Table.is-responsive tbody tr {
        display: flex;
        flex-direction: column; }
      .Table.is-responsive tbody td {
        border-top: 0; }
        tr:last-child > .Table.is-responsive tbody td {
          border-bottom: 0; }
      .Table.is-responsive thead {
        display: none; }
      .Table.is-responsive tfoot {
        border: 1px solid rgba(180, 180, 180, 0.4);
        border-top-width: 2px; }
      .Table.is-responsive .Table-cell {
        display: flex;
        vertical-align: top;
        width: 100%; }
        .Table.is-responsive .Table-cell[data-label]::before {
          content: attr(data-label);
          font-size: 90%;
          font-weight: 500;
          display: block;
          flex-shrink: 0;
          text-align: left;
          width: 6.66667rem; } }

.Table--striped tbody tr:nth-child(even) {
  background-color: #fafafa; }

@media screen and (max-width: 63.9375em) {
  .Table--striped.is-responsive tbody tr:nth-child(even) {
    background-color: transparent; } }

.Toggle {
  height: 0.77778rem;
  width: 1.11111rem;
  position: relative;
  cursor: pointer;
  transform: rotate(0deg); }
  .Toggle-item {
    height: 0.11111rem;
    width: 100%;
    position: absolute;
    transition-property: top, transform, opacity;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
    background-color: currentColor;
    border-radius: 0px;
    display: block;
    transform: rotate(0deg); }
    .Toggle-item:nth-child(1) {
      top: 0; }
      .show-nav .Toggle-item:nth-child(1) {
        top: 0.33333rem;
        transform: rotate(135deg); }
    .Toggle-item:nth-child(2) {
      top: 0.33333rem; }
      .show-nav .Toggle-item:nth-child(2) {
        opacity: 0; }
    .Toggle-item:nth-child(3) {
      top: 0.66667rem; }
      .show-nav .Toggle-item:nth-child(3) {
        top: 0.33333rem;
        transform: rotate(-135deg); }

/* Alert */
.Alert--success {
  color: #659667;
  background-color: #eef6ef;
  border-color: #dfeddf; }
  .Alert--success a {
    color: #4c714d; }

.Alert--info {
  color: #5690bf;
  background-color: #ebf4fb;
  border-color: #d7e9f7; }
  .Alert--info a {
    color: #396d96; }

.Alert--warning {
  color: #bf8c40;
  background-color: #fcf4e9;
  border-color: #f9ead3; }
  .Alert--warning a {
    color: #8f6930; }

.Alert--danger {
  color: #c56c66;
  background-color: #fceeed;
  border-color: #f8dbd9; }
  .Alert--danger a {
    color: #a2453e; }

/* Button */
.Site-Ocular .Button.has-icon {
  display: inline-flex;
  align-items: center; }

.Site-Xperify .Button.has-icon .Button-text {
  display: inline-flex;
  align-items: center; }

.Button-object {
  display: block; }

.Site-Xperify .Button--primary .Button-mask--fill,
.Site-Xperify .Button--primary .Button-mask--outline {
  fill: #101010; }

.Site-Ocular .Button--primary:not(.Button--outline) {
  background-color: #0026ff;
  color: #ffffff;
  font-weight: 700; }
  .Site-Ocular .Button--primary:not(.Button--outline):active, .Site-Ocular .Button--primary:not(.Button--outline):focus, .Site-Ocular .Button--primary:not(.Button--outline):hover, .Site-Ocular .Button--primary:not(.Button--outline).is-hovered {
    background-color: #101010; }

.Site-Xperify .Button--primary:not(.Button--outline) {
  color: #ffffff; }
  .Site-Xperify .Button--primary:not(.Button--outline) .Button-object {
    fill: #ffffff; }
  .Site-Xperify .Button--primary:not(.Button--outline):active, .Site-Xperify .Button--primary:not(.Button--outline):focus, .Site-Xperify .Button--primary:not(.Button--outline):hover, .Site-Xperify .Button--primary:not(.Button--outline).is-active, .Site-Xperify .Button--primary:not(.Button--outline).is-hovered {
    color: #101010; }
    .Site-Xperify .Button--primary:not(.Button--outline):active .Button-object, .Site-Xperify .Button--primary:not(.Button--outline):focus .Button-object, .Site-Xperify .Button--primary:not(.Button--outline):hover .Button-object, .Site-Xperify .Button--primary:not(.Button--outline).is-active .Button-object, .Site-Xperify .Button--primary:not(.Button--outline).is-hovered .Button-object {
      fill: #101010; }

.Site-Ocular .Button--primary.Button--outline {
  color: #101010;
  border-color: #101010; }
  .Site-Ocular .Button--primary.Button--outline:active, .Site-Ocular .Button--primary.Button--outline:focus, .Site-Ocular .Button--primary.Button--outline:hover, .Site-Ocular .Button--primary.Button--outline.is-active, .Site-Ocular .Button--primary.Button--outline.is-hovered {
    background-color: #101010;
    border-color: #101010;
    color: #ffffff; }

.Site-Xperify .Button--primary.Button--outline {
  color: #101010; }
  .Site-Xperify .Button--primary.Button--outline .Button-object {
    fill: #101010; }
  .Site-Xperify .Button--primary.Button--outline:active, .Site-Xperify .Button--primary.Button--outline:focus, .Site-Xperify .Button--primary.Button--outline:hover, .Site-Xperify .Button--primary.Button--outline.is-active, .Site-Xperify .Button--primary.Button--outline.is-hovered {
    color: #ffffff; }
    .Site-Xperify .Button--primary.Button--outline:active .Button-object, .Site-Xperify .Button--primary.Button--outline:focus .Button-object, .Site-Xperify .Button--primary.Button--outline:hover .Button-object, .Site-Xperify .Button--primary.Button--outline.is-active .Button-object, .Site-Xperify .Button--primary.Button--outline.is-hovered .Button-object {
      fill: #ffffff; }

.Site-Xperify .Button--secondary .Button-mask--fill,
.Site-Xperify .Button--secondary .Button-mask--outline {
  fill: #ff8000; }

.Site-Ocular .Button--secondary:not(.Button--outline) {
  background-color: #101010;
  color: #ffffff;
  font-weight: 700; }
  .Site-Ocular .Button--secondary:not(.Button--outline):active, .Site-Ocular .Button--secondary:not(.Button--outline):focus, .Site-Ocular .Button--secondary:not(.Button--outline):hover, .Site-Ocular .Button--secondary:not(.Button--outline).is-active, .Site-Ocular .Button--secondary:not(.Button--outline).is-hovered {
    background-color: #0026ff; }

.Site-Xperify .Button--secondary:not(.Button--outline) {
  color: #ffffff; }
  .Site-Xperify .Button--secondary:not(.Button--outline) .Button-object {
    fill: #ffffff; }
  .Site-Xperify .Button--secondary:not(.Button--outline):active, .Site-Xperify .Button--secondary:not(.Button--outline):focus, .Site-Xperify .Button--secondary:not(.Button--outline):hover, .Site-Xperify .Button--secondary:not(.Button--outline).is-active, .Site-Xperify .Button--secondary:not(.Button--outline).is-hovered {
    color: #ff8000; }
    .Site-Xperify .Button--secondary:not(.Button--outline):active .Button-object, .Site-Xperify .Button--secondary:not(.Button--outline):focus .Button-object, .Site-Xperify .Button--secondary:not(.Button--outline):hover .Button-object, .Site-Xperify .Button--secondary:not(.Button--outline).is-active .Button-object, .Site-Xperify .Button--secondary:not(.Button--outline).is-hovered .Button-object {
      fill: #ff8000; }

.Site-Ocular .Button--secondary.Button--outline {
  color: #ffffff;
  border-color: #ffffff; }
  .Site-Ocular .Button--secondary.Button--outline:active, .Site-Ocular .Button--secondary.Button--outline:focus, .Site-Ocular .Button--secondary.Button--outline:hover, .Site-Ocular .Button--secondary.Button--outline.is-active, .Site-Ocular .Button--secondary.Button--outline.is-hovered {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #101010; }

.Site-Xperify .Button--secondary.Button--outline {
  color: #ff8000; }
  .Site-Xperify .Button--secondary.Button--outline .Button-object {
    fill: #ff8000; }
  .Site-Xperify .Button--secondary.Button--outline:active, .Site-Xperify .Button--secondary.Button--outline:focus, .Site-Xperify .Button--secondary.Button--outline:hover, .Site-Xperify .Button--secondary.Button--outline.is-active, .Site-Xperify .Button--secondary.Button--outline.is-hovered {
    color: #ffffff; }
    .Site-Xperify .Button--secondary.Button--outline:active .Button-object, .Site-Xperify .Button--secondary.Button--outline:focus .Button-object, .Site-Xperify .Button--secondary.Button--outline:hover .Button-object, .Site-Xperify .Button--secondary.Button--outline.is-active .Button-object, .Site-Xperify .Button--secondary.Button--outline.is-hovered .Button-object {
      fill: #ffffff; }

.Site-Xperify .Button--tertiary .Button-mask--fill,
.Site-Xperify .Button--tertiary .Button-mask--outline {
  fill: #e60080; }

.Site-Ocular .Button--tertiary.Button--outline {
  background-color: #101010;
  color: #ffffff;
  border-color: #101010;
  font-weight: 500; }
  .Site-Ocular .Button--tertiary.Button--outline:active, .Site-Ocular .Button--tertiary.Button--outline:focus, .Site-Ocular .Button--tertiary.Button--outline:hover, .Site-Ocular .Button--tertiary.Button--outline.is-active, .Site-Ocular .Button--tertiary.Button--outline.is-hovered {
    background-color: #0026ff;
    border-color: #0026ff;
    color: #ffffff; }

.Site-Xperify .Button--tertiary:not(.Button--outline) {
  color: #ffffff; }
  .Site-Xperify .Button--tertiary:not(.Button--outline) .Button-object {
    fill: #ffffff; }
  .Site-Xperify .Button--tertiary:not(.Button--outline):active, .Site-Xperify .Button--tertiary:not(.Button--outline):focus, .Site-Xperify .Button--tertiary:not(.Button--outline):hover, .Site-Xperify .Button--tertiary:not(.Button--outline).is-active, .Site-Xperify .Button--tertiary:not(.Button--outline).is-hovered {
    color: #e60080; }
    .Site-Xperify .Button--tertiary:not(.Button--outline):active .Button-object, .Site-Xperify .Button--tertiary:not(.Button--outline):focus .Button-object, .Site-Xperify .Button--tertiary:not(.Button--outline):hover .Button-object, .Site-Xperify .Button--tertiary:not(.Button--outline).is-active .Button-object, .Site-Xperify .Button--tertiary:not(.Button--outline).is-hovered .Button-object {
      fill: #e60080; }

.Site-Xperify .Button--tertiary.Button--outline {
  color: #e60080; }
  .Site-Xperify .Button--tertiary.Button--outline .Button-object {
    fill: #e60080; }
  .Site-Xperify .Button--tertiary.Button--outline:active, .Site-Xperify .Button--tertiary.Button--outline:focus, .Site-Xperify .Button--tertiary.Button--outline:hover, .Site-Xperify .Button--tertiary.Button--outline.is-active, .Site-Xperify .Button--tertiary.Button--outline.is-hovered {
    color: #ffffff; }
    .Site-Xperify .Button--tertiary.Button--outline:active .Button-object, .Site-Xperify .Button--tertiary.Button--outline:focus .Button-object, .Site-Xperify .Button--tertiary.Button--outline:hover .Button-object, .Site-Xperify .Button--tertiary.Button--outline.is-active .Button-object, .Site-Xperify .Button--tertiary.Button--outline.is-hovered .Button-object {
      fill: #ffffff; }

.Button--quaternary.Button--outline {
  background-color: #ffffff;
  color: #000000;
  border-color: #ffffff;
  font-weight: 500; }
  .Button--quaternary.Button--outline:active, .Button--quaternary.Button--outline:focus, .Button--quaternary.Button--outline:hover, .Button--quaternary.Button--outline.is-active, .Button--quaternary.Button--outline.is-hovered {
    background-color: #0026ff;
    border-color: #0026ff;
    color: #ffffff; }

.Site-Xperify .Button--white .Button-mask--fill,
.Site-Xperify .Button--white .Button-mask--outline {
  fill: #ffffff; }

.Site-Xperify .Button--white.Button--outline {
  color: #ffffff; }
  .Site-Xperify .Button--white.Button--outline .Button-object {
    fill: #ffffff; }
  .Site-Xperify .Button--white.Button--outline:active, .Site-Xperify .Button--white.Button--outline:focus, .Site-Xperify .Button--white.Button--outline:hover, .Site-Xperify .Button--white.Button--outline.is-active, .Site-Xperify .Button--white.Button--outline.is-hovered {
    color: #101010; }
    .Site-Xperify .Button--white.Button--outline:active .Button-object, .Site-Xperify .Button--white.Button--outline:focus .Button-object, .Site-Xperify .Button--white.Button--outline:hover .Button-object, .Site-Xperify .Button--white.Button--outline.is-active .Button-object, .Site-Xperify .Button--white.Button--outline.is-hovered .Button-object {
      fill: #101010; }

.Button--info {
  background-color: #2196f3;
  color: #ffffff; }
  .Button--info:not(.Button--outline):active, .Button--info:not(.Button--outline):focus, .Button--info:not(.Button--outline):hover, .Button--info:not(.Button--outline).is-active {
    background-color: #1976d2;
    color: #ffffff; }
  .Button--info.Button--outline {
    color: #2196f3; }
    .Button--info.Button--outline:active, .Button--info.Button--outline:focus, .Button--info.Button--outline:hover, .Button--info.Button--outline.is-active {
      color: #1976d2; }

.Button--success {
  background-color: #4caf50;
  color: #ffffff; }
  .Button--success:not(.Button--outline):active, .Button--success:not(.Button--outline):focus, .Button--success:not(.Button--outline):hover, .Button--success:not(.Button--outline).is-active {
    background-color: #388e3c;
    color: #ffffff; }
  .Button--success.Button--outline {
    color: #4caf50; }
    .Button--success.Button--outline:active, .Button--success.Button--outline:focus, .Button--success.Button--outline:hover, .Button--success.Button--outline.is-active {
      color: #388e3c; }

.Button--warning {
  background-color: #ff9800;
  color: #ffffff; }
  .Button--warning:not(.Button--outline):active, .Button--warning:not(.Button--outline):focus, .Button--warning:not(.Button--outline):hover, .Button--warning:not(.Button--outline).is-active {
    background-color: #f57c00;
    color: #ffffff; }
  .Button--warning.Button--outline {
    color: #ff9800; }
    .Button--warning.Button--outline:active, .Button--warning.Button--outline:focus, .Button--warning.Button--outline:hover, .Button--warning.Button--outline.is-active {
      color: #f57c00; }

.Button--danger {
  background-color: #f44336;
  color: #ffffff; }
  .Button--danger:not(.Button--outline):active, .Button--danger:not(.Button--outline):focus, .Button--danger:not(.Button--outline):hover, .Button--danger:not(.Button--outline).is-active {
    background-color: #d32f2f;
    color: #ffffff; }
  .Button--danger.Button--outline {
    color: #f44336; }
    .Button--danger.Button--outline:active, .Button--danger.Button--outline:focus, .Button--danger.Button--outline:hover, .Button--danger.Button--outline.is-active {
      color: #d32f2f; }

.Site-Ocular .Button--outline {
  border: 1px solid;
  border-radius: 1.11111rem;
  font-size: 0.77778rem;
  font-weight: 500;
  padding: 0.55556em 1.66667em; }

.Button--fixed {
  transition-property: background-color, color, transform;
  transition-duration: 200ms;
  transition-timing-function: linear;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9998; }
  .Button--fixed:not(.is-active) {
    transform: translateX(100%); }

.Button--large {
  font-size: 1.25rem;
  padding-right: 3.88889rem;
  padding-left: 3.88889rem; }

.Button--small {
  font-size: 0.75rem;
  padding-right: 1.2963rem;
  padding-left: 1.2963rem; }

.Button--full {
  display: block;
  width: 100%; }

.Button--bare {
  border: 0;
  border-radius: 0;
  padding: 0; }
  .Button--bare:hover {
    text-decoration: underline; }

.fancybox-button {
  height: 3.33333rem;
  width: 3.33333rem; }

.Modal {
  background-color: #f7f7f7;
  padding: 2.22222rem;
  max-width: 55.55556rem;
  width: 100%; }
  @media screen and (min-width: 48em) {
    .Modal {
      padding: 4.16667rem; } }
  .Modal-body {
    max-width: 38.88889rem;
    margin-right: auto;
    margin-left: auto; }
  .Modal-title {
    margin-bottom: 2.22222rem; }
    .Modal-title::before {
      border-left: 3px solid currentColor;
      content: '';
      height: 1.27778rem;
      display: inline-block;
      margin-right: 1.66667rem; }
    .Modal-title::after {
      content: none; }

/* Outdated Browser */
.Outdated {
  background-color: #f44336;
  color: #ffffff;
  font-size: 12px;
  padding: 12px 24px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1500; }
  .Outdated-title {
    display: block;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 6px; }
  .Outdated-text {
    margin-bottom: 0;
    line-height: 1; }
  .Outdated-button {
    border: 2px solid #ffffff;
    color: #ffffff;
    cursor: pointer;
    display: block;
    font-weight: 700;
    line-height: 1;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    width: 230px;
    /*need for IE*/
    position: absolute;
    top: 12px;
    right: 24px; }
    .Outdated-button:hover {
      background-color: #ffffff;
      color: #f44336; }
  * html .Outdated {
    position: absolute; }

.Site-Ocular .cookie-though {
  /* The colors used to style the modal */
  --ct-primary-400: #0026ff;
  --ct-primary-300: #101010;
  --ct-primary-200: #f7f7f7;
  --ct-primary-100: #f7f7f7;
  /* The colors used to style: customizationButton, headerDescription and cookiePolicy */
  --ct-text: #101010;
  /* The colors used to style the title elements */
  --ct-subtitle: var(--ct-primary-300);
  --ct-title: var(--ct-primary-400);
  /* The color used to style the cookie svg */
  --ct-cookie: var(--ct-primary-400);
  /* The colors used to style a policy */
  --ct-policy: var(--ct-white);
  --ct-policy-enabled: #f7f6f8;
  --ct-policy-text: var(--ct-text);
  --ct-policy-text-enabled: var(--ct-text);
  --ct-policy-essential: var(--ct-primary-100);
  /* The colors used to style the sliders */
  --ct-slider-primary: var(--ct-text);
  --ct-slider-secondary: #d1d5db;
  --ct-slider-enabled-primary: var(--ct-primary-400);
  --ct-slider-enabled-secondary: var(--ct-primary-400);
  /* The font color of the text inside a policy when it's enabled  */
  --ct-enabled-option-color: var(--ct-white);
  /* The white color */
  --ct-white: #ffffff;
  /* The margin underneath text elements */
  --ct-text-margin: 0.22222rem;
  /* The padding of the modal and margin of the banner, toggleButton and customization */
  --ct-default-padding: 0.88889rem;
  /* The padding/margin used to seperate the options and the permission buttons */
  --ct-seperator-padding: 0.44444rem;
  /* The font size of the header title */
  --ct-heading-font-size: 1.11111rem;
  /* The font size of the header sub title */
  --ct-sub-heading-font-size: 0.77778rem;
  /* The font size of text */
  --ct-text-font-size: 0.66667rem;
  /* The font size of the customize button and permission button labels */
  --ct-button-font-size: 0.72222rem;
  /* The font used in the app */
  --ct-primary-font: inherit;
  /* The font used for header description and policy description */
  --ct-secondary-font: inherit;
  /* The z-index you want the root container to have */
  --ct-z-index: 9999; }

.Site-Xperify .cookie-though {
  /* The colors used to style the modal */
  --ct-primary-400: #ff8000;
  --ct-primary-300: #FF9933;
  --ct-primary-200: #F7F7F7;
  --ct-primary-100: #F7F7F7;
  /* The colors used to style: customizationButton, headerDescription and cookiePolicy */
  --ct-text: #101010;
  /* The colors used to style the title elements */
  --ct-subtitle: var(--ct-primary-300);
  --ct-title: var(--ct-primary-400);
  /* The color used to style the cookie svg */
  --ct-cookie: var(--ct-primary-400);
  /* The colors used to style a policy */
  --ct-policy: var(--ct-white);
  --ct-policy-enabled: #f7f6f8;
  --ct-policy-text: var(--ct-text);
  --ct-policy-text-enabled: var(--ct-text);
  --ct-policy-essential: var(--ct-primary-100);
  /* The colors used to style the sliders */
  --ct-slider-primary: var(--ct-text);
  --ct-slider-secondary: #d1d5db;
  --ct-slider-enabled-primary: var(--ct-primary-400);
  --ct-slider-enabled-secondary: var(--ct-primary-400);
  /* The font color of the text inside a policy when it's enabled  */
  --ct-enabled-option-color: var(--ct-white);
  /* The white color */
  --ct-white: #ffffff;
  /* The margin underneath text elements */
  --ct-text-margin: 0.22222rem;
  /* The padding of the modal and margin of the banner, toggleButton and customization */
  --ct-default-padding: 0.88889rem;
  /* The padding/margin used to seperate the options and the permission buttons */
  --ct-seperator-padding: 0.44444rem;
  /* The font size of the header title */
  --ct-heading-font-size: 1.11111rem;
  /* The font size of the header sub title */
  --ct-sub-heading-font-size: 0.77778rem;
  /* The font size of text */
  --ct-text-font-size: 0.66667rem;
  /* The font size of the customize button and permission button labels */
  --ct-button-font-size: 0.72222rem;
  /* The font used in the app */
  --ct-primary-font: inherit;
  /* The font used for header description and policy description */
  --ct-secondary-font: inherit;
  /* The z-index you want the root container to have */
  --ct-z-index: 9999; }

/* Header */
.Header {
  transition-property: background-color, box-shadow, color, transform;
  transition-duration: 200ms;
  transition-timing-function: linear;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  background-color: #f7f7f7;
  color: #101010;
  padding-top: 1.11111rem;
  padding-bottom: 1.11111rem; }
  .Site-Xperify .Header {
    background-color: #ffffff; }
  @media screen and (min-width: 48em) {
    .Header {
      transition-delay: 1000ms; }
      html:not(.is-loaded) .HomePage .Header {
        transform: translateY(-100%); } }
  .Header > .Container {
    display: flex;
    align-items: center; }
    .Header > .Container > .Logo {
      margin-right: auto; }
    .Header > .Container > .Nav--lang {
      margin-right: 1.11111rem; }
    @media screen and (min-width: 48em) {
      .Header > .Container > .Logo {
        margin-right: 1.11111rem; }
      .Header > .Container > .Nav--priority {
        margin-right: auto;
        margin-left: auto; }
      .Header > .Container > .Nav--lang {
        margin-left: 1.11111rem; } }
  .Header--sticky {
    position: fixed;
    will-change: transform;
    transition-delay: 0s; }
    .Header--sticky.is-pinned {
      transform: translateY(0%); }
    .Header--sticky.is-unpinned {
      transform: translateY(-100%); }
    .Header--sticky.is-notTop {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25); }
  .show-nav .Header, .Header--black {
    background-color: #000000;
    color: #ffffff; }
  .show-nav.Site-Xperify .Header,
  .Site-Xperify .Header--black {
    background-color: #f7f6f8;
    color: #101010; }
  .Header::before {
    transition-property: height;
    transition-duration: 500ms;
    transition-timing-function: ease-in-out;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
    background-color: #000000;
    content: '';
    height: 100vh; }
    .Site-Xperify .Header::before {
      background-color: #f7f6f8; }
    html:not(.show-nav) .Header::before {
      height: 0; }

/* Navigation */
.Navigation-toggle {
  color: currentColor;
  display: block;
  opacity: .8; }

.Navigation-body {
  position: fixed;
  top: 6.55556rem;
  right: 0;
  left: 0;
  z-index: 1;
  transition-property: opacity, visibility;
  transition-duration: 200ms;
  transition-timing-function: linear;
  color: #ffffff;
  padding: 2.5rem;
  height: calc(100vh - 6.55556rem); }
  .Site-Xperify .Navigation-body {
    color: #101010; }
  @media screen and (min-width: 48em) {
    .Navigation-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 6.94444rem; } }
  .Navigation-body > .Container {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr auto; }
    @media screen and (max-width: 47.9375em) {
      .Navigation-body > .Container {
        height: 100%;
        padding-right: 0;
        padding-left: 0; } }
    @media screen and (min-width: 48em) {
      .Navigation-body > .Container {
        display: grid;
        grid-template-columns: 1fr auto auto;
        grid-template-rows: 1fr auto; } }
  html:not(.show-nav) .Navigation-body {
    opacity: 0;
    visibility: hidden; }

/* Types */
/* Main */
.Header .Nav--main {
  grid-column: 1/2;
  grid-row: 2/3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1; }
  @media screen and (max-width: 47.9375em) {
    .Header .Nav--main {
      align-self: start;
      margin-top: 1.66667rem;
      margin-bottom: 1.66667rem; } }
  @media screen and (min-width: 48em) {
    .Header .Nav--main {
      font-size: 2.22222rem;
      line-height: 1.2;
      grid-column: 1/2;
      grid-row: 1/3; } }
  .Header .Nav--main .Nav-item {
    display: inline-flex; }
    .Header .Nav--main .Nav-item + .Nav-item {
      margin-top: 0.27778rem; }
      @media screen and (min-width: 48em) {
        .Header .Nav--main .Nav-item + .Nav-item {
          margin-top: 1.11111rem; } }
    .show-nav .Header .Nav--main .Nav-item {
      transition-property: opacity, transform;
      transition-duration: 200ms, 500ms;
      transition-timing-function: linear, cubic-bezier(0.19, 1, 0.22, 1); }
      .show-nav .Header .Nav--main .Nav-item:nth-child(1) {
        transition-delay: 100ms; }
      .show-nav .Header .Nav--main .Nav-item:nth-child(2) {
        transition-delay: 200ms; }
      .show-nav .Header .Nav--main .Nav-item:nth-child(3) {
        transition-delay: 300ms; }
      .show-nav .Header .Nav--main .Nav-item:nth-child(4) {
        transition-delay: 400ms; }
      .show-nav .Header .Nav--main .Nav-item:nth-child(5) {
        transition-delay: 500ms; }
      .show-nav .Header .Nav--main .Nav-item:nth-child(6) {
        transition-delay: 600ms; }
      .show-nav .Header .Nav--main .Nav-item:nth-child(7) {
        transition-delay: 700ms; }
      .show-nav .Header .Nav--main .Nav-item:nth-child(8) {
        transition-delay: 800ms; }
      .show-nav .Header .Nav--main .Nav-item:nth-child(9) {
        transition-delay: 900ms; }
      .show-nav .Header .Nav--main .Nav-item:nth-child(10) {
        transition-delay: 1000ms; }
    html:not(.show-nav) .Header .Nav--main .Nav-item {
      opacity: 0;
      transform: translateY(1.11111rem); }
  .Header .Nav--main .Nav-link {
    transition-property: color, padding-right;
    transition-duration: 200ms;
    transition-timing-function: linear;
    display: inline-flex;
    align-items: center; }
    .Header .Nav--main .Nav-link::before {
      transition-property: opacity, margin-left;
      transition-duration: 200ms;
      transition-timing-function: linear;
      height: 0.38889rem;
      width: 0.38889rem;
      background-color: #ffffff;
      border-radius: 100%;
      content: '';
      display: block;
      margin-right: 0.77778rem; }
      .Site-Xperify .Header .Nav--main .Nav-link::before {
        height: 0.77778rem;
        width: 0.77778rem;
        background-color: transparent;
        background-image: url("../img/xperify/icons/nav--hover.svg");
        border-radius: 0; }
    .Header .Nav--main .Nav-link:not(:hover) {
      padding-right: 1.16667rem; }
      .Header .Nav--main .Nav-link:not(:hover)::before {
        margin-left: -1.16667rem;
        opacity: 0; }
    .Header .Nav--main .Nav-link.is-active {
      font-weight: 500; }

.Nav--main .Nav-link, .Nav--main .Nav-link:active, .Nav--main .Nav-link:focus, .Nav--main .Nav-link:hover {
  color: currentColor; }

.Footer .Nav--main .Nav-link:hover {
  text-decoration: underline; }

/* Lang */
.Nav--lang {
  font-size: 0.72222rem;
  line-height: 1.23077; }
  .Nav--lang .Nav-list {
    text-transform: uppercase; }
  .Nav--lang .Nav-item + .Nav-item {
    margin-left: 0.55556rem; }
  .Nav--lang .Nav-link {
    color: currentColor; }
    .Nav--lang .Nav-link.is-active {
      font-weight: 700; }

.Nav--footer .Nav-list {
  margin-left: -1.11111rem; }

.Nav--footer .Nav-item {
  padding-left: 1.11111rem; }

.Nav--footer .Nav-link, .Nav--footer .Nav-link:active, .Nav--footer .Nav-link:focus, .Nav--footer .Nav-link:hover {
  color: currentColor; }

.Nav--footer .Nav-link:hover {
  text-decoration: underline; }

.Nav--pagination {
  border-top: 2px solid #101010;
  width: calc(100% - 6.66667rem);
  padding-top: 2.22222rem; }
  .Nav--pagination .Nav-item {
    padding-right: 2.22222rem; }
    .Nav--pagination .Nav-item::after {
      display: none; }
  .Nav--pagination .Nav-link {
    font-weight: 600; }
    .Nav--pagination .Nav-link, .Nav--pagination .Nav-link:active, .Nav--pagination .Nav-link:focus, .Nav--pagination .Nav-link:hover {
      color: rgba(16, 16, 16, 0.35); }
    .Nav--pagination .Nav-link:hover, .Nav--pagination .Nav-link.is-active {
      color: #101010; }

.Header > .Container > .Nav--priority {
  font-size: 0.77778rem;
  transition-property: opacity, visibility;
  transition-duration: 300ms;
  transition-timing-function: linear; }
  .Site-Xperify .Header > .Container > .Nav--priority {
    font-size: 0.88889rem;
    font-weight: 600; }
  .show-nav .Header > .Container > .Nav--priority {
    opacity: 0;
    visibility: hidden; }
  .Header > .Container > .Nav--priority .Nav-link.is-active {
    position: relative; }
    .Header > .Container > .Nav--priority .Nav-link.is-active::before {
      position: absolute;
      bottom: 100%;
      left: 50%;
      z-index: 1;
      height: 0.38889rem;
      width: 0.38889rem;
      background-color: currentColor;
      border-radius: 100%;
      content: '';
      display: block;
      transform: translateX(-50%) translateY(-150%); }
      .Site-Xperify .Header > .Container > .Nav--priority .Nav-link.is-active::before {
        display: none; }

.Navigation .Nav--priority {
  transition-property: opacity, transform;
  transition-duration: 500ms;
  transition-timing-function: linear, cubic-bezier(0.19, 1, 0.22, 1);
  font-size: 1.27778rem;
  grid-column: 1/4;
  grid-row: 1/2;
  align-self: end;
  line-height: 1.15; }
  html:not(.show-nav) .Navigation .Nav--priority {
    opacity: 0;
    transform: translateY(-1.11111rem); }
  @media screen and (min-width: 48em) {
    .Navigation .Nav--priority {
      grid-column: 2/4;
      grid-row: 1/2;
      font-size: 1.11111rem;
      margin-bottom: 2.22222rem; } }
  .Navigation .Nav--priority .Nav-item + .Nav-item {
    margin-top: 0.83333rem; }
    @media screen and (min-width: 48em) {
      .Navigation .Nav--priority .Nav-item + .Nav-item {
        margin-top: 1.11111rem; } }
  .Navigation .Nav--priority .Nav-link {
    transition-property: color, padding-right;
    transition-duration: 200ms;
    transition-timing-function: linear;
    display: inline-flex;
    align-items: center; }
    .Navigation .Nav--priority .Nav-link::before {
      transition-property: opacity, margin-left;
      transition-duration: 200ms;
      transition-timing-function: linear;
      height: 0.38889rem;
      width: 0.38889rem;
      background-color: currentColor;
      border-radius: 100%;
      content: '';
      display: block;
      margin-right: 0.77778rem; }
      .Site-Xperify .Navigation .Nav--priority .Nav-link::before {
        height: 0.77778rem;
        width: 0.77778rem;
        background-color: transparent;
        background-image: url("../img/xperify/icons/nav--hover.svg");
        background-position: center top;
        background-repeat: no-repeat;
        border-radius: 0; }
    .Navigation .Nav--priority .Nav-link:not(:hover) {
      padding-right: 1.16667rem; }
      .Navigation .Nav--priority .Nav-link:not(:hover)::before {
        margin-left: -1.16667rem;
        opacity: 0; }
    .Navigation .Nav--priority .Nav-link.is-active {
      font-weight: 500; }
  .Navigation .Nav--priority .Nav-item-dropdown-second span .Icon {
    display: none; }
  .Navigation .Nav--priority .Nav-item-dropdown-second span:before {
    content: "";
    display: block;
    height: 0.77778rem;
    width: 0.77778rem;
    margin-left: -1.16667rem;
    margin-right: 0.77778rem; }
  .Navigation .Nav--priority .Nav-item-dropdown-second .Nav-dropdown-wrapper {
    height: auto;
    opacity: 1; }
  .Navigation .Nav--priority .Nav-item-dropdown-second .Nav-link {
    white-space: nowrap; }

.Nav--priority .Nav-item {
  z-index: 1; }

.Nav--priority .Nav-item-dropdown {
  position: relative;
  z-index: 0; }
  .Nav--priority .Nav-item-dropdown span {
    display: flex; }
    .Nav--priority .Nav-item-dropdown span .Icon {
      margin-left: 10px;
      width: 10px; }
  .Nav--priority .Nav-item-dropdown:hover .Nav-dropdown-wrapper {
    display: block; }
  .Nav--priority .Nav-item-dropdown .Nav-dropdown {
    margin: 0;
    width: 100%;
    padding: 1.11111rem 2.22222rem;
    background-color: #f7f6f8; }
    .Nav--priority .Nav-item-dropdown .Nav-dropdown-wrapper {
      position: absolute;
      z-index: 1;
      top: 0rem;
      left: calc(50% + 1.16667rem);
      transform: translateX(-50%);
      display: none;
      width: 21.66667rem;
      padding-top: 3.33333rem; }
    .Nav--priority .Nav-item-dropdown .Nav-dropdown::before {
      content: "";
      position: absolute;
      left: 0rem;
      top: 2.22222rem;
      right: 0;
      margin: auto;
      width: 0;
      height: 0;
      border-left: 1.11111rem solid transparent;
      border-right: 1.11111rem solid transparent;
      border-bottom: 1.11111rem solid #f7f6f8; }
    .Nav--priority .Nav-item-dropdown .Nav-dropdown .Nav-item {
      padding: 0;
      text-align: center; }
      .Nav--priority .Nav-item-dropdown .Nav-dropdown .Nav-item:not(:last-child) {
        margin-bottom: 0.5rem;
        border-bottom: 1px solid #b4b4b4;
        padding-bottom: 0.5rem; }

.Nav--priority .Nav-item-dropdown-second {
  position: relative;
  z-index: 0;
  transition: all 1s ease-in-out;
  cursor: pointer;
  width: 200px; }
  .Nav--priority .Nav-item-dropdown-second span {
    display: flex; }
    .Nav--priority .Nav-item-dropdown-second span .Icon {
      margin-left: 10px;
      width: 10px; }
  .Nav--priority .Nav-item-dropdown-second:hover .Nav-dropdown-wrapper {
    height: auto;
    opacity: 1; }
  .Nav--priority .Nav-item-dropdown-second .Nav-dropdown {
    margin: 0;
    width: 100%;
    padding: 1.11111rem 2.22222rem;
    background-color: #f7f6f8; }
    .Nav--priority .Nav-item-dropdown-second .Nav-dropdown-wrapper {
      height: 0;
      opacity: 0;
      position: relative;
      z-index: 1; }
      @media screen and (max-width: 47.9375em) {
        .Nav--priority .Nav-item-dropdown-second .Nav-dropdown-wrapper {
          width: 50px; } }
    .Nav--priority .Nav-item-dropdown-second .Nav-dropdown::before {
      content: "";
      position: absolute;
      left: 0rem;
      top: 2.22222rem;
      right: 0;
      margin: auto;
      width: 0;
      height: 0;
      border-left: 1.11111rem solid transparent;
      border-right: 1.11111rem solid transparent;
      border-bottom: 1.11111rem solid #f7f6f8; }
    .Nav--priority .Nav-item-dropdown-second .Nav-dropdown .Nav-item {
      padding: 0;
      text-align: center; }
      .Nav--priority .Nav-item-dropdown-second .Nav-dropdown .Nav-item:not(:last-child) {
        margin-bottom: 0.5rem;
        padding-bottom: 0.5rem; }

.Footer .Nav--priority {
  font-size: 0.83333rem; }

.Nav--priority .Nav-list {
  margin-left: -2.22222rem; }

.Nav--priority .Nav-item {
  padding-left: 2.22222rem; }
  .Footer .Nav--priority .Nav-item + .Nav-item {
    margin-top: 0.55556rem; }

.Nav--priority .Nav-link, .Nav--priority .Nav-link:active, .Nav--priority .Nav-link:focus, .Nav--priority .Nav-link:hover {
  color: currentColor; }

.Footer .Nav--priority .Nav-link:hover {
  text-decoration: underline; }

.Nav--socialMedia {
  padding-bottom: 10px; }
  .Navigation .Nav--socialMedia {
    grid-column: 1/3;
    grid-row: 3/4;
    transition-property: opacity, transform;
    transition-duration: 500ms;
    transition-timing-function: linear, cubic-bezier(0.19, 1, 0.22, 1);
    transition-delay: 600ms; }
    html:not(.show-nav) .Navigation .Nav--socialMedia {
      opacity: 0;
      transform: translateY(-1.11111rem); }
    @media screen and (min-width: 48em) {
      .Navigation .Nav--socialMedia {
        grid-column: 2/4;
        grid-row: 2/3;
        align-self: end; } }
  .Nav--socialMedia .Nav-list {
    margin-left: -1.11111rem; }
  .Nav--socialMedia .Nav-item {
    padding-left: 1.11111rem; }
  .Nav--socialMedia .Nav-link, .Nav--socialMedia .Nav-link:active, .Nav--socialMedia .Nav-link:focus, .Nav--socialMedia .Nav-link:hover {
    color: currentColor; }
  .Nav--socialMedia .Nav-icon {
    fill: currentColor;
    display: block; }

.Nav--services .Nav-link {
  transition-property: color, text-shadow, padding-right, padding-left;
  transition-duration: 200ms;
  transition-timing-function: linear;
  display: inline-flex;
  align-items: center;
  white-space: nowrap; }
  .Nav--services .Nav-link:not(:hover):not(.is-active) {
    padding-right: 1.66667rem; }
  @media screen and (min-width: 48em) {
    .Services-category:nth-child(even) .Nav--services .Nav-link {
      flex-direction: row-reverse; }
      .Services-category:nth-child(even) .Nav--services .Nav-link:not(:hover):not(.is-active) {
        padding-right: 0;
        padding-left: 1.66667rem; } }
  .Nav--services .Nav-link, .Nav--services .Nav-link:active, .Nav--services .Nav-link:focus, .Nav--services .Nav-link:hover {
    color: currentColor; }
  .Nav--services .Nav-link.is-active {
    font-weight: 500; }

.Nav--services .Nav-label::after {
  display: block;
  content: attr(data-title);
  font-weight: 500;
  height: 0;
  overflow: hidden;
  visibility: hidden; }

.Nav--services .Nav-icon {
  transition-property: margin-left, opacity;
  transition-duration: 200ms;
  transition-timing-function: linear;
  display: block;
  flex-shrink: 0;
  text-align: left;
  width: 1.66667rem; }
  @media screen and (min-width: 48em) {
    .Services-category:nth-child(even) .Nav--services .Nav-icon {
      transform: scale(-1, 1); } }

.Nav--services .Nav-link:not(.is-active) .Nav-icon {
  margin-left: -1.66667rem;
  opacity: 0; }

.Nav--side .Nav-item {
  margin-bottom: 1.11111rem; }
  .Nav--side .Nav-item::after {
    display: none; }
  .Nav--side .Nav-item:last-child {
    margin-bottom: 0; }

.Nav--side .Nav-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.66667rem;
  background-color: #edf0f4;
  font-weight: 600; }
  .Nav--side .Nav-link, .Nav--side .Nav-link:active, .Nav--side .Nav-link:focus, .Nav--side .Nav-link:hover {
    color: #101010; }
  .Nav--side .Nav-link.is-active {
    color: #e60080; }
    .Nav--side .Nav-link.is-active .Nav-icon {
      fill: #e60080; }

/* Logo */
.Logo {
  flex-shrink: 0; }
  .Logo, .Logo:active, .Logo:focus, .Logo:hover {
    color: currentColor; }
  .Logo-object {
    height: 4.18222rem;
    width: 4.16667rem;
    display: block; }
    .Logo--footer .Logo-object {
      height: 6.61111rem;
      width: 4.72222rem; }

/* Fields */
/* Fields: field */
.Form-field--input,
.Form-field--textarea, .Form-field--select, .Form-field--select[multiple] {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  appearance: none;
  background-color: transparent;
  border-radius: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid currentColor;
  border-left: 0;
  color: currentColor;
  vertical-align: middle;
  width: 100%; }
  .Form-item.is-error .Form-field--input,
  .Form-item.is-error .Form-field--textarea, .Form-item.is-error .Form-field--select {
    border-color: #f44336; }
  .Form-item.is-success .Form-field--input,
  .Form-item.is-success .Form-field--textarea, .Form-item.is-success .Form-field--select {
    border-color: #4caf50; }

.Form-field--input:focus,
.Form-field--textarea:focus, .Form-field--select:focus {
  border-color: currentColor;
  outline: 0;
  z-index: 1; }
  .Form-item.is-error .Form-field--input:focus,
  .Form-item.is-error .Form-field--textarea:focus, .Form-item.is-error .Form-field--select:focus { }
  .Form-item.is-success .Form-field--input:focus,
  .Form-item.is-success .Form-field--textarea:focus, .Form-item.is-success .Form-field--select:focus { }

.Form-field {
  font-family: inherit;
  position: relative; }

.Form-indicator {
  user-select: none; }

.Form-field--xs {
  max-width: 3.33333rem !important; }

.Form-field--sm {
  max-width: 5.55556rem !important; }

.Form-field--md {
  max-width: 11.11111rem !important; }

.Form-field--lg {
  max-width: 16.66667rem !important; }

.Form-field--xl {
  max-width: 22.22222rem !important; }

.Form-field--full {
  max-width: none !important; }

/* Fields: check */
.Form-field--radio,
.Form-field--checkbox {
  cursor: pointer;
  display: flex;
  user-select: none; }
  .Form-field--radio:not(:first-of-type),
  .Form-field--checkbox:not(:first-of-type) {
    margin-top: 0.13889rem; }
  .Form-field--radio input[type="radio"],
  .Form-field--radio input[type="checkbox"],
  .Form-field--checkbox input[type="radio"],
  .Form-field--checkbox input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
    .Form-field--radio input[type="radio"]:checked ~ .Form-indicator,
    .Form-field--radio input[type="checkbox"]:checked ~ .Form-indicator,
    .Form-field--checkbox input[type="radio"]:checked ~ .Form-indicator,
    .Form-field--checkbox input[type="checkbox"]:checked ~ .Form-indicator {
      background-color: currentColor;
      border-color: currentColor; }
    .Form-field--radio input[type="radio"]:focus ~ .Form-indicator,
    .Form-field--radio input[type="checkbox"]:focus ~ .Form-indicator,
    .Form-field--checkbox input[type="radio"]:focus ~ .Form-indicator,
    .Form-field--checkbox input[type="checkbox"]:focus ~ .Form-indicator {
      border-color: currentColor; }
  .Form-field--radio .Form-indicator,
  .Form-field--checkbox .Form-indicator {
    position: relative;
    top: 0.19444em;
    background-color: transparent;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 0.55556em 0.55556em;
    border: 1px solid currentColor;
    flex-shrink: 0;
    height: 0.88889rem;
    margin-right: 0.55556rem;
    width: 0.88889rem; }

.Form-field--radio > .Form-indicator {
  border-radius: 100%; }

.Form-field--radio input[type="radio"]:checked ~ .Form-indicator::after {
  background-color: #ffffff;
  border-radius: 100%;
  content: '';
  height: 0.3em;
  width: 0.3em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.Form-field--checkbox > .Form-indicator {
  border-radius: 0; }

.Form-field--checkbox input[type="checkbox"]:checked ~ .Form-indicator {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.8 6.3'%3E%3Ctitle%3ECheck%3C/title%3E%3Cpath d='M6.4,0,5.7.7,2.9,3.5l-.8-.8L1.4,2,0,3.4l.7.7L2.2,5.6l.7.7.7-.7L7.1,2.1l.7-.7Z' fill='%23fff'/%3E%3C/svg%3E"); }
  .Block--form .Form-field--checkbox input[type="checkbox"]:checked ~ .Form-indicator {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.8 6.3'%3E%3Ctitle%3ECheck%3C/title%3E%3Cpath d='M6.4,0,5.7.7,2.9,3.5l-.8-.8L1.4,2,0,3.4l.7.7L2.2,5.6l.7.7.7-.7L7.1,2.1l.7-.7Z' fill='%23000'/%3E%3C/svg%3E"); }

/* Fields: file */
.Form-field--file {
  cursor: pointer;
  display: block;
  max-width: 16.11111rem; }
  .Form-field--file input[type='file'] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
    .Form-field--file input[type='file']:focus ~ .Form-indicator {
      border-color: currentColor; }
      .Form-item.is-error .Form-field--file input[type='file']:focus ~ .Form-indicator { }
      .Form-item.is-success .Form-field--file input[type='file']:focus ~ .Form-indicator { }
  .Form-field--file .Form-indicator {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: transparent;
    border-radius: 1.41667rem;
    border: 1px solid currentColor;
    color: currentColor;
    display: block;
    line-height: normal;
    overflow: hidden;
    padding: 1em 0em;
    position: relative;
    white-space: nowrap;
    width: 100%; }
    .Form-field--file .Form-indicator::before {
      content: attr(data-file-text);
      display: block;
      padding-right: 5.55556rem;
      padding-left: 1.66667rem; }
    .Form-field--file .Form-indicator::after {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D%2211%22%20height%3D%228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2022.36%2016.46%22%3E%3Cpath%20d%3D%22M21%208.23H0M13.36.73l8%207.5-8%207.5%22%20fill%3D%22none%22%20stroke%3D%22%23ffffff%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%222%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
      transition-property: background-color;
      transition-duration: 200ms;
      transition-timing-function: linear;
      background-repeat: no-repeat;
      background-position: 50%;
      background-color: #000000;
      border-radius: 1.41667rem;
      color: #757575;
      content: '';
      text-align: center;
      width: 2.83333rem;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0; }
      .Form-field--file:hover .Form-indicator::after {
        background-color: #0026ff; }

/* Fields: input */
.Form-field--input {
  padding: 1.66667em 0em 0.44444em 0em; }

.Form-field--textarea {
  padding: 1.55556em 0em 0.33333em 0em; }

/* Fields: select */
select {
  cursor: pointer;
  width: 100% !important; }

.Form-field--select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20292.36%20402%22%3E%3Cpath%20d%3D%22M18.27%2C164.45H274.09a18.17%2C18.17%2C0%2C0%2C0%2C12.85-31.12L159%2C5.42a17.93%2C17.93%2C0%2C0%2C0-25.7%2C0L5.42%2C133.33a17.93%2C17.93%2C0%2C0%2C0%2C0%2C25.7A17.57%2C17.57%2C0%2C0%2C0%2C18.27%2C164.45Z%22%2F%3E%3Cpath%20d%3D%22M274.09%2C237.55H18.27A17.57%2C17.57%2C0%2C0%2C0%2C5.42%2C243a17.93%2C17.93%2C0%2C0%2C0%2C0%2C25.7l127.91%2C127.9a17.92%2C17.92%2C0%2C0%2C0%2C25.7%2C0L286.93%2C268.66a18.27%2C18.27%2C0%2C0%2C0-12.85-31.12Z%22%2F%3E%3C%2Fsvg%3E");
  background-position: right 0.55556rem center;
  background-repeat: no-repeat;
  background-size: 0.55556rem;
  line-height: normal;
  overflow: hidden;
  padding: 1.66667em 2.22222em 0.44444em 0em; }

.Form-field--select[multiple] {
  height: auto; }
  .Form-field--select[multiple] option {
    padding: 0.83333em 0em 0.22222em 0em; }
    .Form-field--select[multiple] option + option {
      border-top: 1px solid currentColor; }

/* Slider */
/* Slider: core */
.Slider {
  position: relative;
  z-index: 1;
  /* Fix of Webkit flickering */
  overflow: hidden; }
  .Slider--no-flexbox .Slider-item {
    float: left; }
  .Slider--vertical > .Slider-items {
    flex-direction: column; }
  .Slider-items {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    transition-property: transform; }
  .Slider--android .Slider-item, .Slider-items {
    transform: translate3d(0px, 0, 0); }
  .Slider--multirow > .Slider-items {
    flex-wrap: wrap; }
  .Slider--free-mode > .Slider-items {
    transition-timing-function: ease-out;
    margin: 0 auto; }
  .Slider-item {
    height: 100%;
    width: 100%;
    flex-shrink: 0;
    position: relative;
    transition-property: transform; }
    .Slider-item.is-blank {
      visibility: hidden; }
  .Slider--autoheight,
  .Slider--autoheight .Slider-item {
    height: auto; }
  .Slider--autoheight .Slider-items {
    align-items: flex-start;
    transition-property: transform, height; }
  .Slider--3d {
    perspective: 1200px; }
    .Slider--3d .Slider-items,
    .Slider--3d .Slider-item,
    .Slider--3d .Slider-item-shadow-left,
    .Slider--3d .Slider-item-shadow-right,
    .Slider--3d .Slider-item-shadow-top,
    .Slider--3d .Slider-item-shadow-bottom,
    .Slider--3d .swiper-cube-shadow {
      transform-style: preserve-3d; }
    .Slider--3d .Slider-item-shadow-left,
    .Slider--3d .Slider-item-shadow-right,
    .Slider--3d .Slider-item-shadow-top,
    .Slider--3d .Slider-item-shadow-bottom {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: 10; }
    .Slider--3d .Slider-item-shadow-left {
      background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
    .Slider--3d .Slider-item-shadow-right {
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
    .Slider--3d .Slider-item-shadow-top {
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
    .Slider--3d .Slider-item-shadow-bottom {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .Slider--wp8-horizontal,
  .Slider--wp8-horizontal > .Slider-items {
    touch-action: pan-y; }
  .Slider--wp8-vertical,
  .Slider--wp8-vertical > .Slider-items {
    touch-action: pan-x; }

/* Slider: a11y */
.Slider .Slider-notification {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1000;
  pointer-events: none;
  opacity: 0; }

/* Slider: fade */
.Slider--fade.Slider--free-mode .Slider-item {
  transition-timing-function: ease-out; }

.Slider--fade .Slider-item {
  pointer-events: none;
  transition-property: opacity; }
  .Slider--fade .Slider-item .Slider-item {
    pointer-events: none; }

.Slider--fade .is-active,
.Slider--fade .is-active .is-active {
  pointer-events: auto; }

/* Slider: coverflow */
.Slider--coverflow .Slider-items {
  -ms-perspective: 1200px;
  /* Windows 8 IE 10 fix */ }

/* Slider: cube */
.Slider--cube {
  overflow: visible; }
  .Slider--cube .Slider-item {
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
    pointer-events: none;
    transform-origin: 0 0;
    visibility: hidden;
    z-index: 1; }
    .Slider--cube .Slider-item .Slider-item {
      pointer-events: none; }
  .Slider--cube.Slider--rtl .Slider-item {
    transform-origin: 100% 0; }
  .Slider--cube .is-active,
  .Slider--cube .is-active .is-active {
    pointer-events: auto; }
  .Slider--cube .is-active,
  .Slider--cube .is-next,
  .Slider--cube .is-prev,
  .Slider--cube .is-next + .Slider-item {
    pointer-events: auto;
    visibility: visible; }
  .Slider--cube .swiper-slide-shadow-top,
  .Slider--cube .swiper-slide-shadow-bottom,
  .Slider--cube .swiper-slide-shadow-left,
  .Slider--cube .swiper-slide-shadow-right {
    backface-visibility: hidden;
    z-index: 0; }
  .Slider--cube .swiper-cube-shadow {
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    background: #000000;
    filter: blur(50px);
    opacity: 0.6; }

/* Slider: flip */
.Slider--flip {
  overflow: visible; }
  .Slider--flip .Slider-item {
    backface-visibility: hidden;
    pointer-events: none;
    z-index: 1; }
    .Slider--flip .Slider-item .Slider-item {
      pointer-events: none; }
  .Slider--flip .is-active,
  .Slider--flip .is-active .is-active {
    pointer-events: auto; }
  .Slider--flip .Slider-item--shadow-top,
  .Slider--flip .Slider-item--shadow-bottom,
  .Slider--flip .Slider-item--shadow-left,
  .Slider--flip .Slider-item--shadow-right {
    backface-visibility: hidden;
    z-index: 0; }

/* Slider: lazy */
.Slider-preloader {
  height: 2.33333rem;
  width: 2.33333rem;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
  margin-top: -1.16667rem;
  margin-left: -1.16667rem; }
  .Slider-preloader:after {
    height: 100%;
    width: 100%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    content: '';
    display: block; }

.Slider-preloader--white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg); } }

/* Slider: navigation */
.Slider-buttons {
  display: flex;
  margin-top: 1.11111rem;
  margin-left: -0.55556rem; }
  @media screen and (min-width: 48em) {
    .Slider-buttons {
      flex-direction: column;
      margin-top: -0.55556rem; } }

.Slider-button {
  transition-property: background-color, opacity;
  transition-duration: 200ms;
  transition-timing-function: linear;
  height: 2.5rem;
  width: 2.5rem;
  background-color: #959595;
  background-image: none;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 0.55556rem; }
  @media screen and (min-width: 48em) {
    .Slider-button {
      margin-top: 0.55556rem; } }
  .Slider-button:not(.is-disabled):hover {
    background-color: rgba(149, 149, 149, 0.35); }
  .Slider-button.is-disabled {
    cursor: auto;
    opacity: 0.35; }
  .Slider-button.is-locked {
    display: none; }

.SliderButton-icon {
  transform: rotate(90deg);
  display: block; }
  .Slider-button--next .SliderButton-icon {
    transform: rotate(-90deg); }

/* Slider: pagination */
.Slider-pagination {
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: linear;
  position: absolute;
  z-index: 10;
  text-align: center;
  transform: translate3d(0, 0, 0); }
  .Slider-pagination.is-hidden {
    opacity: 0; }
  .Slider-pagination.is-locked {
    display: none; }

.Slider-pagination--fraction,
.Slider-pagination--custom,
.Slider--horizontal > .Slider-pagination--bullets {
  bottom: 10px;
  left: 0;
  width: 100%; }

.Slider-pagination--bullets-dynamic {
  overflow: hidden;
  font-size: 0; }
  .Slider-pagination--bullets-dynamic .Slider-pagination-item {
    transform: scale(0.33);
    position: relative; }
  .Slider-pagination--bullets-dynamic .is-active {
    transform: scale(1); }
  .Slider-pagination--bullets-dynamic .is-active-main {
    transform: scale(1); }
  .Slider-pagination--bullets-dynamic .is-active-prev {
    transform: scale(0.66); }
  .Slider-pagination--bullets-dynamic .is-active-prev-prev {
    transform: scale(0.33); }
  .Slider-pagination--bullets-dynamic .is-active-next {
    transform: scale(0.66); }
  .Slider-pagination--bullets-dynamic .is-active-next-next {
    transform: scale(0.33); }

.Slider-pagination-item {
  height: 0.44444rem;
  width: 0.44444rem;
  background: #000000;
  border-radius: 100%;
  display: inline-block;
  opacity: 0.2; }
  button.Slider-pagination-item {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    appearance: none; }
  .is-clickable .Slider-pagination-item {
    cursor: pointer; }
  .Slider-pagination-item.is-active {
    background-color: currentColor;
    opacity: 1; }

.Slider--vertical > .Slider-pagination--bullets {
  right: 10px;
  top: 50%;
  transform: translate3d(0px, -50%, 0); }
  .Slider--vertical > .Slider-pagination--bullets .Slider-pagination-item {
    display: block;
    margin-right: 0.33333rem;
    margin-left: 0.33333rem; }
  .Slider--vertical > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic {
    top: 50%;
    transform: translateY(-50%);
    width: 8px; }
    .Slider--vertical > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic .Slider-pagination-item {
      display: inline-block;
      transition-property: transform, top;
      transition-duration: 200ms; }

.Slider--horizontal > .Slider-pagination--bullets .Slider-pagination-item {
  margin: 0 4px; }

.Slider--horizontal > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap; }
  .Slider--horizontal > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic .Slider-pagination-item {
    transition-property: transform, left;
    transition-duration: 200ms; }

.Slider--horizontal.Slider--rtl > .Slider-pagination--bullets-dynamic .Slider-pagination-item {
  transition-property: transform, right;
  transition-duration: 200ms; }

.Slider-pagination--progressbar {
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute; }
  .Slider-pagination--progressbar .Slider-pagination-fill {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: currentColor;
    transform: scale(0);
    transform-origin: left top; }
  .Slider--rtl .Slider-pagination--progressbar .Slider-pagination-fill {
    transform-origin: right top; }
  .Slider--horizontal > .Slider-pagination--progressbar,
  .Slider--vertical > .Slider-pagination--progressbar.Slider-pagination--progressbar-opposite {
    height: 0.22222rem;
    width: 100%;
    left: 0;
    top: 0; }
  .Slider--vertical > .Slider-pagination--progressbar,
  .Slider--horizontal > .Slider-pagination--progressbar.Slider-pagination--progressbar-opposite {
    height: 100%;
    width: 0.22222rem;
    left: 0;
    top: 0; }

/* Slider: scrollbar */
.Slider-scrollbar {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.55556rem;
  position: relative;
  -ms-touch-action: none; }
  .Slider--horizontal > .Slider-scrollbar {
    height: 0.27778rem;
    width: 98%;
    position: absolute;
    bottom: 3px;
    left: 1%;
    z-index: 50; }
  .Slider--vertical > .Slider-scrollbar {
    height: 98%;
    width: 0.27778rem;
    position: absolute;
    top: 1%;
    right: 3px;
    z-index: 50; }
  .Slider-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0.55556rem; }
  .Slider-scrollbar-cursor-drag {
    cursor: move; }
  .Slider-scrollbar .is-locked {
    display: none; }

/* Slider: thumbs */
/* Slider: zoom */
.Slider--zoom {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; }
  .Slider--zoom > img,
  .Slider--zoom > svg,
  .Slider--zoom > canvas {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; }
  .Slider--zoom .is-zoomed {
    cursor: move; }

.LazyLoad,
.LazyLoading {
  opacity: 0; }

.LazyLoaded {
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: linear;
  opacity: 1; }

button {
  transition-property: background-color, border-color, color;
  transition-duration: 200ms;
  transition-timing-function: linear; }

body:not(.user-is-tabbing) span:focus,
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
  outline: none; }

.Scroll {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  height: 100vh;
  width: 100vw;
  transform-style: preserve-3d; }
  .Scroll,
  .Scroll-content .ScrollHeight {
    will-change: transform;
    backface-visibility: hidden;
    perspective: 1000; }
  .ScrollHeight {
    position: relative;
    width: 100%; }

@media screen and (min-width: 48em) {
  @supports (mix-blend-mode: exclusion) {
    .Cursor {
      position: fixed;
      z-index: 9999999;
      height: 4.44444rem;
      width: 4.44444rem;
      transform-origin: 50% 50%;
      pointer-events: none;
      mix-blend-mode: exclusion; }
      .Cursor-item {
        transition-property: transform;
        transition-duration: 300ms;
        transition-timing-function: ease-in-out;
        height: 100%;
        width: 100%;
        background-color: #ffffff;
        border-radius: 100%;
        transform: scale(0.15);
        mix-blend-mode: exclusion; }
        .Cursor--md .Cursor-item {
          transform: scale(0.5); }
        .Cursor--lg .Cursor-item {
          transform: scale(1); }
    .has-cursor, .has-cursor * {
      cursor: none !important; } } }

.Site-Ocular .has-borderLeft {
  border-left: 3px solid;
  padding-left: 1.66667rem; }

.Site-Ocular .has-borderBottom::after {
  border-bottom: 3px solid;
  content: '';
  display: block;
  max-width: 3.33333rem;
  margin-top: 1.11111rem; }

.Bg-title {
  color: transparent;
  font-weight: bold;
  opacity: 0.2;
  line-height: 1.3;
  text-transform: lowercase;
  user-select: none;
  text-shadow: -1px -1px 0 rgba(255, 255, 255, 0.1), 1px -1px 0 rgba(255, 255, 255, 0.1), -1px 1px 0 rgba(255, 255, 255, 0.1), 1px 1px 0 rgba(255, 255, 255, 0.1); }
  @media screen and (max-width: 37.4375em) {
    .Bg-title {
      font-size: 3.33333rem; } }
  @media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
    .Bg-title {
      font-size: calc(3.33333rem + 114 * ((100vw - 33.33333rem) / 424)); } }
  @media screen and (min-width: 64em) {
    .Bg-title {
      font-size: 9.66667rem; } }
  .is-white .Bg-title,
  .is-grey .Bg-title {
    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.1), 1px -1px 0 rgba(0, 0, 0, 0.1), -1px 1px 0 rgba(0, 0, 0, 0.1), 1px 1px 0 rgba(0, 0, 0, 0.1); }
  @supports (-webkit-text-stroke: 1px black) {
    .Bg-title {
      text-shadow: none;
      -webkit-text-stroke: 1px #ffffff; }
      .is-white .Bg-title,
      .is-grey .Bg-title {
        text-shadow: none;
        -webkit-text-stroke: 1px rgba(0, 0, 0, 0.5); } }

.Link {
  font-size: 0.61111rem;
  color: #ffffff;
  cursor: pointer;
  text-transform: lowercase; }
  .Link.has-icon {
    display: inline-flex;
    align-items: center; }
  .Link:hover {
    text-decoration: underline; }

.Media-link {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  color: currentColor;
  text-decoration: none; }

.Media-object {
  grid-column: 1/2;
  grid-row: 1/2;
  background-color: #101010;
  max-width: 48.33333rem;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden; }

.Site-Ocular .Media-image {
  filter: blur(2px);
  opacity: 0.63; }

.Media .Play {
  position: relative;
  z-index: 1;
  grid-column: 1/2;
  grid-row: 1/2;
  align-self: center;
  justify-self: center; }

.Play {
  color: #ffffff;
  display: flex;
  align-items: center;
  font-weight: 500; }
  .Play::before {
    border: 1.27778rem solid transparent;
    height: 0;
    width: 0;
    border-right: none;
    border-left-width: 1.5rem;
    border-left-color: #ffffff;
    content: '';
    display: block;
    margin-right: 1.11111rem; }

.FormItem {
  font-size: 0.77778rem;
  max-width: 25.55556rem; }
  .FormItem .FormField-label a:not([class]) {
    color: currentColor; }
    .FormItem .FormField-label a:not([class]):hover {
      text-decoration: none; }

.Form-field--checkbox {
  font-size: 85.71429%; }
  .Form-field--checkbox .Form-indicator {
    position: relative;
    top: 0.11111em; }

@media screen and (min-width: 48em) {
  @supports (clip-path: polygon(0px 0%, 100% 0%, 100% 100%, 0% 100%)) {
    [data-effect] {
      position: relative;
      overflow: hidden; }
      [data-effect] > img,
      [data-effect] > picture,
      [data-effect] > .BoxItem-object--icon {
        transition-property: clip-path, transform;
        transition-duration: 1s;
        transition-timing-function: ease-in;
        clip-path: polygon(0px 0%, 100% 0%, 100% 100%, 0% 100%);
        will-change: clip-path, transform;
        transition-timing-function: cubic-bezier(0, 0, 0.2, 1); }
      [data-effect]:not(.is-active) > img,
      [data-effect]:not(.is-active) > picture,
      [data-effect]:not(.is-active) > .BoxItem-object--icon {
        clip-path: polygon(0px 100%, 100% 100%, 100% 100%, 0% 100%);
        transform: translateY(2.22222rem); } } }

.is-mirror {
  transform: scale(-1, -1); }

.More {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -0.55556rem;
  margin-left: -0.55556rem; }
  .More > .Button {
    margin-top: 0.55556rem;
    margin-left: 0.55556rem; }

.Boxes {
  display: flex;
  flex-wrap: wrap; }
  .Boxes + .Boxes--small {
    margin-top: 0.55556rem; }
  .Boxes:not(.Boxes--small) {
    justify-content: space-between;
    margin-top: -2.22222rem; }
    .Boxes:not(.Boxes--small) > .Box {
      margin-top: 2.22222rem; }
      @media screen and (min-width: 37.5em) {
        .Boxes:not(.Boxes--small) > .Box {
          width: 50%; } }
    @media screen and (min-width: 64em) {
      .Boxes:not(.Boxes--small) {
        margin-left: -8.88889rem; }
        .Boxes:not(.Boxes--small) > .Box {
          padding-left: 8.88889rem; }
        .Boxes:not(.Boxes--small).is-unloaded > .Box {
          opacity: 0; }
        .Boxes:not(.Boxes--small).has-masonry > .Box {
          opacity: 1; } }
    @media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
      .Boxes:not(.Boxes--small) {
        margin-left: -2.22222rem; }
        .Boxes:not(.Boxes--small) > .Box {
          padding-left: 2.22222rem; } }
  .Boxes--small {
    margin-top: -1.11111rem;
    margin-left: -2.22222rem; }
    .Boxes--small > .Box {
      padding-top: 1.11111rem;
      padding-left: 2.22222rem; }
      @media screen and (min-width: 48em) {
        .Boxes--small > .Box {
          width: 33.33333%; } }
      @media screen and (min-width: 37.5em) and (max-width: 47.9375em) {
        .Boxes--small > .Box {
          width: 50%; } }
  .Boxes + .Boxes-more {
    text-align: center;
    margin-top: 3.88889rem; }

.Box {
  width: 100%; }
  .Box .Button {
    transition-property: background-color, color;
    transition-duration: 200ms;
    transition-timing-function: linear; }
  .Box-link {
    display: block; }
    .Box:not(.Box--button) .Box-link {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 1fr 2.22222rem auto; }
      @media screen and (min-width: 64em) {
        .Box:not(.Box--button) .Box-link {
          display: grid;
          grid-template-columns: 100%;
          grid-template-rows: 1fr 3.88889rem auto; } }
    .Box-link, .Box-link:active, .Box-link:focus, .Box-link:hover {
      color: currentColor;
      text-decoration: none; }
  .Box:hover .Button--outline.Button--primary {
    background-color: #101010;
    border-color: #101010;
    color: #ffffff; }
  .Box:hover .ReadMore-text::after {
    opacity: 1; }
  .Box-image {
    transition-property: opacity, transform;
    transition-duration: 200ms;
    transition-timing-function: linear; }
  .Box-object {
    grid-column: 1/2;
    grid-row: 1/3; }
  .BoxItem-object {
    position: relative;
    background-color: #f7f7f7;
    background-image: url("../img/logo.svg");
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: 50%;
    display: block;
    padding-bottom: 100%;
    overflow: hidden;
    height: 0; }
    .Site-Xperify .BoxItem-object {
      background-image: none;
      background-color: #ffffff; }
      .Site-Xperify .BoxItem-object .Icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    .BoxItem-object::after {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20250.35%20251.31%22%3E%3Ctitle%3Eicon%3C%2Ftitle%3E%3Cpath%20d%3D%22M126.91%2C245.41A118.17%2C118.17%2C0%2C1%2C1%2C245.08%2C127.25%2C118.3%2C118.3%2C0%2C0%2C1%2C126.91%2C245.41Zm0-225.69A107.53%2C107.53%2C0%2C1%2C0%2C234.44%2C127.25%2C107.65%2C107.65%2C0%2C0%2C0%2C126.91%2C19.72Zm5.25%2C231.59a113.84%2C113.84%2C0%2C0%2C1-94.67-50.66A113.81%2C113.81%2C0%2C0%2C1%2C113.1%2C2.41%2C113.9%2C113.9%2C0%2C0%2C1%2C245.69%2C146.14%2C114%2C114%2C0%2C0%2C1%2C132.16%2C251.31ZM43.86%2C206a111.8%2C111.8%2C0%2C0%2C0%2C198.73-50.84%2C114%2C114%2C0%2C0%2C1-105.34%2C72.53A113.8%2C113.8%2C0%2C0%2C1%2C43.86%2C206ZM39%2C199.32a111.39%2C111.39%2C0%2C0%2C0%2C74.82%2C28.78%2C112.75%2C112.75%2C0%2C0%2C0%2C13.2-.77A114%2C114%2C0%2C0%2C1%2C22.64%2C114.79%2C111.81%2C111.81%2C0%2C0%2C0%2C39%2C199.32ZM25%2C105.55c-.2%2C2.74-.3%2C5.52-.3%2C8.31A112%2C112%2C0%2C0%2C0%2C136.49%2C225.69H137A111.8%2C111.8%2C0%2C0%2C0%2C207%2C54.41%2C111.78%2C111.78%2C0%2C0%2C0%2C25%2C105.55ZM212.9%2C60.15a113.82%2C113.82%2C0%2C0%2C1-65.85%2C165%2C112.12%2C112.12%2C0%2C0%2C0%2C96.64-79.43q.3-4.12.3-8.31A111.46%2C111.46%2C0%2C0%2C0%2C212.9%2C60.15ZM103.31%2C4.93A111.78%2C111.78%2C0%2C0%2C0%2C33.12%2C193.57%2C113.81%2C113.81%2C0%2C0%2C1%2C23%2C105.18%2C114.13%2C114.13%2C0%2C0%2C1%2C103.31%2C4.93ZM208.53%2C53.08A113.61%2C113.61%2C0%2C0%2C1%2C246%2C136.53%2C111.92%2C111.92%2C0%2C0%2C0%2C123.29%2C2.8%2C113.94%2C113.94%2C0%2C0%2C1%2C208.53%2C53.08ZM113.32%2C4.44a112.16%2C112.16%2C0%2C0%2C0-87.26%2C91.7%2C113.8%2C113.8%2C0%2C0%2C1%2C176.1-48.43%2C111.73%2C111.73%2C0%2C0%2C0-88.3-43.27Z%22%20fill%3D%22rgba(254%2C%20254%2C%20254%2C%200.1)%22%2F%3E%3C%2Fsvg%3E");
      font-size: 1.11111rem;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      transition-property: opacity, visibility;
      transition-duration: 200ms;
      transition-timing-function: linear;
      background-color: rgba(0, 0, 0, 0.8);
      background-repeat: no-repeat;
      background-position: bottom -5vh left -7.5vh;
      color: #ffffff;
      content: attr(data-more);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: 700; }
      .Box-link:not(:hover) .BoxItem-object::after {
        opacity: 0;
        visibility: hidden; }
      .Site-Xperify .BoxItem-object::after {
        display: none; }
    .BoxItem-object.object-fit-polyfill {
      padding-bottom: 0;
      height: 22.66667rem; }
  .Box-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%; }
  .Box-body {
    position: relative;
    z-index: 1;
    grid-column: 1/2;
    grid-row: 2/4;
    background-color: #f7f7f7;
    margin-right: 1.11111rem;
    padding-top: 1.11111rem;
    padding-right: 1.11111rem; }
    .Site-Xperify .Box-body {
      background-color: transparent;
      margin-right: 0;
      padding-right: 0; }
      .Site-Xperify .Box-body p {
        background-color: #f7f7f7; }
    .HomePage .Box-body,
    .SplashPage .Block:not(.is-grey) .Box-body {
      background-color: #ffffff; }
    .Block--related .Box-body {
      background-color: #101010;
      color: #ffffff; }
    .Box-body :last-child {
      margin-bottom: 0; }
    @media screen and (max-width: 63.9375em) {
      .Box-body {
        padding-top: 1.11111rem; } }
    @media screen and (min-width: 64em) {
      .Box-body {
        margin-right: 3.33333rem;
        padding-top: 2.22222rem;
        padding-right: 2.22222rem; } }
  .Box-lead {
    font-size: 0.88889rem;
    color: #b4b4b4;
    font-weight: 500;
    margin-bottom: 0.55556rem; }
    .Site-Xperify .Box-lead {
      color: #101010;
      font-weight: 400; }
  .Box-title {
    color: currentColor;
    line-height: 1.38889; }
    .Site-Xperify .Box-title {
      margin-bottom: 0;
      margin-right: 1.11111rem;
      padding-top: 1.11111rem;
      padding-bottom: 0.83333rem;
      padding-right: 1.11111rem;
      background-color: #f7f7f7; }
      @media screen and (min-width: 64em) {
        .Site-Xperify .Box-title {
          margin-right: 6.66667rem;
          padding-right: 2.22222rem; } }

.Filter {
  display: flex;
  margin-top: 3.88889rem; }
  .Filter-label {
    flex-shrink: 0;
    margin-right: 2.22222rem; }
  .Filter-items {
    display: flex;
    flex-wrap: wrap;
    margin-top: -0.55556rem;
    margin-left: -0.55556rem; }
  .Filter-item {
    font-weight: inherit;
    margin-top: 0.55556rem;
    margin-left: 0.55556rem; }

@media screen and (min-width: 48em) {
  .Contact {
    display: flex;
    flex-direction: row-reverse; } }

.Contact-object {
  max-width: 34rem;
  width: 100%; }
  @media screen and (max-width: 47.9375em) {
    .Contact-object {
      margin-top: 2.22222rem; } }
  @media screen and (min-width: 48em) {
    .Contact-object {
      flex-shrink: 0;
      width: 50%; } }

@media screen and (min-width: 48em) {
  .Contact-body {
    flex-grow: 1; } }
  @media screen and (min-width: 48em) and (max-width: 47.9375em) {
    .Contact-body {
      margin-left: 2.22222rem; } }
  @media screen and (min-width: 48em) and (min-width: 48em) and (max-width: 63.9375em) {
    .Contact-body {
      margin-left: calc(2.22222rem + 105 * ((100vw - 42.66667rem) / 256)); } }
  @media screen and (min-width: 48em) and (min-width: 64em) {
    .Contact-body {
      margin-left: 8.05556rem; } }

.Contact-title {
  margin-bottom: 1.66667rem; }

@media screen and (min-width: 48em) {
  /* Core */
  body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
    transition-duration: 50ms; }
  body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
    transition-delay: 0s; }
    body[data-aos-delay='50'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='50'].is-animated {
      transition-delay: 50ms; }
  body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
    transition-duration: 100ms; }
  body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
    transition-delay: 0s; }
    body[data-aos-delay='100'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='100'].is-animated {
      transition-delay: 100ms; }
  body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
    transition-duration: 150ms; }
  body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
    transition-delay: 0s; }
    body[data-aos-delay='150'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='150'].is-animated {
      transition-delay: 150ms; }
  body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
    transition-duration: 200ms; }
  body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
    transition-delay: 0s; }
    body[data-aos-delay='200'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='200'].is-animated {
      transition-delay: 200ms; }
  body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
    transition-duration: 250ms; }
  body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
    transition-delay: 0s; }
    body[data-aos-delay='250'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='250'].is-animated {
      transition-delay: 250ms; }
  body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
    transition-duration: 300ms; }
  body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
    transition-delay: 0s; }
    body[data-aos-delay='300'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='300'].is-animated {
      transition-delay: 300ms; }
  body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
    transition-duration: 350ms; }
  body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
    transition-delay: 0s; }
    body[data-aos-delay='350'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='350'].is-animated {
      transition-delay: 350ms; }
  body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
    transition-duration: 400ms; }
  body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
    transition-delay: 0s; }
    body[data-aos-delay='400'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='400'].is-animated {
      transition-delay: 400ms; }
  body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
    transition-duration: 450ms; }
  body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
    transition-delay: 0s; }
    body[data-aos-delay='450'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='450'].is-animated {
      transition-delay: 450ms; }
  body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
    transition-duration: 500ms; }
  body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
    transition-delay: 0s; }
    body[data-aos-delay='500'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='500'].is-animated {
      transition-delay: 500ms; }
  body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
    transition-duration: 550ms; }
  body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
    transition-delay: 0s; }
    body[data-aos-delay='550'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='550'].is-animated {
      transition-delay: 550ms; }
  body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
    transition-duration: 600ms; }
  body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
    transition-delay: 0s; }
    body[data-aos-delay='600'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='600'].is-animated {
      transition-delay: 600ms; }
  body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
    transition-duration: 650ms; }
  body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
    transition-delay: 0s; }
    body[data-aos-delay='650'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='650'].is-animated {
      transition-delay: 650ms; }
  body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
    transition-duration: 700ms; }
  body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
    transition-delay: 0s; }
    body[data-aos-delay='700'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='700'].is-animated {
      transition-delay: 700ms; }
  body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
    transition-duration: 750ms; }
  body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
    transition-delay: 0s; }
    body[data-aos-delay='750'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='750'].is-animated {
      transition-delay: 750ms; }
  body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
    transition-duration: 800ms; }
  body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
    transition-delay: 0s; }
    body[data-aos-delay='800'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='800'].is-animated {
      transition-delay: 800ms; }
  body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
    transition-duration: 850ms; }
  body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
    transition-delay: 0s; }
    body[data-aos-delay='850'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='850'].is-animated {
      transition-delay: 850ms; }
  body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
    transition-duration: 900ms; }
  body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
    transition-delay: 0s; }
    body[data-aos-delay='900'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='900'].is-animated {
      transition-delay: 900ms; }
  body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
    transition-duration: 950ms; }
  body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
    transition-delay: 0s; }
    body[data-aos-delay='950'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='950'].is-animated {
      transition-delay: 950ms; }
  body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
    transition-duration: 1000ms; }
  body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
    transition-delay: 0s; }
    body[data-aos-delay='1000'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1000'].is-animated {
      transition-delay: 1000ms; }
  body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
    transition-duration: 1050ms; }
  body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
    transition-delay: 0s; }
    body[data-aos-delay='1050'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1050'].is-animated {
      transition-delay: 1050ms; }
  body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
    transition-duration: 1100ms; }
  body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
    transition-delay: 0s; }
    body[data-aos-delay='1100'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1100'].is-animated {
      transition-delay: 1100ms; }
  body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
    transition-duration: 1150ms; }
  body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
    transition-delay: 0s; }
    body[data-aos-delay='1150'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1150'].is-animated {
      transition-delay: 1150ms; }
  body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
    transition-duration: 1200ms; }
  body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
    transition-delay: 0s; }
    body[data-aos-delay='1200'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1200'].is-animated {
      transition-delay: 1200ms; }
  body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
    transition-duration: 1250ms; }
  body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
    transition-delay: 0s; }
    body[data-aos-delay='1250'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1250'].is-animated {
      transition-delay: 1250ms; }
  body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
    transition-duration: 1300ms; }
  body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
    transition-delay: 0s; }
    body[data-aos-delay='1300'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1300'].is-animated {
      transition-delay: 1300ms; }
  body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
    transition-duration: 1350ms; }
  body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
    transition-delay: 0s; }
    body[data-aos-delay='1350'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1350'].is-animated {
      transition-delay: 1350ms; }
  body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
    transition-duration: 1400ms; }
  body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
    transition-delay: 0s; }
    body[data-aos-delay='1400'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1400'].is-animated {
      transition-delay: 1400ms; }
  body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
    transition-duration: 1450ms; }
  body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
    transition-delay: 0s; }
    body[data-aos-delay='1450'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1450'].is-animated {
      transition-delay: 1450ms; }
  body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
    transition-duration: 1500ms; }
  body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
    transition-delay: 0s; }
    body[data-aos-delay='1500'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1500'].is-animated {
      transition-delay: 1500ms; }
  body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
    transition-duration: 1550ms; }
  body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
    transition-delay: 0s; }
    body[data-aos-delay='1550'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1550'].is-animated {
      transition-delay: 1550ms; }
  body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
    transition-duration: 1600ms; }
  body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
    transition-delay: 0s; }
    body[data-aos-delay='1600'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1600'].is-animated {
      transition-delay: 1600ms; }
  body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
    transition-duration: 1650ms; }
  body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
    transition-delay: 0s; }
    body[data-aos-delay='1650'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1650'].is-animated {
      transition-delay: 1650ms; }
  body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
    transition-duration: 1700ms; }
  body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
    transition-delay: 0s; }
    body[data-aos-delay='1700'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1700'].is-animated {
      transition-delay: 1700ms; }
  body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
    transition-duration: 1750ms; }
  body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
    transition-delay: 0s; }
    body[data-aos-delay='1750'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1750'].is-animated {
      transition-delay: 1750ms; }
  body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
    transition-duration: 1800ms; }
  body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
    transition-delay: 0s; }
    body[data-aos-delay='1800'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1800'].is-animated {
      transition-delay: 1800ms; }
  body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
    transition-duration: 1850ms; }
  body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
    transition-delay: 0s; }
    body[data-aos-delay='1850'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1850'].is-animated {
      transition-delay: 1850ms; }
  body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
    transition-duration: 1900ms; }
  body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
    transition-delay: 0s; }
    body[data-aos-delay='1900'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1900'].is-animated {
      transition-delay: 1900ms; }
  body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
    transition-duration: 1950ms; }
  body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
    transition-delay: 0s; }
    body[data-aos-delay='1950'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1950'].is-animated {
      transition-delay: 1950ms; }
  body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
    transition-duration: 2000ms; }
  body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
    transition-delay: 0s; }
    body[data-aos-delay='2000'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2000'].is-animated {
      transition-delay: 2000ms; }
  body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
    transition-duration: 2050ms; }
  body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
    transition-delay: 0s; }
    body[data-aos-delay='2050'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2050'].is-animated {
      transition-delay: 2050ms; }
  body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
    transition-duration: 2100ms; }
  body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
    transition-delay: 0s; }
    body[data-aos-delay='2100'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2100'].is-animated {
      transition-delay: 2100ms; }
  body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
    transition-duration: 2150ms; }
  body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
    transition-delay: 0s; }
    body[data-aos-delay='2150'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2150'].is-animated {
      transition-delay: 2150ms; }
  body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
    transition-duration: 2200ms; }
  body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
    transition-delay: 0s; }
    body[data-aos-delay='2200'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2200'].is-animated {
      transition-delay: 2200ms; }
  body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
    transition-duration: 2250ms; }
  body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
    transition-delay: 0s; }
    body[data-aos-delay='2250'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2250'].is-animated {
      transition-delay: 2250ms; }
  body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
    transition-duration: 2300ms; }
  body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
    transition-delay: 0s; }
    body[data-aos-delay='2300'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2300'].is-animated {
      transition-delay: 2300ms; }
  body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
    transition-duration: 2350ms; }
  body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
    transition-delay: 0s; }
    body[data-aos-delay='2350'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2350'].is-animated {
      transition-delay: 2350ms; }
  body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
    transition-duration: 2400ms; }
  body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
    transition-delay: 0s; }
    body[data-aos-delay='2400'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2400'].is-animated {
      transition-delay: 2400ms; }
  body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
    transition-duration: 2450ms; }
  body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
    transition-delay: 0s; }
    body[data-aos-delay='2450'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2450'].is-animated {
      transition-delay: 2450ms; }
  body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
    transition-duration: 2500ms; }
  body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
    transition-delay: 0s; }
    body[data-aos-delay='2500'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2500'].is-animated {
      transition-delay: 2500ms; }
  body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
    transition-duration: 2550ms; }
  body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
    transition-delay: 0s; }
    body[data-aos-delay='2550'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2550'].is-animated {
      transition-delay: 2550ms; }
  body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
    transition-duration: 2600ms; }
  body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
    transition-delay: 0s; }
    body[data-aos-delay='2600'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2600'].is-animated {
      transition-delay: 2600ms; }
  body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
    transition-duration: 2650ms; }
  body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
    transition-delay: 0s; }
    body[data-aos-delay='2650'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2650'].is-animated {
      transition-delay: 2650ms; }
  body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
    transition-duration: 2700ms; }
  body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
    transition-delay: 0s; }
    body[data-aos-delay='2700'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2700'].is-animated {
      transition-delay: 2700ms; }
  body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
    transition-duration: 2750ms; }
  body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
    transition-delay: 0s; }
    body[data-aos-delay='2750'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2750'].is-animated {
      transition-delay: 2750ms; }
  body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
    transition-duration: 2800ms; }
  body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
    transition-delay: 0s; }
    body[data-aos-delay='2800'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2800'].is-animated {
      transition-delay: 2800ms; }
  body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
    transition-duration: 2850ms; }
  body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
    transition-delay: 0s; }
    body[data-aos-delay='2850'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2850'].is-animated {
      transition-delay: 2850ms; }
  body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
    transition-duration: 2900ms; }
  body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
    transition-delay: 0s; }
    body[data-aos-delay='2900'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2900'].is-animated {
      transition-delay: 2900ms; }
  body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
    transition-duration: 2950ms; }
  body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
    transition-delay: 0s; }
    body[data-aos-delay='2950'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2950'].is-animated {
      transition-delay: 2950ms; }
  body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
    transition-duration: 3000ms; }
  body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
    transition-delay: 0s; }
    body[data-aos-delay='3000'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='3000'].is-animated {
      transition-delay: 3000ms; }
  /* Easing */
  body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
    transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }
  body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); }
  body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
    transition-timing-function: cubic-bezier(0.42, 0, 1, 1); }
  body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
    transition-timing-function: cubic-bezier(0, 0, 0.58, 1); }
  body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
    transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }
  body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
    transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }
  body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
    transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }
  body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }
  body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
    transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }
  body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
    transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }
  body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
    transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
    transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }
  body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
    transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
    transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }
  body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
    transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  /* Animations */ }

@media screen and (min-width: 48em) {
  html:not(.no-js) [data-aos^='fade'][data-aos^='fade'] {
    opacity: 0;
    transition-property: opacity, transform; }
    html:not(.no-js) [data-aos^='fade'][data-aos^='fade'].is-animated {
      opacity: 1;
      transform: none; }
  html:not(.no-js) [data-aos='fade-up'] {
    transform: translate3d(0, 2.22222rem, 0); }
  html:not(.no-js) [data-aos='fade-down'] {
    transform: translate3d(0, -2.22222rem, 0); }
  html:not(.no-js) [data-aos='fade-right'] {
    transform: translate3d(-2.22222rem, 0, 0); }
  html:not(.no-js) [data-aos='fade-left'] {
    transform: translate3d(2.22222rem, 0, 0); }
  html:not(.no-js) [data-aos='fade-up-right'] {
    transform: translate3d(-2.22222rem, 2.22222rem, 0); }
  html:not(.no-js) [data-aos='fade-up-left'] {
    transform: translate3d(2.22222rem, 2.22222rem, 0); }
  html:not(.no-js) [data-aos='fade-down-right'] {
    transform: translate3d(-2.22222rem, -2.22222rem, 0); }
  html:not(.no-js) [data-aos='fade-down-left'] {
    transform: translate3d(2.22222rem, -2.22222rem, 0); } }

/* Blocks */
.Block {
  padding-top: 2.22222rem;
  padding-bottom: 2.22222rem; }
  @media screen and (min-width: 48em) {
    .Block {
      padding-top: 6.66667rem;
      padding-bottom: 6.66667rem; } }
  .Block.is-white {
    background-color: #ffffff; }
  .Block.is-grey {
    background-color: #f7f7f7; }
    .Site-Xperify .Block.is-grey {
      background-color: #f7f6f8; }
  .Block.is-black {
    background-color: #000000;
    color: #ffffff; }
  .Site-Xperify .Block.Block--title * {
    margin-bottom: 0; }
  .Site-Xperify .Block.Block--title.is-grey {
    padding-bottom: 2.22222rem; }
  .Site-Xperify .Block.Block--textMedia + .Block--textMedia.is-grey,
  .Site-Xperify .Block.Block--textMedia + .Block--Columns.is-grey,
  .Site-Xperify .Block.Block--Columns + .Block--textMedia.is-grey,
  .Site-Xperify .Block.Block--Columns + .Block--Columns.is-grey {
    padding-top: 0; }
  .Site-Xperify .Block:not(.Block--cta):not(.Block--banner-xperify):not(.Block--Quote).is-white + .Block.is-white:not(.Block--cta) {
    padding-top: 0; }
  .Site-Xperify .Block.Block--title.is-grey + .Block.is-grey:not(.Block--cta) {
    padding-top: 0; }
  .Site-Xperify .Block.Block--info.is-grey + .Block.is-grey {
    margin-top: 2.22222rem; }
    @media screen and (min-width: 48em) {
      .Site-Xperify .Block.Block--info.is-grey + .Block.is-grey {
        margin-top: 6.66667rem; } }
  .Site-Xperify .Block.Block--info.is-grey + .Block--Quote {
    margin-top: 2.22222rem; }
    @media screen and (min-width: 48em) {
      .Site-Xperify .Block.Block--info.is-grey + .Block--Quote {
        margin-top: 6.66667rem; } }
  .Site-Xperify .Container + .Block:not(.Block--cta) {
    padding-top: 0; }

.Site-Ocular .Block--banner {
  background-color: #000000;
  color: #ffffff;
  padding-top: 6.55556rem;
  padding-bottom: 2.22222rem;
  overflow: hidden; }
  @media screen and (min-width: 48em) {
    .Site-Ocular .Block--banner {
      height: 100vh; } }
  .Site-Ocular .Block--banner > .Container {
    height: 100%; }

.Site-Ocular .Banner {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: repeat(4, auto);
  height: 100%; }
  @media screen and (min-width: 48em) {
    .Site-Ocular .Banner {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-rows: 1fr auto 1fr 30px; } }
  .Site-Ocular .Banner-object {
    transition-property: transform, opacity;
    transition-duration: 1s;
    transition-timing-function: linear;
    grid-column: 1/3;
    grid-row: 1/4;
    position: relative;
    max-width: 100%;
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }
    .Site-Ocular .Banner-object::after {
      height: 17.66667rem;
      width: 17.77778rem;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20250.35%20251.31%22%3E%3Ctitle%3Eicon%3C%2Ftitle%3E%3Cpath%20d%3D%22M126.91%2C245.41A118.17%2C118.17%2C0%2C1%2C1%2C245.08%2C127.25%2C118.3%2C118.3%2C0%2C0%2C1%2C126.91%2C245.41Zm0-225.69A107.53%2C107.53%2C0%2C1%2C0%2C234.44%2C127.25%2C107.65%2C107.65%2C0%2C0%2C0%2C126.91%2C19.72Zm5.25%2C231.59a113.84%2C113.84%2C0%2C0%2C1-94.67-50.66A113.81%2C113.81%2C0%2C0%2C1%2C113.1%2C2.41%2C113.9%2C113.9%2C0%2C0%2C1%2C245.69%2C146.14%2C114%2C114%2C0%2C0%2C1%2C132.16%2C251.31ZM43.86%2C206a111.8%2C111.8%2C0%2C0%2C0%2C198.73-50.84%2C114%2C114%2C0%2C0%2C1-105.34%2C72.53A113.8%2C113.8%2C0%2C0%2C1%2C43.86%2C206ZM39%2C199.32a111.39%2C111.39%2C0%2C0%2C0%2C74.82%2C28.78%2C112.75%2C112.75%2C0%2C0%2C0%2C13.2-.77A114%2C114%2C0%2C0%2C1%2C22.64%2C114.79%2C111.81%2C111.81%2C0%2C0%2C0%2C39%2C199.32ZM25%2C105.55c-.2%2C2.74-.3%2C5.52-.3%2C8.31A112%2C112%2C0%2C0%2C0%2C136.49%2C225.69H137A111.8%2C111.8%2C0%2C0%2C0%2C207%2C54.41%2C111.78%2C111.78%2C0%2C0%2C0%2C25%2C105.55ZM212.9%2C60.15a113.82%2C113.82%2C0%2C0%2C1-65.85%2C165%2C112.12%2C112.12%2C0%2C0%2C0%2C96.64-79.43q.3-4.12.3-8.31A111.46%2C111.46%2C0%2C0%2C0%2C212.9%2C60.15ZM103.31%2C4.93A111.78%2C111.78%2C0%2C0%2C0%2C33.12%2C193.57%2C113.81%2C113.81%2C0%2C0%2C1%2C23%2C105.18%2C114.13%2C114.13%2C0%2C0%2C1%2C103.31%2C4.93ZM208.53%2C53.08A113.61%2C113.61%2C0%2C0%2C1%2C246%2C136.53%2C111.92%2C111.92%2C0%2C0%2C0%2C123.29%2C2.8%2C113.94%2C113.94%2C0%2C0%2C1%2C208.53%2C53.08ZM113.32%2C4.44a112.16%2C112.16%2C0%2C0%2C0-87.26%2C91.7%2C113.8%2C113.8%2C0%2C0%2C1%2C176.1-48.43%2C111.73%2C111.73%2C0%2C0%2C0-88.3-43.27Z%22%20fill%3D%22rgba(254%2C%20254%2C%20254%2C%200.1)%22%2F%3E%3C%2Fsvg%3E");
      background-size: 31.05556rem 31rem;
      background-repeat: no-repeat;
      background-position: top right;
      content: ''; }
    @media screen and (min-width: 48em) {
      html:not(.is-loaded) .Site-Ocular .Banner-object {
        opacity: 0;
        transform: scale(2); } }
    .Site-Ocular .Banner-object .Slider, .Site-Ocular .Banner-object .Slider-object {
      height: 100%; }
    .Site-Ocular .Banner-object .Slider-item {
      max-width: 88.88889rem;
      width: 100%; }
    @media screen and (max-width: 47.9375em) {
      .Site-Ocular .Banner-object .Slider-item {
        height: auto; } }
    .Site-Ocular .Banner-object .Slider-object {
      position: relative; }
      .Site-Ocular .Banner-object .Slider-object::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-image: linear-gradient(to bottom, rgba(16, 16, 16, 0.53) 0%, rgba(216, 216, 216, 0));
        content: ''; }
      .Site-Ocular .Banner-object .Slider-object--video video {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; }
        @media screen and (max-width: 47.9375em) {
          .Site-Ocular .Banner-object .Slider-object--video video {
            height: 100%;
            width: 100%;
            object-fit: cover; } }
        @media screen and (min-width: 48em) {
          .Site-Ocular .Banner-object .Slider-object--video video {
            height: auto;
            width: auto;
            top: auto;
            left: auto;
            min-width: 100%;
            min-height: 100%; } }
    .Site-Ocular .Banner-object .Slider-object, .Site-Ocular .Banner-object .Slider-image {
      display: block; }
    .Site-Ocular .Banner-object .Slider-image {
      height: 100%;
      width: 100%;
      object-fit: cover; }
  .Site-Ocular .Banner-titles {
    grid-column: 1/3;
    grid-row: 1/2;
    position: relative;
    z-index: 1;
    align-self: center; }
    @media screen and (max-width: 47.9375em) {
      .Site-Ocular .Banner-titles {
        margin-top: 1.11111rem;
        margin-bottom: 1.11111rem; } }
    @media screen and (min-width: 48em) {
      .Site-Ocular .Banner-titles {
        transition-property: opacity;
        transition-duration: 500ms;
        transition-timing-function: linear;
        grid-column: 1/2;
        grid-row: 2/3; }
        html:not(.is-loaded) .Site-Ocular .Banner-titles:not(.is-copy) {
          opacity: 0; }
        .Site-Ocular .Banner-titles.is-copy {
          transition-delay: 250ms;
          grid-row: 1/5;
          align-self: stretch;
          margin-top: -6.55556rem;
          margin-bottom: -2.22222rem; }
          .is-loaded .Site-Ocular .Banner-titles.is-copy {
            opacity: 0; } }
  .Site-Ocular .Banner-title, .Site-Ocular .Banner-subtitle {
    color: currentColor; }
  .Site-Ocular .Banner-title {
    font-weight: 700;
    margin-left: 1.11111rem;
    margin-bottom: 0;
    line-height: 1.05;
    letter-spacing: 0;
    transition-delay: 1s;
    text-shadow: -1px -1px 0 rgba(255, 255, 255, 0.1), 1px -1px 0 rgba(255, 255, 255, 0.1), -1px 1px 0 rgba(255, 255, 255, 0.1), 1px 1px 0 rgba(255, 255, 255, 0.1); }
    @supports (-webkit-text-stroke: 1px black) {
      .Site-Ocular .Banner-title {
        text-shadow: none;
        -webkit-text-stroke: 1px #ffffff; } }
    @media screen and (min-width: 48em) and (max-width: 63.0625em) {
      .Site-Ocular .Banner-title {
        margin-left: 2.22222rem; } }

@media screen and (min-width: 68.75em) and (max-width: 37.4375em) {
  .Site-Ocular .Banner-title {
    margin-left: 1.11111rem; } }

@media screen and (min-width: 68.75em) and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Site-Ocular .Banner-title {
    margin-left: calc(1.11111rem + 140 * ((100vw - 33.33333rem) / 424)); } }

@media screen and (min-width: 68.75em) and (min-width: 64em) {
  .Site-Ocular .Banner-title {
    margin-left: 8.88889rem; } }
    @media screen and (min-width: 48em) {
      .Site-Ocular .Banner-title {
        transition-property: color;
        transition-duration: 250ms;
        transition-timing-function: linear; }
        html:not(.is-loaded) .Site-Ocular .Banner-title:not(.Banner-title--copy) {
          color: transparent; } }
    .Site-Ocular .Banner-title--copy {
      color: transparent; }
  .Site-Ocular .Banner-subtitle {
    line-height: 1.26667; }
    @media screen and (min-width: 48em) {
      .Site-Ocular .Banner-subtitle::after {
        content: none; } }
  .Site-Ocular .Banner-body,
  .Site-Ocular .Banner .Button {
    margin-right: 1.11111rem;
    margin-left: 1.11111rem; }

@media screen and (min-width: 68.75em) and (max-width: 37.4375em) {
  .Site-Ocular .Banner-body,
  .Site-Ocular .Banner .Button {
    margin-right: 1.11111rem; } }

@media screen and (min-width: 68.75em) and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Site-Ocular .Banner-body,
  .Site-Ocular .Banner .Button {
    margin-right: calc(1.11111rem + 140 * ((100vw - 33.33333rem) / 424)); } }

@media screen and (min-width: 68.75em) and (min-width: 64em) {
  .Site-Ocular .Banner-body,
  .Site-Ocular .Banner .Button {
    margin-right: 8.88889rem; } }
    @media screen and (min-width: 48em) {
      .Site-Ocular .Banner-body,
      .Site-Ocular .Banner .Button {
        transition-property: background-color, color, opacity;
        transition-duration: 200ms, 200ms, 500ms;
        transition-timing-function: linear;
        margin-left: 1.66667rem;
        transition-delay: 0s, 0s, 1250ms; }
        html:not(.is-loaded) .Site-Ocular .Banner-body, html:not(.is-loaded)
        .Site-Ocular .Banner .Button {
          opacity: 0; } }
  .Site-Ocular .Banner-body {
    grid-column: 1/3;
    grid-row: 2/3;
    position: relative;
    z-index: 1;
    align-self: start;
    max-width: 18.61111rem; }
    @media screen and (max-width: 47.9375em) {
      .Site-Ocular .Banner-body {
        margin-bottom: 1.11111rem; } }
    @media screen and (min-width: 48em) {
      .Site-Ocular .Banner-body {
        grid-column: 2/3;
        grid-row: 2/3;
        align-self: end; } }
  .Site-Ocular .Banner .Button {
    position: relative;
    z-index: 1;
    grid-column: 1/3;
    grid-row: 3/4;
    justify-self: start;
    align-self: end; }
    @media screen and (max-width: 47.9375em) {
      .Site-Ocular .Banner .Button {
        margin-bottom: 1.11111rem; } }
    @media screen and (min-width: 48em) {
      .Site-Ocular .Banner .Button {
        grid-column: 2/3;
        grid-row: 3/5; } }
  .Site-Ocular .Banner .Slider-pagination {
    position: relative;
    z-index: 1;
    grid-column: 2/3;
    grid-row: 1/2;
    justify-self: end;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    @media screen and (max-width: 47.9375em) {
      .Site-Ocular .Banner .Slider-pagination {
        align-self: center;
        margin-top: 1.11111rem;
        margin-right: 0.72222rem; } }
    @media screen and (min-width: 48em) {
      .Site-Ocular .Banner .Slider-pagination {
        grid-column: 2/3;
        grid-row: 1/4; } }
    .Site-Ocular .Banner .Slider-pagination-item {
      height: 0.38889rem;
      width: 0.38889rem; }
      .Site-Ocular .Banner .Slider-pagination-item + .Slider-pagination-item {
        margin-top: 0.72222rem; }
      .Site-Ocular .Banner .Slider-pagination-item:not(.is-active) {
        background-color: #ffffff;
        opacity: 0.35; }
  .Site-Ocular .Banner .Nav--socialMedia {
    position: relative;
    z-index: 1;
    grid-column: 2/3;
    grid-row: 2/4;
    align-self: end;
    justify-self: end;
    opacity: 0.8; }
    .Site-Ocular .Banner .Nav--socialMedia .Nav-list {
      align-items: center;
      margin-top: -0.83333rem; }
    .Site-Ocular .Banner .Nav--socialMedia .Nav-item {
      padding-top: 0.83333rem; }
  @media screen and (min-width: 48em) {
    .Site-Ocular .Banner .Slider-pagination,
    .Site-Ocular .Banner .Nav--socialMedia {
      transition-property: opacity, transform;
      transition-duration: 500ms;
      transition-timing-function: linear;
      transition-delay: 1250ms;
      transform: translateX(100%);
      width: 2.22222rem; }
      html:not(.is-loaded) .Site-Ocular .Banner .Slider-pagination, html:not(.is-loaded)
      .Site-Ocular .Banner .Nav--socialMedia {
        opacity: 0;
        transform: translateX(200%); } }
  @media screen and (min-width: 64em) {
    .Site-Ocular .Banner .Slider-pagination,
    .Site-Ocular .Banner .Nav--socialMedia {
      width: 4.44444rem; } }

.Block--banner-xperify {
  color: #ffffff;
  padding-top: 0;
  padding-bottom: 2.22222rem;
  overflow: hidden;
  height: calc(90vh - 6.55556rem); }
  .Block--banner-xperify > .Container {
    height: 100%; }
  .Block--banner-xperify .Banner {
    position: relative;
    height: 100%;
    padding-top: 1.11111rem;
    padding-left: 1.11111rem; }
    @media screen and (min-width: 48em) {
      .Block--banner-xperify .Banner {
        padding-top: 3.33333rem;
        padding-left: 3.33333rem; } }
    @media screen and (min-width: 64em) {
      .Block--banner-xperify .Banner {
        padding-top: 5rem;
        padding-left: 5rem; } }
    .Block--banner-xperify .Banner-object {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
      .Block--banner-xperify .Banner-object .Slider, .Block--banner-xperify .Banner-object .Slider-object {
        height: calc(100% - 1.66667rem); }
      .Block--banner-xperify .Banner-object .Slider-object--video {
        overflow: hidden; }
        .Block--banner-xperify .Banner-object .Slider-object--video video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: calc(100% - 1.66667rem);
          object-fit: cover; }
      .Block--banner-xperify .Banner-object .Slider-object, .Block--banner-xperify .Banner-object .Slider-image {
        display: block; }
      .Block--banner-xperify .Banner-object .Slider-image {
        height: 100%;
        width: 100%;
        object-fit: cover; }
      .Block--banner-xperify .Banner-object .Slider .Video-overlay {
        display: none; }
      .Block--banner-xperify .Banner-object .Slider .PlayButton {
        position: absolute;
        right: 1.66667rem;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 6.66667rem;
        height: 6.66667rem;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20d%3D%22M0%2016V3.84L5.12%200H16v12.16L10.88%2016Z%22%20fill%3D%22%23ff8000%22%2F%3E%3C%2Fsvg%3E");
        font-size: 1.16667rem;
        font-weight: 700;
        text-align: center; }
    .Block--banner-xperify .Banner-titles, .Block--banner-xperify .Banner-body,
    .Block--banner-xperify .Banner .Button {
      position: relative;
      z-index: 2; }
    .Block--banner-xperify .Banner-titles, .Block--banner-xperify .Banner-body {
      max-width: 44.44444rem; }
    .Block--banner-xperify .Banner-title, .Block--banner-xperify .Banner-subtitle {
      color: #ffffff; }
    .Block--banner-xperify .Banner .Button {
      margin-top: 2.22222rem; }
    .Block--banner-xperify .Banner .Slider-pagination {
      position: absolute;
      top: 50%;
      right: -2.22222rem;
      justify-self: end;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transform: translateX(100%); }
      @media screen and (max-width: 63.9375em) {
        .Block--banner-xperify .Banner .Slider-pagination {
          right: -0.55556rem; } }
      @media screen and (max-width: 47.9375em) {
        .Block--banner-xperify .Banner .Slider-pagination {
          right: -0.11111rem; } }
      .Block--banner-xperify .Banner .Slider-pagination-item {
        height: 0.88889rem;
        width: 0.88889rem;
        border-radius: 0;
        background-color: transparent;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20d%3D%22M0%2016V3.84L5.12%200H16v12.16L10.88%2016Z%22%20fill%3D%22%23ff8000%22%2F%3E%3C%2Fsvg%3E"); }
        .Block--banner-xperify .Banner .Slider-pagination-item + .Slider-pagination-item {
          margin-top: 0.44444rem; }
        .Block--banner-xperify .Banner .Slider-pagination-item:not(.is-active) {
          opacity: 0.25; }

@media screen and (min-width: 48em) {
  .Text {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 140px auto; }
    .Text-title {
      grid-column: 1/2;
      grid-row: 1/3; }
    .Text-body {
      grid-column: 2/3;
      grid-row: 2/3; } }

.Text-title {
  max-width: 20rem;
  line-height: 1.22; }
  @media screen and (max-width: 47.9375em) {
    .Text-title {
      margin-bottom: 2.22222rem; } }

.Text-body {
  max-width: 29.44444rem; }

.Text--vertical {
  max-width: 40rem; }
  .Text--vertical .Text--title {
    margin-bottom: 1.66667rem; }
  .Text--vertical .Button {
    margin-top: 0.55556rem; }

.Block--cases {
  overflow: hidden;
  position: relative; }
  .Block--cases .Bg-title {
    position: absolute;
    top: 25%;
    right: -2.22222rem; }
  .SplashPage .Block--cases:nth-child(even) {
    background-color: #ffffff;
    padding-top: 2.22222rem;
    padding-bottom: 2.22222rem; }
    @media screen and (min-width: 48em) {
      .SplashPage .Block--cases:nth-child(even) {
        padding-top: 6.66667rem;
        padding-bottom: 6.66667rem; } }
  .HomePage .Block--cases {
    background-color: #ffffff;
    padding-top: 2.22222rem;
    padding-bottom: 2.22222rem; }
    .HomePage .Block--cases + .Block {
      margin-top: 0; }
    @media screen and (min-width: 48em) {
      .HomePage .Block--cases {
        padding-top: 6.66667rem;
        padding-bottom: 6.66667rem; } }

.Cases-header {
  max-width: 36.11111rem;
  margin-bottom: 2.22222rem;
  margin-right: auto;
  margin-left: auto;
  text-align: center; }
  @media screen and (min-width: 48em) {
    .Cases-header {
      margin-bottom: 4.44444rem; } }

.Cases-title.has-borderBottom::after {
  margin-right: auto;
  margin-left: auto; }

.Block--services {
  color: #000000; }
  .SplashPage .Block--services.is-white,
  .SplashPage .Block--services.is-black {
    padding-top: 0;
    padding-bottom: 0; }
  .Block--services.is-black {
    background-color: #000000;
    color: #ffffff; }
  .Block--services.is-black .Container, .Block--services.is-white .Container {
    padding-top: 2.22222rem;
    padding-bottom: 2.22222rem; }
    @media screen and (min-width: 48em) {
      .Block--services.is-black .Container, .Block--services.is-white .Container {
        padding-top: 6.66667rem;
        padding-bottom: 6.66667rem; } }

@media screen and (min-width: 48em) {
  .Services {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    height: 100%;
    margin-left: -2.22222rem; } }

@media screen and (min-width: 48em) {
  .Services-body, .Services-object {
    padding-left: 2.22222rem; } }

@media screen and (min-width: 48em) {
  .Services-body {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0; } }

@media screen and (min-width: 48em) {
  .Services-object {
    max-width: 27.77778rem;
    width: 40%; } }

@media screen and (min-width: 64em) {
  .Services-object {
    width: 50%; } }

.Services-category {
  margin-top: 3.33333rem; }
  @media screen and (min-width: 48em) {
    .Services-category {
      width: auto; }
      .Services-category:last-child {
        text-align: right;
        margin-left: -1.66667rem;
        margin-top: 10.55556rem; } }

.Services-title {
  color: currentColor; }
  @media screen and (min-width: 48em) {
    .Services-category:last-child .Services-title.has-borderBottom::after {
      margin-left: auto; } }

.Services-object .Slider-object, .Services-object .Slider-image {
  width: 100%; }

@media screen and (min-width: 48em) and (max-width: 78.0625em) {
  .Services-object .Slider {
    margin-right: -4.44444rem; } }

@media screen and (min-width: 78.125em) {
  .Services-object .Slider {
    margin-right: calc((100vw - 60.55556rem) / -2); } }

.Reference {
  filter: grayscale(100%);
  padding-top: 1.66667rem;
  padding-left: 1.66667rem;
  width: 33.33333%; }
  @media screen and (min-width: 48em) {
    .Reference {
      width: 14.28571%; } }
  .References {
    display: flex;
    flex-flow: row wrap;
    margin-top: -1.66667rem;
    margin-left: -1.66667rem; }

.Block--testimonials .Container-Testimonials::after {
  content: "";
  display: block;
  clear: both; }

.Block--testimonials .Testimonials-title {
  margin-bottom: 4.44444rem; }

.Block--testimonials .Testimonial {
  display: block;
  margin-bottom: 2.22222rem;
  text-decoration: none;
  color: inherit; }
  .Block--testimonials .Testimonial:active, .Block--testimonials .Testimonial:focus, .Block--testimonials .Testimonial:hover, .Block--testimonials .Testimonial.is-active, .Block--testimonials .Testimonial.is-hovered {
    text-decoration: none;
    color: inherit; }
    .Block--testimonials .Testimonial:active .Testimonial-image-overlay, .Block--testimonials .Testimonial:focus .Testimonial-image-overlay, .Block--testimonials .Testimonial:hover .Testimonial-image-overlay, .Block--testimonials .Testimonial.is-active .Testimonial-image-overlay, .Block--testimonials .Testimonial.is-hovered .Testimonial-image-overlay {
      opacity: 1; }
  .Block--testimonials .Testimonial .ClipPath {
    height: 0rem;
    width: 0rem;
    display: block; }
  .Block--testimonials .Testimonial--picture {
    position: relative;
    margin-bottom: 1.66667rem; }
  .Block--testimonials .Testimonial-image {
    clip-path: url("#clip-text-media"); }
    .Block--testimonials .Testimonial-image-overlay {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.2s linear;
      width: 100%;
      height: 100%;
      max-width: 178px; }
      .Block--testimonials .Testimonial-image-overlay path {
        fill: none;
        stroke: #e60080;
        stroke-miterlimit: 10;
        stroke-width: 20px; }
  .Block--testimonials .Testimonial--name {
    margin: 0;
    font-size: 1rem;
    font-weight: 600; }
  .Block--testimonials .Testimonial--function, .Block--testimonials .Testimonial--company {
    margin: 0;
    font-size: 0.88889rem;
    font-weight: 300; }
  .Block--testimonials .Testimonial--company {
    margin-bottom: 1.11111rem; }

.Block--clients {
  background-color: #ffffff;
  padding-top: 2.22222rem;
  padding-bottom: 2.22222rem; }
  .Block + .Block--clients {
    margin-top: 0; }
  @media screen and (min-width: 48em) {
    .Block--clients {
      padding-top: 6.66667rem;
      padding-bottom: 6.66667rem; } }

.Clients-title {
  max-width: 15rem;
  margin-bottom: 0; }
  .Site-Xperify .Clients-title {
    max-width: 44.44444rem; }

.Clients-object {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 2.22222rem;
  margin-top: -0.55556rem;
  margin-left: -0.55556rem; }

.Site-Xperify .Clients .Button {
  margin-top: 2.22222rem; }

@media screen and (max-width: 47.9375em) {
  .Clients-object {
    margin-bottom: 2.22222rem; } }

@media screen and (min-width: 48em) {
  .Site-Ocular .Clients {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto; }
    .Site-Ocular .Clients-title {
      grid-column: 1/2;
      grid-row: 1/2; }
    .Site-Ocular .Clients-object {
      grid-column: 1/3;
      grid-row: 2/3; }
    .Site-Ocular .Clients .Button {
      grid-column: 2/3;
      grid-row: 1/2;
      align-self: end; } }

.Client {
  transition-property: filter;
  transition-duration: 200ms;
  transition-timing-function: linear;
  padding-top: 0.55556rem;
  padding-left: 0.55556rem; }
  @media screen and (max-width: 47.9375em) {
    .Client {
      text-align: center;
      width: 33.33333%; } }
  .Client:not(:hover) {
    filter: grayscale(100%); }
  .Site-Xperify .Client {
    max-width: 12.22222rem; }

.Block--page {
  padding-top: 2.22222rem; }
  @media screen and (min-width: 48em) {
    .Block--page {
      padding-top: 6.66667rem; } }
  .Block--page + .Block {
    margin-top: -2.22222rem; }
    @media screen and (min-width: 48em) {
      .Block--page + .Block {
        margin-top: -6.66667rem; } }

.Page-title {
  max-width: 22.22222rem; }
  .Page-title::after {
    content: none; }

.Site-Ocular .Block--cta {
  background-color: #ffffff;
  padding-top: 2.22222rem;
  padding-bottom: 2.22222rem; }
  @media screen and (min-width: 48em) {
    .Site-Ocular .Block--cta {
      padding-top: 6.66667rem;
      padding-bottom: 6.66667rem; } }

.Site-Ocular .CallToAction {
  text-align: center; }
  .Site-Ocular .CallToAction-title {
    max-width: 18.05556rem;
    margin-bottom: 1.66667rem; }
    .Site-Ocular .CallToAction-title::after {
      margin-top: 1.66667rem; }
    .Site-Ocular .CallToAction-title, .Site-Ocular .CallToAction-title::after {
      margin-right: auto;
      margin-left: auto; }

.Site-Xperify .Block--cta {
  padding-top: 2.22222rem;
  padding-bottom: 2.22222rem; }

@media screen and (max-width: 48em) {
  .Site-Xperify .CallToAction {
    text-align: center; } }

@media screen and (min-width: 48em) {
  .Site-Xperify .CallToAction-body {
    display: flex;
    justify-content: space-between;
    align-items: center; } }

.Site-Xperify .CallToAction-title {
  font-weight: 500; }
  @media screen and (min-width: 48em) {
    .Site-Xperify .CallToAction-title {
      margin: 0;
      margin-right: 2.22222rem; } }
  @media screen and (max-width: 48em) {
    .Site-Xperify .CallToAction-title {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1.66667rem;
      max-width: 18.05556rem; } }

.Block--info {
  position: relative;
  z-index: 0;
  color: #101010;
  overflow: hidden; }
  .Site-Xperify .ContactPage .Block--info {
    margin-bottom: 4.44444rem; }
  .Block--info .ClipPath {
    height: 0rem;
    width: 0rem;
    display: block; }
  .Block--info:not(.is-black) + .Block {
    margin-top: 0; }
  @media screen and (min-width: 48em) {
    .Block--info {
      padding-top: 4.44444rem; }
      .CasesDetailPage .Block--info {
        padding-top: 2.77778rem; } }
  .Block--info.is-black {
    color: #ffffff;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20250.35%20251.31%22%3E%3Ctitle%3Eicon%3C%2Ftitle%3E%3Cpath%20d%3D%22M126.91%2C245.41A118.17%2C118.17%2C0%2C1%2C1%2C245.08%2C127.25%2C118.3%2C118.3%2C0%2C0%2C1%2C126.91%2C245.41Zm0-225.69A107.53%2C107.53%2C0%2C1%2C0%2C234.44%2C127.25%2C107.65%2C107.65%2C0%2C0%2C0%2C126.91%2C19.72Zm5.25%2C231.59a113.84%2C113.84%2C0%2C0%2C1-94.67-50.66A113.81%2C113.81%2C0%2C0%2C1%2C113.1%2C2.41%2C113.9%2C113.9%2C0%2C0%2C1%2C245.69%2C146.14%2C114%2C114%2C0%2C0%2C1%2C132.16%2C251.31ZM43.86%2C206a111.8%2C111.8%2C0%2C0%2C0%2C198.73-50.84%2C114%2C114%2C0%2C0%2C1-105.34%2C72.53A113.8%2C113.8%2C0%2C0%2C1%2C43.86%2C206ZM39%2C199.32a111.39%2C111.39%2C0%2C0%2C0%2C74.82%2C28.78%2C112.75%2C112.75%2C0%2C0%2C0%2C13.2-.77A114%2C114%2C0%2C0%2C1%2C22.64%2C114.79%2C111.81%2C111.81%2C0%2C0%2C0%2C39%2C199.32ZM25%2C105.55c-.2%2C2.74-.3%2C5.52-.3%2C8.31A112%2C112%2C0%2C0%2C0%2C136.49%2C225.69H137A111.8%2C111.8%2C0%2C0%2C0%2C207%2C54.41%2C111.78%2C111.78%2C0%2C0%2C0%2C25%2C105.55ZM212.9%2C60.15a113.82%2C113.82%2C0%2C0%2C1-65.85%2C165%2C112.12%2C112.12%2C0%2C0%2C0%2C96.64-79.43q.3-4.12.3-8.31A111.46%2C111.46%2C0%2C0%2C0%2C212.9%2C60.15ZM103.31%2C4.93A111.78%2C111.78%2C0%2C0%2C0%2C33.12%2C193.57%2C113.81%2C113.81%2C0%2C0%2C1%2C23%2C105.18%2C114.13%2C114.13%2C0%2C0%2C1%2C103.31%2C4.93ZM208.53%2C53.08A113.61%2C113.61%2C0%2C0%2C1%2C246%2C136.53%2C111.92%2C111.92%2C0%2C0%2C0%2C123.29%2C2.8%2C113.94%2C113.94%2C0%2C0%2C1%2C208.53%2C53.08ZM113.32%2C4.44a112.16%2C112.16%2C0%2C0%2C0-87.26%2C91.7%2C113.8%2C113.8%2C0%2C0%2C1%2C176.1-48.43%2C111.73%2C111.73%2C0%2C0%2C0-88.3-43.27Z%22%20fill%3D%22rgba(254%2C%20254%2C%20254%2C%200.1)%22%2F%3E%3C%2Fsvg%3E");
    background-size: 31.05556rem 31rem;
    background-repeat: no-repeat;
    background-position: left -10.38889rem bottom -7rem; }
    .CasesDetailPage .Block--info.is-black {
      padding-bottom: 0; }
    .Block--info.is-black::after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      content: '';
      height: 3.88889rem; }
    .Block--info.is-black[data-next="grey"]::after {
      background-color: #f7f7f7; }
    .Block--info.is-black[data-next="white"]::after {
      background-color: #ffffff; }
    .ContactPage .Block--info.is-black {
      background-color: #000000;
      padding-bottom: 2.77778rem; }
      @media screen and (min-width: 48em) {
        .ContactPage .Block--info.is-black {
          padding-bottom: 5.55556rem; } }
      .ContactPage .Block--info.is-black::after {
        content: none; }
      .ContactPage .Block--info.is-black .Info-body {
        padding-bottom: 0;
        margin-bottom: 0; }
        .ContactPage .Block--info.is-black .Info-body > :last-child {
          margin-bottom: auto; }
    .Block--info.is-black .Bg-title {
      position: absolute;
      right: -2.22222rem;
      bottom: 3.88889rem; }
  .Block--info:not(.is-black) {
    padding-bottom: 0; }
    .Block--info:not(.is-black)::after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      content: '';
      height: 3.88889rem; }
      @media screen and (min-width: 37.5em) {
        .Site-Xperify .Block--info:not(.is-black)::after {
          height: 2.33333rem;
          background-color: #ffffff; } }
    .Block--info:not(.is-black)[data-next="white"]::after {
      background-color: #ffffff; }
    .Block--info:not(.is-black)[data-next="black"]::after {
      background-color: #000000; }
    .Block--info:not(.is-black) .Bg-title {
      position: absolute;
      bottom: 3.88889rem;
      left: -2.22222rem;
      text-shadow: -1px -1px 0 rgba(16, 16, 16, 0.1), 1px -1px 0 rgba(16, 16, 16, 0.1), -1px 1px 0 rgba(16, 16, 16, 0.1), 1px 1px 0 rgba(16, 16, 16, 0.1); }
      @supports (-webkit-text-stroke: 1px black) {
        .Block--info:not(.is-black) .Bg-title {
          text-shadow: none;
          -webkit-text-stroke: 1px #101010; } }

@media screen and (min-width: 37.5em) {
  .Info {
    display: flex; }
    .is-black .Info {
      flex-direction: row-reverse; }
    .Block--info:not(.is-black) .Info {
      margin-left: -2.22222rem; } }

.Info-title {
  color: currentColor;
  max-width: 24.44444rem; }
  .is-black .Info-title {
    max-width: 18.33333rem; }
  @media screen and (min-width: 37.5em) {
    .Info-title {
      margin-top: auto; } }

.Info-buttons {
  display: flex;
  flex-wrap: wrap;
  margin-top: -0.55556rem;
  margin-left: -0.55556rem;
  padding-left: 1.83333rem; }
  .Site-Xperify .Info-buttons {
    padding-left: 0; }
  .Info-buttons .Button {
    margin-top: 0.55556rem;
    margin-left: 0.55556rem; }

@media screen and (min-width: 37.5em) {
  .Block--info:not(.is-black) .Info-body, .Block--info:not(.is-black) .Info-object {
    padding-left: 2.22222rem; } }

.Site-Xperify .Block--info:not(.is-black) .Info-object {
  clip-path: url("#clip-info-media"); }
  @media screen and (min-width: 37.5em) {
    .Site-Xperify .Block--info:not(.is-black) .Info-object {
      padding-left: 0; } }

@media screen and (min-width: 37.5em) {
  .Info-image-wrapper {
    padding-left: 2.22222rem; } }

.Info-body {
  margin-bottom: 2.22222rem; }
  .Site-Xperify .ContactPage .Info-body {
    width: 100%; }
  @media screen and (min-width: 64em) {
    .Site-Xperify .Block--info:not(.is-black) .Info-body {
      padding-left: 6.66667rem; } }
  @media screen and (min-width: 48em) {
    .Info-body {
      max-width: 32.77778rem; } }
  .is-black .Info-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: auto; }
    @media screen and (min-width: 48em) {
      .ContactPage .is-black .Info-body {
        max-width: 505px;
        width: 50%; } }
  .Block--info:not(.is-black) .Info-body {
    margin-right: auto; }
    @media screen and (min-width: 48em) {
      .Block--info:not(.is-black) .Info-body {
        margin-top: 4.44444rem; } }
  @media screen and (min-width: 37.5em) {
    .Info-body {
      margin-bottom: 3.88889rem;
      padding-bottom: 2.22222rem; } }
  .Info-body .Link {
    margin-top: auto; }

@media screen and (max-width: 47.9375em) {
  .Info-object {
    margin-top: 2.22222rem; }
    .Site-Xperify .Info-object {
      margin-bottom: 2.22222rem; } }

@media screen and (min-width: 37.5em) {
  .is-black .Info-object {
    margin-right: 2.22222rem; }
  .Block--info:not(.is-black) .Info-object {
    align-self: flex-end; } }

.Info .Info-Contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 0.83333rem;
  margin-bottom: 1.11111rem;
  width: 100%; }
  @media screen and (max-width: 37.4375em) {
    .Info .Info-Contact {
      margin-top: 1.11111rem; } }
  @media screen and (min-width: 37.5em) {
    .Info .Info-Contact-item {
      width: 33.33%; } }
  @media screen and (max-width: 37.4375em) {
    .Info .Info-Contact-item {
      width: 100%; } }
  .Info .Info-Contact-item a:not([class]) {
    color: currentColor;
    text-decoration: none; }
    .Info .Info-Contact-item a:not([class]):hover {
      text-decoration: underline; }
  .Info .Info-Contact-item:not(:last-child) {
    margin-bottom: 1.11111rem; }
  .Info .Info-Contact-item-label {
    font-weight: bold;
    opacity: 0.8;
    margin-bottom: 0.27778rem; }

.DL {
  font-size: 0.83333rem;
  margin-bottom: 1.11111rem;
  max-width: 23.88889rem;
  width: 100%; }
  .CasesDetailPage .DL {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: repeat(3, auto); }
    .CasesDetailPage .DL-item--year {
      grid-column: 2/3;
      grid-row: 1/2;
      margin-left: 1.11111rem; }
    .CasesDetailPage .DL-item--ta {
      grid-column: 1/2;
      grid-row: 1/2; }
    .CasesDetailPage .DL-item--challenge {
      grid-column: 1/3;
      grid-row: 2/3; }
    .CasesDetailPage .DL-item--result {
      grid-column: 1/3;
      grid-row: 3/4; }
  .ContactPage .DL {
    column-count: 2;
    width: 100%; }
  .DL-item a:not([class]) {
    color: currentColor;
    text-decoration: none; }
    .DL-item a:not([class]):hover {
      text-decoration: underline; }
  .DL-item:not(:last-child) {
    margin-bottom: 1.11111rem; }
  .DL-label {
    font-weight: bold;
    opacity: 0.8;
    margin-bottom: 0.27778rem; }

.Block--related {
  background-color: #101010;
  color: #ffffff;
  padding-top: 2.22222rem;
  padding-bottom: 2.22222rem; }
  .Block + .Block--related {
    margin-top: 0; }
  @media screen and (min-width: 48em) {
    .Block--related {
      padding-top: 6.66667rem;
      padding-bottom: 6.66667rem; } }

.Related {
  margin-top: 3.88889rem; }
  @media screen and (min-width: 48em) {
    .Related {
      display: flex; }
      .Related .Slider {
        flex-grow: 1; }
        .Related .Slider-buttons {
          align-self: center;
          margin-left: 3.88889rem; } }
  .Related-title {
    color: currentColor; }

@media screen and (min-width: 48em) {
  .Block--textMedia.is-3by4 {
    position: relative;
    z-index: 0; }
    .Block--textMedia.is-3by4:not(.is-last) {
      padding-bottom: 0; }
    .Block--textMedia.is-3by4::after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      background-color: #ffffff;
      content: '';
      height: 5rem; }
    .Block--textMedia.is-3by4 .TextMedia-body {
      margin-bottom: 5rem; }
    .Block--textMedia.is-3by4 + .Block {
      margin-top: 0; } }

.Block--textMedia .ClipPath {
  height: 0rem;
  width: 0rem;
  display: block; }

.TextMedia.is-dictionary .TextMedia-body {
  font-family: "DroidSerif", serif; }

.TextMedia.is-dictionary .TextMedia-title {
  letter-spacing: 0; }

@media screen and (max-width: 47.9375em) {
  .TextMedia-object {
    display: block;
    margin-top: 2.22222rem; } }

@media screen and (min-width: 48em) {
  .TextMedia {
    display: flex;
    align-items: center;
    margin-left: -3.33333rem; } }
  @media screen and (min-width: 48em) and (min-width: 64em) {
    .TextMedia {
      margin-left: -6.66667rem; } }

@media screen and (min-width: 48em) {
    .Block:nth-child(even) .TextMedia,
    .Block.media--alignLeft .TextMedia {
      flex-direction: row-reverse; }
    .Block.media--alignRight .TextMedia {
      flex-direction: row; }
    .TextMedia-object, .TextMedia-body {
      padding-left: 3.33333rem;
      flex-shrink: 0;
      max-width: 31.38889rem;
      width: 50%; } }
    @media screen and (min-width: 48em) and (min-width: 64em) {
      .TextMedia-object, .TextMedia-body {
        padding-left: 6.66667rem;
        max-width: 34.72222rem; } }

@media screen and (min-width: 48em) {
    .is-3by4 .TextMedia-body {
      margin-right: auto; }
    .Block:nth-child(even) .TextMedia-body {
      margin-right: auto; }
    .is-3by4 .TextMedia-object {
      max-width: 36.44444rem;
      align-self: flex-end; } }

.Site-Xperify .TextMedia-image {
  clip-path: url("#clip-text-media"); }

.Block--form {
  padding-top: 2.22222rem;
  padding-bottom: 2.22222rem; }
  .Block--form.is-black {
    background-color: #000000;
    color: #ffffff; }
  .Block + .Block--form {
    margin-top: 0; }
  .Block--form + .Block--cta {
    margin-top: 0; }
  @media screen and (min-width: 48em) {
    .Block--form {
      padding-top: 6.66667rem;
      padding-bottom: 6.66667rem; } }

@media screen and (min-width: 48em) {
  .Form {
    display: flex;
    justify-content: space-between;
    margin-left: -2.22222rem;
    max-width: 64.44444rem; }
    .Form-object, .Form-body {
      padding-left: 2.22222rem;
      width: 50%; } }

.Form-title {
  color: currentColor;
  max-width: 18.88889rem; }

.Form-object {
  max-width: 30rem; }
  @media screen and (max-width: 47.9375em) {
    .Form-object {
      margin-bottom: 2.22222rem; } }

.Form-body {
  max-width: 25.55556rem; }

@media screen and (min-width: 48em) {
  .Block--carousel .Container::after {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    background-color: #f7f7f7;
    content: '';
    left: calc(100% - 8.61111rem);
    width: 8.61111rem; }
    .Block--carousel.is-white .Container::after {
      background-color: #ffffff; } }
  @media screen and (min-width: 48em) and (min-width: 64em) and (max-width: 78.0625em) {
    .Block--carousel .Container::after {
      left: calc(100% - 10.83333rem);
      width: 10.83333rem; } }
  @media screen and (min-width: 48em) and (min-width: 78.125em) {
    .Block--carousel .Container::after {
      left: calc(100% - 10.83333rem);
      width: calc((100vw - 60.55556rem) / 2 + 6.38889rem); } }

.Carousel-title {
  margin-bottom: 2.22222rem; }

.Carousel .Slider {
  width: 100%; }
  .Carousel .Slider-buttons {
    position: relative;
    z-index: 2; }
  .Carousel .Slider-item {
    user-select: none;
    width: auto; }

@media screen and (min-width: 48em) {
  .Carousel {
    display: flex; }
    .Carousel .Slider {
      overflow: visible;
      flex-grow: 1; }
      .Carousel .Slider-buttons {
        align-self: center;
        padding-left: 3.88889rem; } }

.Carousel-object, .Carousel-image {
  display: block; }

.Carousel-image {
  max-height: 22.77778rem; }

.Block--list {
  position: relative;
  z-index: 0;
  color: #ffffff;
  padding-bottom: 0; }
  .Block--list::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-color: #ffffff;
    height: 2.77778rem;
    content: ''; }
  .Block--list[data-next="white"]::after {
    background-color: #ffffff; }
  .Block--list[data-next="grey"]::after {
    background-color: #f7f7f7; }

.List {
  font-size: 0.88889rem; }
  .List-title, .List-subtitle {
    color: currentColor; }
  .List-header {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 1.11111rem;
    user-select: none; }
  .List-title {
    margin-bottom: 2.22222rem; }
  .List-subtitle {
    font-size: 1.16667rem;
    margin-bottom: 0; }
    .List-item:not(.is-active) .List-subtitle {
      color: #b4b4b4; }
  .List-items {
    max-width: 22.22222rem; }
  .List-item + .List-item {
    margin-top: 1.66667rem; }
  .List-item:not(.is-active) .List-toggle,
  .List-item:not(.is-active) .List-icon {
    display: none; }
  .List-icon {
    display: block;
    margin-right: 2.22222rem; }
  @media screen and (max-width: 47.9375em) {
    .List-object {
      margin-top: 2.22222rem; } }
  @media screen and (min-width: 37.5em) {
    .List {
      display: flex;
      justify-content: space-between;
      margin-left: -2.22222rem; }
      .Block:nth-child(even) .List {
        flex-direction: row-reverse; }
      .List-object, .List-body {
        padding-left: 2.22222rem;
        flex-shrink: 0;
        max-width: 31.38889rem;
        width: 50%; }
      .List-object {
        align-self: flex-end; }
        .List--landscape .List-object {
          max-width: 41.11111rem; }
      .List-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-bottom: 2.77778rem; }
        .List-body .Button:not(.Button--outline) {
          margin-top: auto;
          transform: translateY(50%); } }

.Block--maps {
  overflow: hidden;
  position: relative;
  padding-bottom: 0; }
  .Block--maps .Bg-title {
    position: absolute;
    top: 25%;
    right: -2.22222rem; }
  @media screen and (min-width: 48em) {
    .Block--maps {
      padding-top: 0; }
      .Block + .Block--maps {
        margin-top: 0; } }

@media screen and (min-width: 48em) {
  .Maps {
    display: flex; }
    .Maps-body {
      flex-grow: 1;
      margin-right: 2.22222rem;
      padding-top: 6.66667rem;
      padding-bottom: 6.66667rem; }
    .Maps-object {
      position: relative;
      z-index: 0;
      max-width: 23.19444rem;
      width: 50%; } }

@media screen and (max-width: 47.9375em) {
  .Maps-object {
    margin-top: 2.22222rem;
    margin-right: -1.11111rem;
    margin-left: -1.11111rem; } }

@media screen and (max-width: 47.9375em) {
  .GoogleMaps-item {
    padding-bottom: 75%; } }

@media screen and (min-width: 48em) {
  .GoogleMaps {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1; } }
  @media screen and (min-width: 48em) and (min-width: 48em) and (max-width: 78.0625em) {
    .GoogleMaps {
      right: -4.44444rem; } }
  @media screen and (min-width: 48em) and (min-width: 78.125em) {
    .GoogleMaps {
      right: calc((100vw - 60.55556rem) / -2); } }

@media screen and (min-width: 48em) {
    .GoogleMaps-item {
      height: 100%;
      width: 100%; } }

.Intro {
  max-width: 40.27778rem; }

.Block--Quote {
  padding-top: 4.44444rem;
  padding-bottom: 4.44444rem; }
  @media screen and (max-width: 48em) {
    .Block--Quote {
      padding-top: 2.22222rem;
      padding-bottom: 2.22222rem; } }
  @media screen and (min-width: 64em) {
    .Block--Quote .Quote {
      padding-left: 2.22222rem;
      padding-right: 2.22222rem; } }
  .Block--Quote .Quote p:not(.Author) {
    font-size: 1.33333rem;
    font-weight: 500;
    margin-bottom: 0; }
    @media screen and (min-width: 48em) {
      .Block--Quote .Quote p:not(.Author) {
        font-size: 1.77778rem;
        line-height: 1.2; } }
  .Block--Quote .Quote strong {
    font-weight: 600; }
  .Block--Quote .Author {
    margin-top: 0.33333rem;
    margin-bottom: 0; }
  .Block--Quote .Icon {
    position: absolute; }
    .Block--Quote .Icon-top {
      top: -5.55556rem;
      left: 0; }
      @media screen and (max-width: 48em) {
        .Block--Quote .Icon-top {
          top: -3.33333rem; } }
      @media screen and (max-width: 80em) {
        .Block--Quote .Icon-top {
          left: 1.11111rem; } }
    .Block--Quote .Icon-bottom {
      bottom: -5.55556rem;
      right: 0; }
      @media screen and (max-width: 48em) {
        .Block--Quote .Icon-bottom {
          bottom: -3.33333rem; } }
      @media screen and (max-width: 80em) {
        .Block--Quote .Icon-bottom {
          right: 1.11111rem; } }

.Block--Columns {
  padding-bottom: -1.11111rem; }
  .Block--Columns .Columns-title {
    margin-bottom: 4.44444rem; }
  @media screen and (min-width: 48em) {
    .Block--Columns {
      padding-bottom: 2.22222rem; } }
  .Block--Columns .Grid-cell {
    margin-bottom: 3.33333rem; }
    @media screen and (min-width: 48em) {
      .Block--Columns .Grid-cell {
        margin-bottom: 4.44444rem; } }
  .Block--Columns .Field-image {
    margin-bottom: 1.66667rem; }

.Block--packageList {
  padding-bottom: 0; }
  @media screen and (min-width: 48em) {
    .Block--packageList {
      padding-bottom: 4.44444rem; } }
  .Block--packageList .PackageList-title {
    margin-bottom: 4.44444rem; }
  .Block--packageList .Grid-cell {
    margin-bottom: 2.22222rem; }

.Package {
  position: relative;
  height: 100%;
  padding: 3.33333rem 2.22222rem 2.22222rem 2.22222rem; }
  .Package--background {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    pointer-events: none; }
    .Package--background-fill, .Package--background-stroke {
      fill: #101010; }
    .Package--background-fill {
      opacity: 0;
      transition: opacity 0.2s linear; }
    .Package--background-top {
      flex-grow: 0;
      flex-shrink: 0;
      width: 100%;
      height: 3.33333rem; }
    .Package--background-middle {
      flex-grow: 1;
      flex-shrink: 1;
      width: 100%;
      height: 100%; }
    .Package--background-bottom {
      flex-grow: 0;
      flex-shrink: 0;
      width: 100%;
      height: 2.22222rem; }
  .Package:hover .Package--background-fill {
    opacity: 0.1; }
  .Package--label {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.33333rem 0.83333rem;
    background-color: #ff8000;
    font-size: 0.77778rem;
    font-weight: 600;
    color: #ffffff; }
  .Package--content {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%; }
  .Package--title {
    margin-bottom: 1.66667rem; }
  .Package ul li::after {
    left: -20px;
    background-image: url("../img/xperify/icons/bullet-package.svg");
    height: 0.66667rem;
    width: 0.66667rem; }
  .Package .ReadMore {
    margin: auto 0 0 auto; }
  .Package.highlight .Package--background-stroke,
  .Package.highlight .Package--background-fill {
    fill: #ff8000; }
  .Package.highlight .ReadMore-text::after {
    background-color: #ff8000; }

.Block--XperifyOverview .XperifyOverview-intro {
  margin-bottom: 3.33333rem; }
  @media screen and (min-width: 37.5em) {
    .Block--XperifyOverview .XperifyOverview-intro {
      margin-bottom: 4.44444rem; } }

.Block--XperifyOverview .XperifyOverview-title {
  margin-bottom: 1.66667rem; }

.Block--XperifyOverview .Nav--pagination {
  margin-top: 1.11111rem; }

.Block--faq .Side {
  padding-right: 3.33333rem; }
  @media screen and (min-width: 64em) {
    .Block--faq .Side {
      padding-right: 4.44444rem; } }
  @media screen and (max-width: 47.9375em) {
    .Block--faq .Side {
      margin-top: 0; } }
  @media screen and (max-width: 47.9375em) {
    .Block--faq .Side {
      margin-bottom: 2.22222rem;
      padding-right: 0; } }

.Faq-items {
  border-top: 2px solid #101010;
  border-bottom: 2px solid #101010;
  padding-top: 0.55556rem;
  padding-bottom: 0.55556rem; }

.Faq-item {
  padding-left: 1.11111rem;
  padding-right: 1.11111rem; }
  .Faq-item + .Faq-item {
    margin-top: 0.55556rem;
    border-top: 2px solid #101010;
    padding-top: 0.55556rem; }
  .Faq-item.is-active .Faq-question-icon {
    transform: scale(-1); }

.Faq-question {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1.11111rem;
  padding-top: 1.66667rem;
  padding-bottom: 1.66667rem;
  user-select: none; }

.Faq-answer {
  overflow: hidden;
  transition: max-height 0.2s linear;
  max-height: 0; }
  .Faq-answer > *:last-child {
    margin-bottom: 1.66667rem; }

.Video--overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  transition-property: opacity, visibility;
  transition-duration: 200ms;
  transition-timing-function: linear;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  padding-top: 9.83333rem;
  padding-bottom: 9.83333rem; }
  .Video--overlay .Container, .Video--overlay-object {
    height: 100%; }
  .Video--overlay-object {
    transition-property: opacity, visibility;
    transition-duration: 200ms;
    transition-timing-function: linear;
    opacity: 0;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center; }
    .Block.show-video-overlay .Video--overlay-object {
      opacity: 1;
      visibility: visible; }
  .Video--overlay video {
    transition-property: opacity, visibility;
    transition-duration: 200ms;
    transition-timing-function: linear;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    max-height: 100%; }
    .Block.show-video-overlay .Video--overlay video {
      opacity: 1;
      visibility: visible;
      transition-delay: 1s; }
    .Block:not(.show-video-overlay) .Video--overlay video {
      transition-duration: 0ms; }
  .Video--overlay .Close {
    height: 0.77778rem;
    width: 1.11111rem;
    position: absolute;
    top: -6.55556rem;
    right: 1.11111rem;
    cursor: pointer;
    transform: translateY(-50%); }
    @media screen and (min-width: 48em) {
      .Video--overlay .Close {
        right: 2.22222rem; } }
    @media screen and (min-width: 64em) {
      .Video--overlay .Close {
        right: 4.44444rem; } }
    .Video--overlay .Close-item {
      height: 0.11111rem;
      width: 100%;
      position: absolute;
      background-color: #101010;
      border-radius: 0px;
      display: block; }
      .Video--overlay .Close-item:nth-child(1) {
        top: 0.33333rem;
        transform: rotate(135deg); }
      .Video--overlay .Close-item:nth-child(2) {
        top: 0.33333rem;
        transform: rotate(-135deg); }
  .Video--overlay::before {
    transition-property: height;
    transition-duration: 500ms;
    transition-timing-function: ease-in-out;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
    background-color: #f7f6f8;
    content: '';
    height: 0; }
    .Block.show-video-overlay .Video--overlay::before {
      height: 100vh; }
  .Block.show-video-overlay .Video--overlay {
    opacity: 1;
    visibility: visible; }

html.show-video-overlay .Header {
  z-index: 1; }

.Block--videoCarousel .VideoCarousel-object {
  margin-top: 3.33333rem; }
  @media screen and (min-width: 48em) {
    .Block--videoCarousel .VideoCarousel-object {
      margin-top: 4.44444rem; } }
  .Block--videoCarousel .VideoCarousel-object .Slider--videoCarousel {
    padding-left: 4.44444rem;
    padding-right: 6.11111rem; }
    @media screen and (max-width: 63.9375em) {
      .Block--videoCarousel .VideoCarousel-object .Slider--videoCarousel {
        padding-left: 2.22222rem;
        padding-right: 3.88889rem; } }
    @media screen and (max-width: 47.9375em) {
      .Block--videoCarousel .VideoCarousel-object .Slider--videoCarousel {
        padding-left: 1.11111rem;
        padding-right: 1.66667rem; } }
    .Block--videoCarousel .VideoCarousel-object .Slider--videoCarousel-navigation {
      display: flex;
      justify-content: flex-end;
      padding-top: 2.77778rem; }
      .Block--videoCarousel .VideoCarousel-object .Slider--videoCarousel-navigation .Slider-button--prev {
        margin-right: 1.66667rem; }
        .Block--videoCarousel .VideoCarousel-object .Slider--videoCarousel-navigation .Slider-button--prev .Slider-button-icon {
          transform: scale(-1); }
      .Block--videoCarousel .VideoCarousel-object .Slider--videoCarousel-navigation .Slider-button-icon {
        display: block; }
      .Block--videoCarousel .VideoCarousel-object .Slider--videoCarousel-navigation .Slider-button--prev,
      .Block--videoCarousel .VideoCarousel-object .Slider--videoCarousel-navigation .Slider-button--next {
        transition-property: opacity;
        transition-duration: 200ms;
        transition-timing-function: linear; }
      .Block--videoCarousel .VideoCarousel-object .Slider--videoCarousel-navigation .is-disabled {
        pointer-events: none;
        opacity: 0.35; }
  .Block--videoCarousel .VideoCarousel-object .videoCarousel-item-object {
    position: relative;
    z-index: 1;
    margin-bottom: 1.11111rem;
    padding-bottom: 56.81818%; }
    .Block--videoCarousel .VideoCarousel-object .videoCarousel-item-object video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .Block--videoCarousel .VideoCarousel-object .videoCarousel-item-object::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      transition-property: opacity;
      transition-duration: 200ms;
      transition-timing-function: linear;
      opacity: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(16, 16, 16, 0.9);
      background-blend-mode: darken; }
    .Block--videoCarousel .VideoCarousel-object .videoCarousel-item-object .Play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      transition-property: fill;
      transition-duration: 200ms;
      transition-timing-function: linear;
      transform: translate(-50%, -50%);
      fill: #ffffff; }
  .Block--videoCarousel .VideoCarousel-object .videoCarousel-item:hover .videoCarousel-item-object::before {
    opacity: 1; }
  .Block--videoCarousel .VideoCarousel-object .videoCarousel-item:hover .videoCarousel-item-object .Play-button {
    fill: #e60080; }

.Field:not(.Field--noGutter) + .Field {
  margin-top: 2.22222rem; }
  @media screen and (min-width: 48em) {
    .Field:not(.Field--noGutter) + .Field {
      margin-top: 6.66667rem; } }

.Field-title {
  max-width: 31.66667rem; }

.Field-body {
  max-width: 40.27778rem; }
  .Field-body :last-child {
    margin-bottom: 0; }

.Field-object {
  display: block; }
  .Field--images .Field-object:not(:only-child) {
    padding-top: 1.66667rem;
    padding-left: 1.66667rem; }
    .Field--images .Field-object:not(:only-child).object-fit-polyfill {
      padding-top: 0;
      padding-left: 0;
      margin-top: 1.66667rem;
      margin-left: 1.66667rem; }
  .Field--images .Field-object.object-fit-polyfill {
    height: 30.77778rem; }

.Field-objects {
  margin-top: -1.66667rem;
  margin-left: -1.66667rem; }
  @media screen and (min-width: 48em) {
    .Field-objects {
      display: flex; } }
  .Field-objects.is-full .Field-image {
    height: 100%;
    width: 100%;
    max-height: 30.77778rem;
    object-fit: cover; }
  @media screen and (min-width: 48em) {
    .Field-objects.is-2by1 .Field-object:first-child {
      width: 70%; }
    .Field-objects.is-2by1 .Field-object:last-child {
      width: 30%; }
    .Field-objects.is-1by2 .Field-object:first-child {
      width: 30%; }
    .Field-objects.is-1by2 .Field-object:last-child {
      width: 70%; }
    .Field-objects.is-top {
      align-items: flex-start; }
    .Field-objects.is-middle {
      align-items: center; }
    .Field-objects.is-bottom {
      align-items: flex-end; } }

.Field-author {
  color: #000000;
  font-size: 83.33333%;
  font-style: normal; }

.Field--quote {
  position: relative;
  z-index: 0; }
  .Field--quote .Field-object {
    position: absolute;
    top: 0;
    z-index: -1;
    transform: translateX(-100%) translateX(1.11111rem) translateY(-1.11111rem);
    opacity: 0.25; }

.Field--text + .Field--text,
.Field--title + .Field--text,
.Field--text + .Field--link {
  margin-top: 2.22222rem; }

.Field--text .Field-body.has-columns {
  column-count: 2;
  column-width: 19.44444rem;
  column-gap: 5rem;
  max-width: none; }
  .Field--text .Field-body.has-columns .Field-title {
    column-span: all;
    max-width: 50rem;
    margin-bottom: 2.22222rem; }
  .Field--text .Field-body.has-columns .Field-author {
    column-span: all;
    display: block;
    margin-top: 1.11111rem; }

/* Footer */
.Footer {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20250.35%20251.31%22%3E%3Ctitle%3Eicon%3C%2Ftitle%3E%3Cpath%20d%3D%22M126.91%2C245.41A118.17%2C118.17%2C0%2C1%2C1%2C245.08%2C127.25%2C118.3%2C118.3%2C0%2C0%2C1%2C126.91%2C245.41Zm0-225.69A107.53%2C107.53%2C0%2C1%2C0%2C234.44%2C127.25%2C107.65%2C107.65%2C0%2C0%2C0%2C126.91%2C19.72Zm5.25%2C231.59a113.84%2C113.84%2C0%2C0%2C1-94.67-50.66A113.81%2C113.81%2C0%2C0%2C1%2C113.1%2C2.41%2C113.9%2C113.9%2C0%2C0%2C1%2C245.69%2C146.14%2C114%2C114%2C0%2C0%2C1%2C132.16%2C251.31ZM43.86%2C206a111.8%2C111.8%2C0%2C0%2C0%2C198.73-50.84%2C114%2C114%2C0%2C0%2C1-105.34%2C72.53A113.8%2C113.8%2C0%2C0%2C1%2C43.86%2C206ZM39%2C199.32a111.39%2C111.39%2C0%2C0%2C0%2C74.82%2C28.78%2C112.75%2C112.75%2C0%2C0%2C0%2C13.2-.77A114%2C114%2C0%2C0%2C1%2C22.64%2C114.79%2C111.81%2C111.81%2C0%2C0%2C0%2C39%2C199.32ZM25%2C105.55c-.2%2C2.74-.3%2C5.52-.3%2C8.31A112%2C112%2C0%2C0%2C0%2C136.49%2C225.69H137A111.8%2C111.8%2C0%2C0%2C0%2C207%2C54.41%2C111.78%2C111.78%2C0%2C0%2C0%2C25%2C105.55ZM212.9%2C60.15a113.82%2C113.82%2C0%2C0%2C1-65.85%2C165%2C112.12%2C112.12%2C0%2C0%2C0%2C96.64-79.43q.3-4.12.3-8.31A111.46%2C111.46%2C0%2C0%2C0%2C212.9%2C60.15ZM103.31%2C4.93A111.78%2C111.78%2C0%2C0%2C0%2C33.12%2C193.57%2C113.81%2C113.81%2C0%2C0%2C1%2C23%2C105.18%2C114.13%2C114.13%2C0%2C0%2C1%2C103.31%2C4.93ZM208.53%2C53.08A113.61%2C113.61%2C0%2C0%2C1%2C246%2C136.53%2C111.92%2C111.92%2C0%2C0%2C0%2C123.29%2C2.8%2C113.94%2C113.94%2C0%2C0%2C1%2C208.53%2C53.08ZM113.32%2C4.44a112.16%2C112.16%2C0%2C0%2C0-87.26%2C91.7%2C113.8%2C113.8%2C0%2C0%2C1%2C176.1-48.43%2C111.73%2C111.73%2C0%2C0%2C0-88.3-43.27Z%22%20fill%3D%22rgba(254%2C%20254%2C%20254%2C%200.1)%22%2F%3E%3C%2Fsvg%3E");
  background-color: #000000;
  background-size: 31.05556rem 31rem;
  background-repeat: no-repeat;
  background-position: right -15.5rem bottom -13.33333rem;
  color: #ffffff;
  padding-top: 2.22222rem;
  padding-bottom: 2.22222rem; }
  .Footer > .Container {
    height: auto; }
  .Site-Xperify .Footer {
    border-top: 2px solid #e60080;
    padding-top: 0;
    padding-bottom: 0;
    background-image: none;
    background-color: #ffffff;
    color: #101010; }
    .Site-Xperify .Footer .Footer-top {
      padding-top: 2.22222rem;
      padding-bottom: 2.22222rem; }
  .Footer a:not([class]) {
    text-decoration: none; }
    .Footer a:not([class]), .Footer a:not([class]):active, .Footer a:not([class]):focus, .Footer a:not([class]):hover {
      color: currentColor; }
    .Footer a:not([class]):hover {
      text-decoration: underline; }
  @media screen and (min-width: 48em) {
    .Footer {
      padding-top: 6.66667rem;
      padding-bottom: 6.66667rem; }
      .Site-Xperify .Footer .Footer-top {
        padding-top: 6.66667rem;
        padding-bottom: 6.66667rem; } }
  .Footer-title {
    font-size: 1.27778rem;
    color: #b4b4b4;
    font-weight: 500;
    margin-bottom: 2.22222rem;
    line-height: 1.30435; }
    .Site-Xperify .Footer-title {
      margin-bottom: 1.55556rem;
      font-size: 1rem;
      line-height: 1.5;
      font-weight: 600;
      color: #101010; }
  .Footer-item {
    padding-top: 1.11111rem;
    padding-left: 1.11111rem; }
    @media screen and (min-width: 48em) {
      .Footer-item:first-child {
        margin-right: 5.55556rem; } }
    @media screen and (max-width: 47.9375em) {
      .Footer-item:first-child {
        width: 100%; } }
  .Footer-nav {
    display: flex; }
    .Footer-nav .Nav + .Nav {
      margin-left: 2.22222rem; }
    .Site-Xperify .Footer-nav .Nav-list {
      padding-left: 1.11111rem; }
    .Site-Xperify .Footer-nav .Nav--priority {
      padding-left: 0; }
      .Site-Xperify .Footer-nav .Nav--priority .Nav-list {
        margin-left: 0; }
      .Site-Xperify .Footer-nav .Nav--priority .Nav-item {
        padding-left: 0; }
  .Footer .Flanders-State-of-the-Art {
    margin-top: 1.66667rem;
    margin-bottom: 1.66667rem; }
    .Footer .Flanders-State-of-the-Art-object {
      width: 7.77778rem; }
  .Footer-product-by {
    font-size: 0.88889rem; }
    .Footer-product-by .Site-Xperify b,
    .Footer-product-by .Site-Xperify strong {
      font-weight: 600; }
  .Footer-top {
    display: flex;
    flex-wrap: wrap;
    margin-top: -1.11111rem;
    margin-left: -1.11111rem; }
    @media screen and (min-width: 48em) {
      .Footer-top {
        justify-content: space-between; } }
  .Footer-bottom {
    font-size: 0.66667rem;
    margin-top: 2.22222rem; }
    @media screen and (min-width: 48em) {
      .Footer-bottom {
        margin-top: 5.27778rem; } }
    .Footer-bottom-inner {
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .Site-Xperify .Footer-bottom {
      margin-top: 0;
      padding-top: 1rem;
      padding-bottom: 1rem;
      background-color: #f7f6f8; }
      .Site-Xperify .Footer-bottom .DigitalPulse-object {
        background-color: #f7f6f8; }

/* DigitalPulse */
.DigitalPulse {
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  margin-left: auto;
  height: 1.5em; }
  .DigitalPulse, .DigitalPulse:active, .DigitalPulse:focus, .DigitalPulse:hover {
    color: currentColor; }
  .DigitalPulse-icon, .DigitalPulse-body {
    display: block;
    fill: currentColor; }
  .DigitalPulse-label {
    margin-right: 0.55556rem;
    flex-shrink: 0; }
  .DigitalPulse-object {
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .DigitalPulse-icon {
    flex-shrink: 0;
    height: 1.85743em;
    transform: translateY(5%);
    width: 1.5em; }
  @media screen and (min-width: 64em) {
    .DigitalPulse-object {
      transition-property: transform, right;
      transition-duration: 300ms;
      transition-timing-function: ease-out;
      min-width: 5.56167em;
      position: relative;
      z-index: 1;
      position: absolute;
      top: 50%;
      right: 0;
      transform: scale(1.01) translateY(-50%); }
    .DigitalPulse:hover .DigitalPulse-object {
      color: currentColor;
      right: 100%; }
    .DigitalPulse-body {
      transition-property: width;
      transition-duration: 300ms;
      transition-timing-function: ease-out;
      margin-left: 0.75em;
      transform: translateY(12.5%);
      display: flex;
      justify-content: flex-end;
      height: 0.94828em;
      width: 5.56167em; } }

/* Print */
@media print {
  *,
  *::before,
  *::after,
  *::first-letter,
  p::first-line,
  div::first-line,
  blockquote::first-line,
  li::first-line {
    background: transparent !important;
    box-shadow: none !important;
    color: #000000 !important;
    letter-spacing: 0;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]::after {
    content: " (" attr(href) ")"; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */
  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: ""; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #9e9e9e;
    page-break-inside: avoid; }
  /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  /**
     * General
     */
  html {
    font-family: sans-serif;
    font-size: 12pt; }
  .Container {
    max-width: none; }
  h1,
  h2,
  h3 {
    margin-bottom: 12pt;
    text-transform: none; }
  h1 {
    font-size: 24pt; }
  h2 {
    font-size: 18pt; }
  h3 {
    font-size: 14pt; } }
