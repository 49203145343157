/* Footer */
.Footer {
    @include background-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250.35 251.31"><title>icon</title><path d="M126.91,245.41A118.17,118.17,0,1,1,245.08,127.25,118.3,118.3,0,0,1,126.91,245.41Zm0-225.69A107.53,107.53,0,1,0,234.44,127.25,107.65,107.65,0,0,0,126.91,19.72Zm5.25,231.59a113.84,113.84,0,0,1-94.67-50.66A113.81,113.81,0,0,1,113.1,2.41,113.9,113.9,0,0,1,245.69,146.14,114,114,0,0,1,132.16,251.31ZM43.86,206a111.8,111.8,0,0,0,198.73-50.84,114,114,0,0,1-105.34,72.53A113.8,113.8,0,0,1,43.86,206ZM39,199.32a111.39,111.39,0,0,0,74.82,28.78,112.75,112.75,0,0,0,13.2-.77A114,114,0,0,1,22.64,114.79,111.81,111.81,0,0,0,39,199.32ZM25,105.55c-.2,2.74-.3,5.52-.3,8.31A112,112,0,0,0,136.49,225.69H137A111.8,111.8,0,0,0,207,54.41,111.78,111.78,0,0,0,25,105.55ZM212.9,60.15a113.82,113.82,0,0,1-65.85,165,112.12,112.12,0,0,0,96.64-79.43q.3-4.12.3-8.31A111.46,111.46,0,0,0,212.9,60.15ZM103.31,4.93A111.78,111.78,0,0,0,33.12,193.57,113.81,113.81,0,0,1,23,105.18,114.13,114.13,0,0,1,103.31,4.93ZM208.53,53.08A113.61,113.61,0,0,1,246,136.53,111.92,111.92,0,0,0,123.29,2.8,113.94,113.94,0,0,1,208.53,53.08ZM113.32,4.44a112.16,112.16,0,0,0-87.26,91.7,113.8,113.8,0,0,1,176.1-48.43,111.73,111.73,0,0,0-88.3-43.27Z" fill="#{rgba(#fefefe, .1)}"/></svg>');
    background-color: $footer-bg-color;
    background-size: u(559, 558);
    background-repeat: no-repeat;
    background-position: right u(-279) bottom u(-240);
    color: $white;
    padding-top: u($spacing-unit--lg);
    padding-bottom: u($spacing-unit--lg);
    // margin-top: u($spacing-unit--lg);

    // @include mq($from: $viewport--md) {
    //     margin-top: u(120)
    // }

    & > .Container {
        height: auto;
    }

    .Site-Xperify & {
        border-top: 2px solid $cc-xperify-pink;
        padding-top: 0;
        padding-bottom: 0;
        background-image: none;
        background-color: $white;
        color: $cc-black;

        .Footer-top {
            padding-top: u($spacing-unit--lg);
            padding-bottom: u($spacing-unit--lg);
        }
    }

    a:not([class]) {
        text-decoration: none;

        &,
        &:active,
        &:focus,
        &:hover {
            color: currentColor;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    @include mq($from: $viewport--md) {
        padding-top: u(120);
        padding-bottom: u(120);

        .Site-Xperify & {    
            .Footer-top {
                padding-top: u(120);
                padding-bottom: u(120);
            }
        }
    }

    &-title {
        @include font-size(23, false);
        color: $cc-grey;
        font-weight: 500;
        margin-bottom: u($spacing-unit--lg);
        line-height: (30 / 23);

        .Site-Xperify & {
            margin-bottom: u(28);
            @include font-size($heading-size-5--Xperify);
            line-height: 1.5;
            font-weight: 600;
            color: $cc-black;
        }
    }

    &-item {
        padding-top: u($spacing-unit);
        padding-left: u($spacing-unit);

        @include mq($from: $viewport--md) {

            &:first-child {
                margin-right: u(100);
            }
        }

        @include mq($until: $viewport--md - 1) {

            &:first-child {
                width: 100%;
            }
        }
    }

    &-nav {
        display: flex;

        .Nav + .Nav {
            margin-left: u($spacing-unit--lg);
        }

        .Site-Xperify & {
            .Nav-list {
                padding-left: u($list-gutter--l);
            }

            .Nav--priority {
                padding-left: 0;

                .Nav-list {
                    margin-left: 0;
                }

                .Nav-item {
                    padding-left: 0;
                }
            }
        }
    }

    .Flanders-State-of-the-Art {
        margin-top: u($line-height);
        margin-bottom: u($line-height);

        &-object {
            width: u(140);
        }
    }

    &-product-by {
        @include font-size($font-size - 2);

        .Site-Xperify {
            b,
            strong {
                font-weight: 600;
            }
        }
    }

    // Top
    &-top {
        display: flex;
        flex-wrap: wrap;
        margin-top: u($spacing-unit * -1);
        margin-left: u($spacing-unit * -1);

        @include mq($from: $viewport--md) {
            justify-content: space-between;
        }
    }

    // Bottom
    &-bottom {
        @include font-size(12, false);
        margin-top: u($spacing-unit--lg);
        
        @include mq($from: $viewport--md) {
            margin-top: u(95);
        }

        &-inner {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .Site-Xperify & {
            margin-top: 0;
            padding-top: u(18);
            padding-bottom: u(18);
            background-color: $cc-xperify-grey--light;

            .DigitalPulse-object {
                background-color: $cc-xperify-grey--light;
            }
        }
    }
}
