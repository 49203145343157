/* Colors: Custom */

// Grey
$cc-grey:                           #b4b4b4 !default; // BrownGreyTwo
$cc-grey--dark:                     #959595 !default; // BrownGrey
$cc-grey--darker:                   #555c62 !default; // GunMetal
$cc-grey--light:                    #f7f7f7 !default;
$cc-grey--hover:                    scale-color($cc-grey, $lightness: -30%);

// Blue
$cc-blue:                           #0026ff !default; // RichBlue

// Black
$cc-black:                          #101010 !default; // BlackTwo

// Xperify
$cc-xperify-orange:                 #ff8000 !default;
$cc-xperify-pink:                   #e60080 !default;
$cc-xperify-grey:                   #edf0f4 !default;
$cc-xperify-grey--light:            #f7f6f8 !default;