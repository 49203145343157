/* Objects: Toggle */

// Namespace
$toggle-namespace:                   $namespace !default;

// Dimensions
$toggle-width:                       20 !default;
$toggle-height:                      14 !default;

// Border
$toggle-border-width:                2 !default;
$toggle-border-radius:               0 !default;
