.Block--banner-xperify {
    color: $white;
    padding-top: 0;
    padding-bottom: u($spacing-unit--lg);
    overflow: hidden;
    height: calc(90vh - #{u($structure-header-height)});

    @include mq($from: $viewport--md) {
    }

    > .Container {
        height: 100%;
    }

    .Banner {
        position: relative;
        height: 100%;
        padding-top: u($spacing-unit);
        padding-left: u($spacing-unit);

        @include mq($from: $viewport--md) {
            padding-top: u(60);
            padding-left: u(60);
        }

        @include mq($from: $viewport--lg) {
            padding-top: u(90);
            padding-left: u(90);
        }

        &-object {
            @include position(absolute, $top: 0, $left: 0);
            width: 100%;
            height: 100%;

            .Slider {
                &,
                &-object {
                    height: calc(100% - #{u(30)});
                }

                &-object {
                    &--video {
                        overflow: hidden;

                        video {
                            @include position(absolute, $top: 0, $left: 0);
                            width: 100%;
                            height: calc(100% - #{u(30)});
                            object-fit: cover;
                        }
                    }
                }

                &-object,
                &-image {
                    display: block;
                }

                &-image {
                    @include dimensions(100%);
                    object-fit: cover;
                }

                .Video-overlay {
                    display: none;
                }

                .PlayButton {
                    @include position(absolute, $bottom: 0, $right: u(30));
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: u(120);
                    height: u(120);
                    background-repeat: no-repeat;
                    background-size: cover;
                    cursor: pointer;
                    @include background-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M0 16V3.84L5.12 0H16v12.16L10.88 16Z" fill="#ff8000"/></svg>');
                    @include font-size(21);
                    font-weight: 700;
                    text-align: center;
                }
            }
        }

        &-titles,
        &-body,
        .Button {
            position: relative;
            z-index: 2;
        }

        &-titles,
        &-body {
            max-width: u(800);
        }

        &-title,
        &-subtitle {
            color: $white;
        }

        .Button {
            margin-top: u($spacing-unit--lg);
        }

        .Slider-pagination {
            @include position(absolute, $top: 50%, $right: u($spacing-unit--lg * -1));
            justify-self: end;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transform: translateX(100%);

            @include mq($until: $viewport--lg - 1) {
                right: u($spacing-unit--sm * -1);
            }

            @include mq($until: $viewport--md - 1) {
                right: u(-2);
            }

            &-item {
                @include dimensions(16);
                border-radius: 0;
                background-color: transparent;
                @include background-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M0 16V3.84L5.12 0H16v12.16L10.88 16Z" fill="#ff8000"/></svg>');

                + .Slider-pagination-item {
                    margin-top: u(8);
                }

                &:not(.is-active) {
                    opacity: 0.25;
                }
            }
        }
    }
}
