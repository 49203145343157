.Nav--socialMedia {
    padding-bottom: 10px;

    .Navigation & {
        @include grid-area(1, 3, 3, 4);
        @include transition(#{opacity, transform}, 500ms, #{linear, cubic-bezier(.19,1,.22,1)});
        transition-delay: 600ms;

        html:not(.show-nav) & {
            opacity: 0;
            transform: translateY(#{u($spacing-unit * -1)});
        }

        @include mq($from: $viewport--md) {
            @include grid-area(2, 4, 2, 3);
            align-self: end;
            // justify-self: end;
        }
    }

    // List
    .Nav-list {
        margin-left: u($spacing-unit * -1);
    }

    // Item
    .Nav-item {
        padding-left: u($spacing-unit);
    }

    // Item
    .Nav-link {

        &,
        &:active,
        &:focus,
        &:hover {
            color: currentColor;
        }
    }

    // Icon
    .Nav-icon {
        fill: currentColor;
        display: block;
    }
}
