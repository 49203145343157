.Block--cases {
    overflow: hidden;
    position: relative;

    .Bg-title {
        @include position(absolute, $top: 25%, $right: u($spacing-unit--lg * -1));
    }

    .SplashPage & {

        &:nth-child(even) {
            background-color: $white;
            padding-top: u($spacing-unit--lg);
            padding-bottom: u($spacing-unit--lg);

            @include mq($from: $viewport--md) {
                padding-top: u(120);
                padding-bottom: u(120);
            }
        }
    }

    .HomePage & {
        background-color: $white;
        padding-top: u($spacing-unit--lg);
        padding-bottom: u($spacing-unit--lg);

        & + .Block {
            margin-top: 0;
        }

        @include mq($from: $viewport--md) {
            padding-top: u(120);
            padding-bottom: u(120);
        }
    }
}

.Cases {

    &-header {
        max-width: u(650);
        margin-bottom: u($spacing-unit--lg);
        margin-right: auto;
        margin-left: auto;
        text-align: center;

        @include mq($from: $viewport--md) {
            margin-bottom: u(80);
        }
    }

    &-title {

        &.has-borderBottom {

            &::after {
                margin-right: auto;
                margin-left: auto;
            }
        }
    }
}
