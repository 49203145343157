.Boxes {
    display: flex;
    flex-wrap: wrap;

    + .Boxes--small {
        margin-top: u($spacing-unit--sm);
    }

    &:not(.Boxes--small) {
        justify-content: space-between;
        margin-top: u($spacing-unit--lg * -1);

        > .Box {
            margin-top: u($spacing-unit--lg);

            @include mq($from: $viewport--sm) {
                width: 50%;
            }
        }

        @include mq($from: $viewport--lg) {
            margin-left: u(-160);

            > .Box {
                padding-left: u(160);
            }

            &.is-unloaded > .Box {
                opacity: 0;
            }

            &.has-masonry > .Box {
                opacity: 1;
            }
        }

        @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
            margin-left: u($spacing-unit--lg * -1);

            > .Box {
                padding-left: u($spacing-unit--lg);
            }
        }
    }

    &--small {
        margin-top: u($spacing-unit * -1);
        margin-left: u($spacing-unit--lg * -1);

        > .Box {
            padding-top: u($spacing-unit);
            padding-left: u($spacing-unit--lg);

            @include mq($from: $viewport--md) {
                width: (100% / 3);
            }

            @include mq($from: $viewport--sm, $until: $viewport--md - 1) {
                width: (100% / 2);
            }
        }
    }

    + .Boxes-more {
        text-align: center;
        margin-top: u(70);
    }
}

.Box {
    width: 100%;

    .Button {
        @include transition(#{background-color, color});
    }

    &-link {
        display: block;

        .Box:not(.Box--button) & {
            @include grid(100%, 1fr u($spacing-unit--lg) auto);

            @include mq($from: $viewport--lg) {
                @include grid(100%, 1fr u(70) auto);
            }
        }

        &,
        &:active,
        &:focus,
        &:hover {
            color: currentColor;
            text-decoration: none;
        }
    }

    &:hover {

        .Button--outline.Button--primary {
            background-color: $cc-black;
            border-color: $cc-black;
            color: $white;
        }

        .ReadMore-text {
            &::after {
                opacity: 1;
            }
        }
    }

    &-image {
        @include transition(#{opacity, transform});
    }

    &-object {
        @include grid-area(1, 2, 1, 3);
    }

    &Item-object {
        @include position(relative);
        background-color: $cc-grey--light;
        background-image: url('#{$path-img}logo.svg');
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: 50%;
        display: block;
        padding-bottom: 100%;
        overflow: hidden;
        height: 0;

        .Site-Xperify & {
            background-image: none;
            background-color: $white;

            .Icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &::after {
            @include background-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250.35 251.31"><title>icon</title><path d="M126.91,245.41A118.17,118.17,0,1,1,245.08,127.25,118.3,118.3,0,0,1,126.91,245.41Zm0-225.69A107.53,107.53,0,1,0,234.44,127.25,107.65,107.65,0,0,0,126.91,19.72Zm5.25,231.59a113.84,113.84,0,0,1-94.67-50.66A113.81,113.81,0,0,1,113.1,2.41,113.9,113.9,0,0,1,245.69,146.14,114,114,0,0,1,132.16,251.31ZM43.86,206a111.8,111.8,0,0,0,198.73-50.84,114,114,0,0,1-105.34,72.53A113.8,113.8,0,0,1,43.86,206ZM39,199.32a111.39,111.39,0,0,0,74.82,28.78,112.75,112.75,0,0,0,13.2-.77A114,114,0,0,1,22.64,114.79,111.81,111.81,0,0,0,39,199.32ZM25,105.55c-.2,2.74-.3,5.52-.3,8.31A112,112,0,0,0,136.49,225.69H137A111.8,111.8,0,0,0,207,54.41,111.78,111.78,0,0,0,25,105.55ZM212.9,60.15a113.82,113.82,0,0,1-65.85,165,112.12,112.12,0,0,0,96.64-79.43q.3-4.12.3-8.31A111.46,111.46,0,0,0,212.9,60.15ZM103.31,4.93A111.78,111.78,0,0,0,33.12,193.57,113.81,113.81,0,0,1,23,105.18,114.13,114.13,0,0,1,103.31,4.93ZM208.53,53.08A113.61,113.61,0,0,1,246,136.53,111.92,111.92,0,0,0,123.29,2.8,113.94,113.94,0,0,1,208.53,53.08ZM113.32,4.44a112.16,112.16,0,0,0-87.26,91.7,113.8,113.8,0,0,1,176.1-48.43,111.73,111.73,0,0,0-88.3-43.27Z" fill="#{rgba(#fefefe, .1)}"/></svg>');
            @include font-size(20, false);
            @include position(absolute, 0, 0, 0, 0, 1);
            @include transition(#{opacity, visibility});
            background-color: rgba($black, .8);
            background-repeat: no-repeat;
            background-position: bottom -5vh left -7.5vh;
            color: $white;
            content: attr(data-more);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-weight: 700;

            .Box-link:not(:hover) & {
                opacity: 0;
                visibility: hidden;
            }

            .Site-Xperify & {
                display: none;
            }
        }

        &.object-fit-polyfill {
            padding-bottom: 0;
            height: u(408);
        }
    }

    &-image {
        @include position(absolute, 0, 0, 0, 0);
        @include dimensions(100%);
    }

    &-body {
        @include position(relative, $z: 1);
        @include grid-area(1, 2, 2, 4);
        background-color: $cc-grey--light;
        margin-right: u($spacing-unit);
        padding-top: u($spacing-unit);
        padding-right: u($spacing-unit);

        .Site-Xperify & {
            background-color: transparent;
            margin-right: 0;
            padding-right: 0;

            p {
                background-color: $cc-grey--light;
            }
        }

        .HomePage &,
        .SplashPage .Block:not(.is-grey) & {
            background-color: $white;
        }

        .Block--related & {
            background-color: $cc-black;
            color: $white;
        }

        :last-child {
            margin-bottom: 0;
        }

        @include mq($until: $viewport--lg - 1) {
            padding-top: u($spacing-unit);
        }

        @include mq($from: $viewport--lg) {
            margin-right: u(60);
            padding-top: u($spacing-unit--lg);
            padding-right: u($spacing-unit--lg);
        }
    }

    &-lead {
        @include font-size(16, false);
        color: $cc-grey;
        font-weight: 500;
        // max-width: u(240);
        margin-bottom: u($spacing-unit--sm);

        .Site-Xperify & {
            color: $cc-black;
            font-weight: 400;
        }
    }

    &-title {
        color: currentColor;
        line-height: (25 / 18);

        .Site-Xperify & {
            margin-bottom: 0;
            margin-right: u($spacing-unit);
            padding-top: u($spacing-unit);
            padding-bottom: u(15);
            padding-right: u($spacing-unit);
            background-color: $cc-grey--light;

            @include mq($from: $viewport--lg) {
                margin-right: u(120);
                padding-right: u($spacing-unit--lg);
            }
        }
    }
}
