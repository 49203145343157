/* Mixin: Arrow */
@mixin arrow($dir, $base-width, $length, $color) {
    border: u($base-width / 2) solid transparent;
    height: 0;
    width: 0;

    @if ($dir == top) {
        border-top: none;
        border-bottom-width: u($length);
        border-bottom-color: $color;
    }

    @else if ($dir == right) {
        border-right: none;
        border-left-width: u($length);
        border-left-color: $color;
    }

    @else if ($dir == bottom) {
        border-top-width: u($length);
        border-bottom: none;
        border-top-color: $color;
    }

    @else if ($dir == left) {
        border-right-width: u($length);
        border-left: none;
        border-right-color: $color;
    }
}
