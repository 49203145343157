.Block--packageList {
    padding-bottom: 0;

    @include mq($from: $viewport--md) {
        padding-bottom: u(120 - $spacing-unit--lg);
    }

    .PackageList-title {
        margin-bottom: u(80);
    }

    .Grid-cell {
        margin-bottom: u($spacing-unit--lg);
    }
}

.Package {
    position: relative;
    height: 100%;
    padding: u($spacing-unit * 3) u($spacing-unit * 2) u($spacing-unit * 2) u($spacing-unit * 2);

    &--background {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-flow: column;
        width: 100%;
        height: 100%;
        pointer-events: none;

        &-fill,
        &-stroke {
            fill: $cc-black;
        }

        &-fill {
            opacity: 0;
            transition: opacity 0.2s linear;
        }

        &-top {
            flex-grow: 0;
            flex-shrink: 0;
            width: 100%;
            height: u($spacing-unit * 3);
        }

        &-middle {
            flex-grow: 1;
            flex-shrink: 1;
            width: 100%;
            height: 100%;
        }

        &-bottom {
            flex-grow: 0;
            flex-shrink: 0;
            width: 100%;
            height: u($spacing-unit * 2);
        }
    }

    &:hover {
        .Package--background-fill {
            opacity: 0.1;
        }
    }

    &--label {
        position: absolute;
        top: 0;
        right: 0;
        padding: u(6) u(15);
        background-color: $cc-xperify-orange;
        font-size: u(14);
        font-weight: 600;
        color: $white;
    }

    &--content {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100%;
    }

    &--title {
        margin-bottom: u(30);
    }

    ul {
        li {
            &::after {
                left: -20px;
                background-image: url("../img/xperify/icons/bullet-package.svg");
                height: u(12);
                width: u(12);
            }
        }
    }

    .ReadMore {
        margin: auto 0 0 auto;
    }

    &.highlight {
        .Package--background-stroke,
        .Package--background-fill {
            fill: $cc-xperify-orange;
        }

        .ReadMore {
            &-text {
                &::after {
                    background-color: $cc-xperify-orange;
                }
            }
        }
    }
}