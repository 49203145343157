.Block--faq {
    .Side {
        padding-right: u(60);

        @include mq($from: $viewport--lg) {
            padding-right: u(80);
        }

        @include mq($until: $viewport--md - 1) {
            margin-top: 0;
        }

        @include mq($until: $viewport--md - 1) {
            margin-bottom: u($spacing-unit--lg);
            padding-right: 0;
        }
    }
}

.Faq {
    &-items {
        border-top: 2px solid $cc-black;
        border-bottom: 2px solid $cc-black;
        padding-top: u(10);
        padding-bottom: u(10);
    }

    &-item {
        padding-left: u($spacing-unit);
        padding-right: u($spacing-unit);

        & + & {
            margin-top: u(10);
            border-top: 2px solid $cc-black;
            padding-top: u(10);
        }

        &.is-active {
            .Faq-question-icon {
                transform: scale(-1);
            }
        }
    }

    &-question {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-gap: u($spacing-unit);
        padding-top: u($spacing-unit * 2 - 10);
        padding-bottom: u($spacing-unit * 1.5);
        user-select: none;
    }

    &-answer {
        overflow: hidden;
        transition: max-height 0.2s linear;
        max-height: 0;

        & > * {
            &:last-child {
                margin-bottom: u($spacing-unit * 2 - 10);
            }
        }
    }
}