/* Button */
/*
 * 1. Corrects inability to style clickable `input` types in iOS.
 * 2. Normalize `box-sizing` across all elements that this component could be
 *    applied to.
 * 3. Inherit text color from ancestor.
 * 4. Inherit font styles from ancestor.
 * 5. Normalize `line-height`. For `input`, it can't be changed from `normal`
 *    in Firefox 4+.
 * 6. Prevent button text from being selectable.
 * 7. Make sure `input` will wrap text across multiple lines.
 *
 */

 .#{$button-namespace}Button {
    appearance: none; // 1
    background: transparent;
    border-color: transparent;
    border-radius: if($button-border-radius > 0, px($button-border-radius), 0);
    border-style: solid;
    border-width: px($button-border-width);
    box-sizing: border-box; // 1
    color: inherit; // 2
    cursor: pointer;
    display: inline-block;
    font: inherit; // 3
    font-size: if($button-font-size == $font-size, null, u($button-font-size));
    line-height: inherit;
    margin: 0;
    padding: em($button-padding--t, $button-padding--r, $button-padding--b, $button-padding--l);
    position: relative;
    text-align: $button-text-align;
    text-decoration: none;
    user-select: none;
    vertical-align: middle;
    white-space: normal; // 7

    // Remove excess padding and border in Firefox 4+
    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }


    // Button fix (Firefox/IE)
    &:focus {
        outline: 1px dotted;
        outline: 5px auto -webkit-focus-ring-color;
    }


    // UI states
    &:active,
    &:focus,
    &:hover,
    &.is-active {
        color: inherit;
        text-decoration: none;
    }

    &:disabled,
    &.is-disabled {
        cursor: default;
        opacity: strip-unit($button-disabled-opacity / 100);
        pointer-events: none;
    }

    .Site-Xperify & {
        border: none !important;
        background: none !important;
        font-weight: 600;

        .Button-masks {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            width: 100%;
            height: 100%;

            .Button-mask {
                display: block;
                height: 100%;

                &--left {
                    flex-grow: 0;
                    flex-shrink: 0;
                    width: em($button-padding--l);
                }

                &--center {
                    flex-grow: 1;
                    flex-shrink: 1;
                    width: 100%;
                }

                &--right {
                    flex-grow: 0;
                    flex-shrink: 0;
                    width: em($button-padding--r);
                }

                &--fill {
                    transition: fill 0.2s linear;
                }
            }
        }

        &.Button--outline {
            .Button-mask--fill {
                opacity: 0;
            }

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                .Button-mask--fill {
                    opacity: 1;
                }
            }
        }

        &:not(.Button--outline) {
            .Button-mask--fill {
                opacity: 1;
            }

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                .Button-mask--fill {
                    opacity: 0;
                }
            }
        }

        .Button-text {
            position: relative;
            z-index: 1;
        }

        .Button-object {
            transition: fill 0.2s linear;
        }
    }
}

.Site-Xperify {
    .ReadMore {
        display: inline-flex;
        align-items: center;
        padding-bottom: 6px;
        font-weight: 600;
        text-decoration: none;
        color: inherit;

        &-text {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                bottom: -6px;
                left: 0;
                opacity: 0;
                transition: opacity 0.2s linear;
                width: 100%;
                height: u(2);
                background-color: $cc-black;
            }
        }

        &:active,
        &:focus,
        &:hover,
        &.is-active {
            color: inherit;
            text-decoration: none;

            .ReadMore-text {
                &::after {
                    opacity: 1;
                }
            }
        }
    }
}