.Site-Ocular {
    .Block--cta {
        background-color: $white;
        padding-top: u($spacing-unit--lg);
        padding-bottom: u($spacing-unit--lg);
    
        @include mq($from: $viewport--md) {
            padding-top: u(120);
            padding-bottom: u(120);
        }
    }
    
    .CallToAction {
        text-align: center;
    
        &-title {
            max-width: u(325);
            margin-bottom: u($spacing-unit * 1.5);
    
            &::after {
                margin-top: u($spacing-unit * 1.5);
            }
    
            &,
            &::after {
                margin-right: auto;
                margin-left: auto;
            }
        }
    }    
}

.Site-Xperify {
    .Block--cta {
        padding-top: u($spacing-unit--lg);
        padding-bottom: u($spacing-unit--lg);
    }
    
    .CallToAction {
        @include mq($until: $viewport--md) {
            text-align: center;
        }

        @include mq($from: $viewport--md) {
            &-body {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        &-title {
            @include mq($from: $viewport--md) {
                margin: 0;
                margin-right: u($spacing-unit--lg);
            }

            @include mq($until: $viewport--md) {
                margin-left: auto;
                margin-right: auto;
                margin-bottom: u($spacing-unit * 1.5);
                max-width: u(325);
            }

            font-weight: 500;
        }
    }
    
}