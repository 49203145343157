.Contact {

    @include mq($from: $viewport--md) {
        display: flex;
        flex-direction: row-reverse;
    }

    &-object {
        max-width: u(612);
        width: 100%;

        @include mq($until: $viewport--md - 1) {
            margin-top: u($spacing-unit--lg);
        }

        @include mq($from: $viewport--md) {
            flex-shrink: 0;
            width: 50%;
        }
    }

    &-body {

        @include mq($from: $viewport--md) {
            @include fluid-type(margin-left, $viewport--md, $viewport--lg, $spacing-unit--lg, 145);
            flex-grow: 1;
        }
    }

    &-title {
        margin-bottom: u($spacing-unit * 1.5);
    }
}
