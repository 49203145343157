.Block--XperifyOverview {
    .XperifyOverview {
        &-intro {
            margin-bottom: u(60);

            @include mq($from: $viewport--sm) {
                margin-bottom: u(80);
            }
        }

        &-title {
            margin-bottom: u(30);
        }
    }

    .Nav--pagination {
        margin-top: u($spacing-unit);
    }
}