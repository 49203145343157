.Block--services {
    color: $black;

    .SplashPage &.is-white,
    .SplashPage &.is-black {
        padding-top: 0;
        padding-bottom: 0;
    }

    &.is-black {
        background-color: $black;
        color: $white;
    }

    &.is-black,
    &.is-white {

        .Container {
            padding-top: u($spacing-unit--lg);
            padding-bottom: u($spacing-unit--lg);

            @include mq($from: $viewport--md) {
                padding-top: u(120);
                padding-bottom: u(120);
                // min-height: 100vh;
                // display: flex;
                // flex-direction: column;
                // justify-content: center;
            }
        }
    }
}

.Services {

    @include mq($from: $viewport--md) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        height: 100%;
        margin-left: u($spacing-unit--lg * -1);
    }

    &-body,
    &-object {

        @include mq($from: $viewport--md) {
            padding-left: u($spacing-unit--lg);
        }
    }

    &-body {

        @include mq($from: $viewport--md) {
            display: flex;
            flex-grow: 1;
            flex-shrink: 0;
        }
    }

    &-object {

        @include mq($from: $viewport--md) {
            max-width: u(500);
            width: 40%;
        }

        @include mq($from: $viewport--lg) {
            width: 50%
        }
    }

    &-category {
        margin-top: u($spacing-unit--xl);

        @include mq($from: $viewport--md) {
            width: auto;

            &:last-child {
                text-align: right;
                margin-left: u($spacing-unit * -1.5);
                margin-top: u(190);
            }
        }
    }

    &-title {
        color: currentColor;

        @include mq($from: $viewport--md) {

            &.has-borderBottom {

                .Services-category:last-child & {

                    &::after {
                        margin-left: auto;
                    }
                }
            }
        }
    }

    &-object {

        .Slider {

            &-object,
            &-image {
                width: 100%;
            }

            @include mq($from: $viewport--md, $until: $structure-responsive-breakpoint - 1) {
                margin-right: u($structure-gutter--l * -1);
            }

            @include mq($from: $structure-responsive-breakpoint) {
                margin-right: calc((100vw - #{u($structure-width)}) / -2);
            }
        }
    }
}

.Reference {
    filter: grayscale(100%);
    padding-top: u(30);
    padding-left: u(30);
    width: (100% / 3);

    @include mq($from: $viewport--md) {
        width: (100% / 7);
    }

    &s {
        display: flex;
        flex-flow: row wrap;
        margin-top: u(-30);
        margin-left: u(-30);
    }
}
