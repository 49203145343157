.Block--page {
    padding-top: u($spacing-unit--lg);

    @include mq($from: $viewport--md) {
        padding-top: u(120);
    }

    + .Block {
        margin-top: u($spacing-unit--lg * -1);

        @include mq($from: $viewport--md) {
            margin-top: u(-120);
        }
    }
}

.Page {

    &-title {
        max-width: u(400);

        &::after {
            content: none;
        }
    }
}
