/* Main */
html {
    background-color: $bg-color;
    color: $text-color;
    font-family: $font-sans;
    height: 100%;

    &:not(.Site-Xperify) {
        font-family: $font-gordita;
    }

    &.Site-Xperify {
        background-color: inherit;
        font-family: $font-montserrat;
        color: $cc-black;
    }

    &.hide-scroll {
        overflow: hidden;
    }
}

body {
    min-height: 100%;
}
