// LazySizes
.LazyLoad,
.LazyLoading {
    opacity: 0;
}

.LazyLoaded {
    @include transition(opacity, 300ms);
    opacity: 1;
}

// Button
button {
    @include transition(#{background-color, border-color, color});
}

// Tabbing
body:not(.user-is-tabbing) {

    span:focus,
    button:focus,
    input:focus,
    select:focus,
    textarea:focus {
        outline: none;
    }
}

// Scroll
.Scroll {
    @include position(fixed, $top: 0, $left: 0, $z: 9998);
    @include dimensions(100vw, 100vh);
    transform-style: preserve-3d;

    &,
    &-content
    &Height {
        will-change: transform;
        backface-visibility: hidden;
        perspective: 1000;
    }

    &Height {
        position: relative;
        width: 100%;
    }
}

// Cursor
@include mq($from: $viewport--md) {

    @supports (mix-blend-mode: exclusion) {

        .Cursor {
            @include position(fixed, $z: 9999999);
            @include dimensions(80);
            transform-origin: 50% 50%;
            pointer-events: none;
            mix-blend-mode: exclusion;

            &-item {
                @include transition(transform, 300ms, ease-in-out);
                @include dimensions(100%);
                background-color: $white;
                // backface-visibility: hidden;
                border-radius: 100%;
                transform: scale(.15);
                mix-blend-mode: exclusion;

                @include parent-nth-status(1, '.Cursor--md', prepend) {
                    transform: scale(0.5);
                }

                @include parent-nth-status(1, '.Cursor--lg', prepend) {
                    transform: scale(1);
                }
            }
        }

        .has-cursor {

            &, * {
                cursor: none !important;
            }
        }
    }
}

.Site-Ocular {
    // Border
    .has-borderLeft {
        border-left: px(3) solid;
        padding-left: u($spacing-unit * 1.5);
    }

    .has-borderBottom {

        &::after {
            border-bottom: px(3) solid;
            content: '';
            display: block;
            max-width: u(60);
            margin-top: u($spacing-unit);
        }
    }
}

// Title
.Bg-title {
    @include fluid-type(font-size, $viewport--sm, $viewport--lg, 60, 174);
    color: transparent;
    font-weight: bold;
    opacity: 0.2;
    line-height: 1.3;
    text-transform: lowercase;
    user-select: none;
    text-shadow: -1px -1px 0 rgba($white, .1), 1px -1px 0 rgba($white, .1), -1px 1px 0 rgba($white, .1), 1px 1px 0 rgba($white, .1);

    .is-white &,
    .is-grey & {
        text-shadow: -1px -1px 0 rgba($black, .1), 1px -1px 0 rgba($black, .1), -1px 1px 0 rgba($black, .1), 1px 1px 0 rgba($black, .1);
    }

    @supports (-webkit-text-stroke: 1px black) {
        text-shadow: none;
        -webkit-text-stroke: px(1) $white;

        .is-white &,
        .is-grey & {
            text-shadow: none;
            -webkit-text-stroke: px(1) rgba($black, .5);
        }
    }
}

// Link
.Link {
    @include font-size(11, false);
    color: $white;
    cursor: pointer;
    text-transform: lowercase;

    &.has-icon {
        display: inline-flex;
        align-items: center;
    }

    &:hover {
        text-decoration: underline;
    }
}

// Play
.Media {

    &-link {
        @include grid(1fr, 1fr);
        color: currentColor;
        text-decoration: none;
    }

    &-object {
        @include grid-area(1, 2, 1, 2);
        background-color: $cc-black;
        max-width: u(870);
        margin-right: auto;
        margin-left: auto;
        overflow: hidden;
    }

    &-image {
        .Site-Ocular & {
            filter: blur(2px);
            opacity: 0.63;
        }
    }

    .Play {
        @include position(relative, $z: 1);
        @include grid-area(1, 2, 1, 2);
        align-self: center;
        justify-self: center;
    }
}

.Play {
    color: $white;
    display: flex;
    align-items: center;
    font-weight: 500;

    &::before {
        @include arrow(right, 46, 27, $white);
        content: '';
        display: block;
        margin-right: u($spacing-unit);
    }
}

// Form
.FormItem {
    @include font-size(14, false);
    max-width: u(460);

    .FormField-label a:not([class]) {
        color: currentColor;

        &:hover {
            text-decoration: none;
        }
    }
}

.Form-field--checkbox {
    font-size: (100% / 14 * 12);

    .Form-indicator {
        @include position(relative, $top: em((20 - 16) / 2));
    }
}

// Effect
@include mq($from: $viewport--md) {

    @supports(clip-path: polygon(0px 0%, 100% 0%, 100% 100%, 0% 100%)) {

        [data-effect] {
            position: relative;
            overflow: hidden;

            > img,
            > picture,
            > .BoxItem-object--icon {
                @include transition(#{clip-path, transform}, 1s, ease-in);
                clip-path: polygon(0px 0%, 100% 0%, 100% 100%, 0% 100%);
                will-change: clip-path, transform;
                transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
            }

            &:not(.is-active) {

                > img,
                > picture,
                > .BoxItem-object--icon {
                    clip-path: polygon(0px 100%, 100% 100%, 100% 100%, 0% 100%);
                    transform: translateY(#{u($spacing-unit--lg)});
                }
            }
        }
    }
}


.is-mirror {
    transform: scale(-1, -1);
}

.More {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: u($spacing-unit--sm * - 1);
    margin-left: u($spacing-unit--sm * - 1);

    > .Button {
        margin-top: u($spacing-unit--sm);
        margin-left: u($spacing-unit--sm);
    }
}
