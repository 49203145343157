.Nav--side {
    .Nav {
        &-item {
            margin-bottom: u(20);

            &::after {
                display: none;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &-link {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: u(30);
            background-color: $cc-xperify-grey;
            font-weight: 600;

            &,
            &:active,
            &:focus,
            &:hover {
                color: $cc-black;
            }

            &.is-active {
                color: $cc-xperify-pink;

                .Nav-icon {
                    fill: $cc-xperify-pink;
                }
            }
        }
    }
}