/* Fields: file */
.#{$form-namespace}Form-field--file {
    cursor: pointer;
    display: block;
    max-width: u(290);

    input[type='file'] {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        // UI state
        &:focus ~ .#{$form-namespace}Form-indicator {
            border-color: $form-element-border-color--focus;
            box-shadow: if(is-false($form-element-box-shadow), null, $form-element-box-shadow) if(is-false($form-element-box-shadow) or is-false($form-element-box-shadow--focus), null, #{','}) if(is-false($form-element-box-shadow--focus), null, $form-element-box-shadow--focus);

            // Error
            .#{$form-namespace}Form-item.is-error & {
                box-shadow: if(is-false($form-element-box-shadow), null, $form-element-box-shadow) if(is-false($form-element-box-shadow) or is-false($form-element-box-shadow--focus), null, #{','}) if(is-false($form-element-box-shadow--focus), null, $form-error-element-box-shadow);
            }

            // Success
            .#{$form-namespace}Form-item.is-success & {
                box-shadow: if(is-false($form-element-box-shadow), null, $form-element-box-shadow) if(is-false($form-element-box-shadow) or is-false($form-element-box-shadow--focus), null, #{','}) if(is-false($form-element-box-shadow--focus), null, $form-success-element-box-shadow);
            }
        }
    }

    .#{$form-namespace}Form-indicator {
        @include font-smoothing($font-smoothing);
        background-color: $form-element-background-color;
        border-radius: u(25.5);
        border: px($form-element-border-width) solid $form-element-border-color;
        box-shadow: if(is-false($form-element-box-shadow), null, $form-element-box-shadow);
        color: $form-element-color;
        display: block;
        line-height: normal;
        overflow: hidden;
        padding: em(18, $form-element-padding--r,);
        position: relative;
        white-space: nowrap;
        width: 100%;

        &::before {
            @extend .u-textTruncate !optional;
            content: attr(data-file-text);
            display: block;
            padding-right: u($form-file-element-button-width);
            padding-left: u($spacing-unit * 1.50);
        }

        &::after {
            @include background-svg('<svg width="11" height="8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.36 16.46"><path d="M21 8.23H0M13.36.73l8 7.5-8 7.5" fill="none" stroke="#{$white}" stroke-linejoin="round" stroke-width="2"></path></svg>');
            @include transition(background-color);
            background-repeat: no-repeat;
            background-position: 50%;
            background-color: $black;
            border-radius: u(25.5);
            color: $form-file-element-button-color;
            content: '';
            // padding: em($form-element-padding--t, $form-element-padding--r, $form-element-padding--b, $form-element-padding--l);
            text-align: center;
            width: u(51);

            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;

            @include parent-nth-status(1, ':hover', suffix) {
                background-color: $cc-blue;
            }
        }
    }


    // Fixed or fluid width
    @if is-false($form-element-fluid) and not is-null($form-element--default) {
        @include mq($from: $viewport--sm) {
            max-width: u($form-element--default);
        }
    }
}
