/* Button */

.Button {

    &.has-icon {
        .Site-Ocular & {
            display: inline-flex;
            align-items: center;
        }

        .Site-Xperify & {
            .Button-text {
                display: inline-flex;
                align-items: center;
            }
        }
    }

    &-object {
        display: block;
    }

    // Primary
    &--primary {

        .Site-Xperify & {
            .Button-mask--fill,
            .Button-mask--outline {
                fill: $cc-black;
            }
        }

        &:not(.Button--outline) {
            .Site-Ocular & {
                background-color: $cc-blue;
                color: $white;
                font-weight: 700;

                &:active,
                &:focus,
                &:hover,
                &.is-hovered {
                    background-color: $cc-black;
                }
            }

            .Site-Xperify & {
                color: $white;

                .Button-object {
                    fill: $white;
                }

                &:active,
                &:focus,
                &:hover,
                &.is-active,
                &.is-hovered {
                    color: $cc-black;

                    .Button-object {
                        fill: $cc-black;
                    }
                }
            }
        }

        &.Button--outline {
            .Site-Ocular & {
                color: $cc-black;
                border-color: $cc-black;

                &:active,
                &:focus,
                &:hover,
                &.is-active,
                &.is-hovered {
                    background-color: $cc-black;
                    border-color: $cc-black;
                    color: $white;
                }
            }

            .Site-Xperify & {
                color: $cc-black;

                .Button-object {
                    fill: $cc-black;
                }

                &:active,
                &:focus,
                &:hover,
                &.is-active,
                &.is-hovered {
                    color: $white;

                    .Button-object {
                        fill: $white;
                    }
                }
            }
        }
    }

    // Secondary
    &--secondary {

        .Site-Xperify & {
            .Button-mask--fill,
            .Button-mask--outline {
                fill: $cc-xperify-orange;
            }
        }

        &:not(.Button--outline) {
            .Site-Ocular & {
                background-color: $cc-black;
                color: $white;
                font-weight: 700;

                &:active,
                &:focus,
                &:hover,
                &.is-active,
                &.is-hovered {
                    background-color: $cc-blue;
                }
            }

            .Site-Xperify & {
                color: $white;

                .Button-object {
                    fill: $white;
                }

                &:active,
                &:focus,
                &:hover,
                &.is-active,
                &.is-hovered {
                    color: $cc-xperify-orange;

                    .Button-object {
                        fill: $cc-xperify-orange;
                    }
                }
            }
        }

        &.Button--outline {
            .Site-Ocular & {
                color: $white;
                border-color: $white;

                &:active,
                &:focus,
                &:hover,
                &.is-active,
                &.is-hovered {
                    background-color: $white;
                    border-color: $white;
                    color: $cc-black;
                }
            }

            .Site-Xperify & {
                color: $cc-xperify-orange;

                .Button-object {
                    fill: $cc-xperify-orange;
                }

                &:active,
                &:focus,
                &:hover,
                &.is-active,
                &.is-hovered {
                    color: $white;

                    .Button-object {
                        fill: $white;
                    }
                }
            }
        }
    }

    // Tertiary
    &--tertiary {

        .Site-Xperify & {
            .Button-mask--fill,
            .Button-mask--outline {
                fill: $cc-xperify-pink;
            }
        }

        .Site-Ocular & {
            &.Button--outline {
                background-color: $cc-black;
                color: $white;
                border-color: $cc-black;
                font-weight: 500;

                &:active,
                &:focus,
                &:hover,
                &.is-active,
                &.is-hovered {
                    background-color: $cc-blue;
                    border-color: $cc-blue;
                    color: $white;
                }
            }
        }

        .Site-Xperify & {
            &:not(.Button--outline) {
                color: $white;

                .Button-object {
                    fill: $white;
                }

                &:active,
                &:focus,
                &:hover,
                &.is-active,
                &.is-hovered {
                    color: $cc-xperify-pink;

                    .Button-object {
                        fill: $cc-xperify-pink;
                    }
                }
            }

            &.Button--outline {
                color: $cc-xperify-pink;

                .Button-object {
                    fill: $cc-xperify-pink;
                }

                &:active,
                &:focus,
                &:hover,
                &.is-active,
                &.is-hovered {
                    color: $white;

                    .Button-object {
                        fill: $white;
                    }
                }
            }
        }
    }

    // quaternary
    &--quaternary {

        &.Button--outline {
            background-color: $white;
            color: $black;
            border-color: $white;
            font-weight: 500;

            &:active,
            &:focus,
            &:hover,
            &.is-active,
            &.is-hovered {
                background-color: $cc-blue;
                border-color: $cc-blue;
                color: $white;
            }
        }
    }

    &--white {
        .Site-Xperify & {
            .Button-mask--fill,
            .Button-mask--outline {
                fill: $white;
            }

            &.Button--outline {
                color: $white;

                .Button-object {
                    fill: $white;
                }

                &:active,
                &:focus,
                &:hover,
                &.is-active,
                &.is-hovered {
                    color: $cc-black;

                    .Button-object {
                        fill: $cc-black;
                    }
                }
            }
        }
    }

    // Info
    &--info {
        background-color: $blue;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $blue--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $blue;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $blue--700;
            }
        }
    }

    // Success
    &--success {
        background-color: $green;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $green--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $green;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $green--700;
            }
        }
    }

    // Warning
    &--warning {
        background-color: $orange;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $orange--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $orange;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $orange--700;
            }
        }
    }

    // Danger
    &--danger {
        background-color: $red;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $red--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $red;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $red--700;
            }
        }
    }

    // Outline
    &--outline {
        .Site-Ocular & {
            border: px(1) solid;
            border-radius: u($spacing-unit);
            font-size: u(14);
            font-weight: 500;
            padding: em($spacing-unit--sm, $spacing-unit * 1.5);
        }
    }

    // Fixed
    &--fixed {
        @include transition(#{background-color, color, transform});
        @include position(fixed, $bottom: 0, $right: 0, $z: 9998);

        &:not(.is-active) {
            transform: translateX(100%);
        }
    }

    // Large
    &--large {
        @include font-size($button-font-size * 1.25, false);
        padding-right: u($button-padding--r * 2);
        padding-left: u($button-padding--l * 2);
    }

    // Small
    &--small {
        @include font-size($button-font-size * .75, false);
        padding-right: u($button-padding--r / 1.5);
        padding-left: u($button-padding--l / 1.5);
    }

    // Full
    &--full {
        display: block;
        width: 100%;
    }

    // Bare
    &--bare {
        border: 0;
        border-radius: 0;
        padding: 0;

        &:hover {
            text-decoration: underline;
        }
    }
}
