.Block--Quote {
    padding-top: u(80);
    padding-bottom: u(80);

    @include mq($until: $viewport--md) {
        padding-top: u(40);
        padding-bottom: u(40);
    }

    .Quote {
        @include mq($from: $viewport--lg) {
            padding-left: u(120 - $structure-gutter--l);
            padding-right: u(120 - $structure-gutter--r);
        }

        p:not(.Author) {
            @include font-size(24);
            font-weight: 500;
            margin-bottom: 0;

            @include mq($from: $viewport--md) {
                @include font-size(32);
            }
        }

        strong {
            font-weight: 600;
        }
    }

    .Author {
        margin-top: u(6);
        margin-bottom: 0;
    }

    .Icon {
        position: absolute;

        &-top {
            top: u((80 + (40 / 2)) * -1);
            left: 0;

            @include mq($until: $viewport--md) {
                top: u((40 + (40 / 2)) * -1);
            }

            @include mq($until: $viewport--xl) {
                left: u($structure-responsive-gutter--r);
            }
        }

        &-bottom {
            bottom: u((80 + (40 / 2)) * -1);
            right: 0;

            @include mq($until: $viewport--md) {
                bottom: u((40 + (40 / 2)) * -1);
            }

            @include mq($until: $viewport--xl) {
                right: u($structure-responsive-gutter--r);
            }
        }
    }
}