.Block--form {
    padding-top: u($spacing-unit--lg);
    padding-bottom: u($spacing-unit--lg);

    &.is-black {
        background-color: $black;
        color: $white;
    }

    .Block + & {
        margin-top: 0;
    }

    & + .Block--cta {
        margin-top: 0;
    }

    @include mq($from: $viewport--md) {
        padding-top: u(120);
        padding-bottom: u(120);
    }
}

.Form {

    @include mq($from: $viewport--md) {
        display: flex;
        justify-content: space-between;
        margin-left: u($spacing-unit--lg * -1);
        max-width: u(1160);

        &-object,
        &-body {
            padding-left: u($spacing-unit--lg);
            width: 50%;
        }
    }

    &-title {
        color: currentColor;
        max-width: u(340);
    }

    &-object {
        max-width: u(500 + $spacing-unit--lg);

        @include mq($until: $viewport--md - 1) {
            margin-bottom: u($spacing-unit--lg);
        }
    }

    &-body {
        max-width: u(460);
    }
}
