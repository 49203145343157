.Nav--priority {

    .Header > .Container > & {
        @include font-size(14, false);
        @include transition(#{opacity, visibility}, 300ms);

        .Site-Xperify & {
            @include font-size(16, false);
            font-weight: 600;
        }

        .show-nav & {
            opacity: 0;
            visibility: hidden;
        }

        .Nav-link.is-active {
            position: relative;

            &::before {
                @include position(absolute, $bottom: 100%, $left: 50%, $z: 1);
                @include dimensions(7);
                background-color: currentColor;
                border-radius: 100%;
                content: '';
                display: block;
                transform: translateX(-50%) translateY(-150%);

                .Site-Xperify & {
                    display: none;
                }
            }
        }
    }

    .Navigation & {
        @include transition(#{opacity, transform}, 500ms, #{linear, cubic-bezier(.19,1,.22,1)});
        @include font-size(23, false);
        @include grid-area(1, 4, 1, 2);
        align-self: end;
        line-height: (23 / 20);

        html:not(.show-nav) & {
            opacity: 0;
            transform: translateY(#{u($spacing-unit * -1)});
        }

        @include mq($from: $viewport--md) {
            @include grid-area(2, 4, 1, 2);
            @include font-size(20, false);
            margin-bottom: u($spacing-unit--lg);
        }

        .Nav-item {

            & + .Nav-item {
                margin-top: u($spacing-unit * 0.75);

                @include mq($from: $viewport--md) {
                    margin-top: u($spacing-unit);
                }
            }
        }

        .Nav-link {
            @include transition(#{color, padding-right});
            display: inline-flex;
            align-items: center;

            &::before {
                @include transition(#{opacity, margin-left});
                @include dimensions(7);
                background-color: currentColor;
                border-radius: 100%;
                content: '';
                display: block;
                margin-right: u(14);

                .Site-Xperify & {
                    @include dimensions(14);
                    background-color: transparent;
                    background-image: url("../img/xperify/icons/nav--hover.svg");
                    background-position: center top;
                    background-repeat: no-repeat;
                    border-radius: 0;
                }
            }

            &:not(:hover) {
                padding-right: u(21);

                &::before {
                    margin-left: u(-21);
                    opacity: 0;
                }
            }

            &.is-active {
                font-weight: 500;
            }
        }

        .Nav-item-dropdown-second {
            // font-weight: 500;

            span {
                .Icon {
                    display: none;
                }

                &:before {
                    content: "";
                    display: block;
                    @include dimensions(14);
                    margin-left: u(-21);
                    margin-right: u(14);
                }

            }

            .Nav-dropdown-wrapper {
                height: auto;
                opacity: 1;
            }

            .Nav-link {
                white-space: nowrap;
            }
        }
    }

    .Nav-item {
        z-index: 1;
    }

    .Nav-item-dropdown {
        position: relative;
        z-index: 0;

        span {
            display: flex;

            .Icon {
                margin-left: 10px;
                width: 10px;
            }
        }

        &:hover {
            .Nav-dropdown {
                &-wrapper {
                    display: block;
                }
            }
        }

        .Nav-dropdown {
            margin: 0;
            width: 100%;
            padding: u(20) u(40);
            background-color: $cc-xperify-grey--light;

            &-wrapper {
                position: absolute;
                z-index: 1;
                top: u(0);
                left: calc(50% + #{u(21)});
                transform: translateX(-50%);
                display: none;
                width: u(390);
                padding-top: u(60);
            }

            &::before {
                content: "";
                position: absolute;
                left: u(0);
                top: u(40);
                right: 0;
                margin: auto;
                width: 0;
                height: 0;
                border-left: u(20) solid transparent;
                border-right: u(20) solid transparent;
                border-bottom: u(20) solid $cc-xperify-grey--light;
            }

            .Nav-item {
                padding: 0;
                text-align: center;

                &:not(:last-child) {
                    margin-bottom: u(9);
                    border-bottom: 1px solid $cc-grey;
                    padding-bottom: u(9);
                }
            }
        }
    }

    .Nav-item-dropdown-second {
        position: relative;
        z-index: 0;
        transition: all 1s ease-in-out;
        cursor: pointer;
        width: 200px;

        span {
            display: flex;

            .Icon {
                margin-left: 10px;
                width: 10px;
            }
        }

        &:hover {
            .Nav-dropdown {
                &-wrapper {
                    height: auto;
                    opacity: 1;
                }
            }
        }

        .Nav-dropdown {
            margin: 0;
            width: 100%;
            padding: u(20) u(40);
            background-color: $cc-xperify-grey--light;

            &-wrapper {
                height: 0;
                opacity: 0;
                position: relative;
                z-index: 1;

                @include mq ($until: $viewport--md - 1) {
                    width: 50px;
                }
            }

            &::before {
                content: "";
                position: absolute;
                left: u(0);
                top: u(40);
                right: 0;
                margin: auto;
                width: 0;
                height: 0;
                border-left: u(20) solid transparent;
                border-right: u(20) solid transparent;
                border-bottom: u(20) solid $cc-xperify-grey--light;
            }

            .Nav-item {
                padding: 0;
                text-align: center;

                &:not(:last-child) {
                    margin-bottom: u(9);
                    padding-bottom: u(9);
                }
            }
        }
    }

    .Footer & {
        @include font-size(15, false);
    }

    .Nav-list {
        margin-left: u($spacing-unit--lg * -1);
    }

    .Nav-item {
        padding-left: u($spacing-unit--lg);

        .Footer & {

            + .Nav-item {
                margin-top: u($spacing-unit--sm);
            }
        }
    }

    .Nav-link {

        &,
        &:active,
        &:focus,
        &:hover {
            color: currentColor;
        }

        .Footer & {

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
