.Block--clients {
    background-color: $white;
    padding-top: u($spacing-unit--lg);
    padding-bottom: u($spacing-unit--lg);

    .Block + & {
        margin-top: 0;
    }

    @include mq($from: $viewport--md) {
        padding-top: u(120);
        padding-bottom: u(120);
    }
}

.Clients {

    &-title {
        max-width: u(270);
        margin-bottom: 0;

        .Site-Xperify & {
            max-width: u(800);
        }
    }

    &-object {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding-top: u($spacing-unit--lg);
        margin-top: u($spacing-unit--sm * -1);
        margin-left: u($spacing-unit--sm * -1);
    }

    .Button {
        .Site-Xperify & {
            margin-top: u($spacing-unit--lg);
        }
    }

    @include mq($until: $viewport--md - 1) {

        &-object {
            margin-bottom: u($spacing-unit--lg);
        }
    }

    .Site-Ocular & {
        @include mq($from: $viewport--md) {
            @include grid(1fr auto, auto auto);

            &-title {
                @include grid-area(1, 2, 1, 2);
            }

            &-object {
                @include grid-area(1, 3, 2, 3);
            }

            .Button {
                @include grid-area(2, 3, 1, 2);
                align-self: end;
            }
        }
    }
}

.Client {
    @include transition(filter);
    padding-top: u($spacing-unit--sm);
    padding-left: u($spacing-unit--sm);

    @include mq($until: $viewport--md - 1) {
        text-align: center;
        width: (100% / 3);
    }

    &:not(:hover) {
        filter: grayscale(100%);
    }

    .Site-Xperify & {
        max-width: u(220);
    }
}
