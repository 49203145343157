.Nav--footer {

    .Nav-list {
        margin-left: u($spacing-unit * -1);
    }

    .Nav-item {
        padding-left: u($spacing-unit);
    }

    .Nav-link {

        &,
        &:active,
        &:focus,
        &:hover {
            color: currentColor;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}
