/* Slider: navigation */
.Slider-buttons {
    display: flex;
    margin-top: u($spacing-unit);
    margin-left: u($spacing-unit--sm * -1);

    @include mq($from: $viewport--md) {
        flex-direction: column;
        margin-top: u($spacing-unit--sm * -1);
    }
}

.Slider-button {
    @include transition(#{background-color, opacity});
    @include dimensions(45);
    background-color: $cc-grey--dark;
    background-image: none;
    color: $white;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: u($spacing-unit--sm);

    @include mq($from: $viewport--md) {
        margin-top: u($spacing-unit--sm);
    }

    &:not(.is-disabled):hover {
        background-color: rgba($cc-grey--dark, .35);
    }

    &.is-disabled {
        cursor: auto;
        opacity: 0.35;
    }

    &.is-locked {
        display: none;
    }
}

.SliderButton-icon {
    transform: rotate(90deg);
    display: block;

    .Slider-button--next & {
        transform: rotate(-90deg);
    }
}
