/* Logo */
.Logo {
    flex-shrink: 0;

    &,
    &:active,
    &:focus,
    &:hover {
        color: currentColor;
    }

    &-object {
        @include dimensions(75, 75.28);
        display: block;

        .Logo--footer & {
            @include dimensions(85, 119);
        }
    }
}
