/* Navigation */
.Navigation {

    &-toggle {
        color: currentColor;
        display: block;
        opacity: .8;
    }

    &-body {
        @include position(fixed, $top: u($structure-header-height), $right: 0, $left: 0, $z: 1);
        @include transition(#{opacity, visibility});
        color: $white;

        .Site-Xperify & {
            color: $cc-black;

            // @include mq ($until: $viewport--md -1) {
            //     color: $cc-black;
            //     overflow-y: auto !important;
            // }
        }

        padding: u(45);
        height: calc(100vh - #{u($structure-header-height)});

        @include mq($from: $viewport--md) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: u(125);
        }

        > .Container {
            @include grid(1fr auto, auto 1fr auto);

            @include mq($until: $viewport--md - 1) {
                height: 100%;
                padding-right: 0;
                padding-left: 0;
            }

            @include mq($from: $viewport--md) {
                @include grid(1fr auto auto, 1fr auto);
            }
        }

        html:not(.show-nav) & {
            opacity: 0;
            visibility: hidden;
        }
    }
}

/* Types */
@import '6-components/navigation/main';
@import '6-components/navigation/lang';
@import '6-components/navigation/footer';
@import '6-components/navigation/pagination';
@import '6-components/navigation/priority';
@import '6-components/navigation/socialMedia';
@import '6-components/navigation/services';
@import '6-components/navigation/side';
