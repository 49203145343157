.Site-Ocular {
    .Block--banner {
        background-color: $black;
        color: $white;
        padding-top: u($structure-header-height);
        padding-bottom: u($spacing-unit--lg);
        overflow: hidden;

        @include mq($from: $viewport--md) {
            height: 100vh;
        }

        > .Container {
            height: 100%;
        }
    }

    .Banner {
        @include grid(1fr auto, repeat(4, auto));
        height: 100%;

        @include mq($from: $viewport--md) {
            @include grid(1fr auto, 1fr auto 1fr 30px);
        }

        &-object {
            @include transition(#{transform, opacity}, 1s);
            @include grid-area(1, 3, 1, 4);
            position: relative;
            max-width: 100%;
            transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);

            &::after {
                @include dimensions(320, 318);
                @include position(absolute, $bottom: 0, $left: 0, $z: 1);
                @include background-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250.35 251.31"><title>icon</title><path d="M126.91,245.41A118.17,118.17,0,1,1,245.08,127.25,118.3,118.3,0,0,1,126.91,245.41Zm0-225.69A107.53,107.53,0,1,0,234.44,127.25,107.65,107.65,0,0,0,126.91,19.72Zm5.25,231.59a113.84,113.84,0,0,1-94.67-50.66A113.81,113.81,0,0,1,113.1,2.41,113.9,113.9,0,0,1,245.69,146.14,114,114,0,0,1,132.16,251.31ZM43.86,206a111.8,111.8,0,0,0,198.73-50.84,114,114,0,0,1-105.34,72.53A113.8,113.8,0,0,1,43.86,206ZM39,199.32a111.39,111.39,0,0,0,74.82,28.78,112.75,112.75,0,0,0,13.2-.77A114,114,0,0,1,22.64,114.79,111.81,111.81,0,0,0,39,199.32ZM25,105.55c-.2,2.74-.3,5.52-.3,8.31A112,112,0,0,0,136.49,225.69H137A111.8,111.8,0,0,0,207,54.41,111.78,111.78,0,0,0,25,105.55ZM212.9,60.15a113.82,113.82,0,0,1-65.85,165,112.12,112.12,0,0,0,96.64-79.43q.3-4.12.3-8.31A111.46,111.46,0,0,0,212.9,60.15ZM103.31,4.93A111.78,111.78,0,0,0,33.12,193.57,113.81,113.81,0,0,1,23,105.18,114.13,114.13,0,0,1,103.31,4.93ZM208.53,53.08A113.61,113.61,0,0,1,246,136.53,111.92,111.92,0,0,0,123.29,2.8,113.94,113.94,0,0,1,208.53,53.08ZM113.32,4.44a112.16,112.16,0,0,0-87.26,91.7,113.8,113.8,0,0,1,176.1-48.43,111.73,111.73,0,0,0-88.3-43.27Z" fill="#{rgba(#fefefe, .1)}"/></svg>');
                background-size: u(559, 558);
                background-repeat: no-repeat;
                background-position: top right;
                content: '';
            }

            @include mq($from: $viewport--md) {

                html:not(.is-loaded) & {
                    opacity: 0;
                    transform: scale(2);
                }
            }

            .Slider {

                &,
                &-object {
                    height: 100%;
                }

                &-item {
                    max-width: u(1600);
                    width: 100%;
                }

                @include mq($until: $viewport--md - 1) {

                    &-item {
                        height: auto;
                    }
                }

                &-object {
                    position: relative;

                    &::after {
                        @include position(absolute, 0, 0, 0, 0, 1);
                        background-image: linear-gradient(to bottom, rgba($cc-black, 0.53) 0%, rgba(#D8D8D8, 0));
                        content: '';
                    }

                    &--video {

                        video {
                            @include position(absolute, 0, 0, 0, 0);

                            @include mq($until: $viewport--md - 1) {
                                @include dimensions(100%);
                                object-fit: cover;
                            }

                            @include mq($from: $viewport--md) {
                                @include dimensions(auto);
                                top: auto;
                                left: auto;
                                min-width: 100%;
                                min-height: 100%;
                            }
                        }
                    }
                }

                &-object,
                &-image {
                    display: block;
                }

                &-image {
                    @include dimensions(100%);
                    object-fit: cover;
                }
            }
        }

        &-titles {
            @include grid-area(1, 3, 1, 2);
            @include position(relative, $z: 1);
            align-self: center;

            @include mq($until: $viewport--md - 1) {
                margin-top: u($spacing-unit);
                margin-bottom: u($spacing-unit);
            }

            @include mq($from: $viewport--md) {
                @include transition(opacity, 500ms);
                @include grid-area(1, 2, 2, 3);

                html:not(.is-loaded) &:not(.is-copy) {
                    opacity: 0;
                }

                &.is-copy {
                    transition-delay: 250ms;
                    grid-row: 1/5;
                    align-self: stretch;
                    margin-top: u($structure-header-height * -1);
                    margin-bottom: u($spacing-unit--lg * -1);

                    .is-loaded & {
                        opacity: 0;
                    }
                }
            }
        }

        &-title,
        &-subtitle {
            color: currentColor;
        }

        &-title {
            font-weight: 700;
            margin-left: u($spacing-unit);
            margin-bottom: 0;
            line-height: 1.05;
            letter-spacing: 0;
            transition-delay: 1s;
            text-shadow: -1px -1px 0 rgba($white, .1), 1px -1px 0 rgba($white, .1), -1px 1px 0 rgba($white, .1), 1px 1px 0 rgba($white, .1);

            @supports (-webkit-text-stroke: 1px black) {
                text-shadow: none;
                -webkit-text-stroke: px(1) $white;
            }

            @include mq($from: $viewport--md, $until: 1009) {
                margin-left: u($spacing-unit--lg);
            }

            @include mq($from: 1100) {
                @include fluid-type(margin-left, $viewport--sm, $viewport--lg, $spacing-unit, 160);
            }

            @include mq($from: $viewport--md) {
                @include transition(color, 250ms);

                html:not(.is-loaded) &:not(.Banner-title--copy) {
                    color: transparent;
                }
            }

            &--copy {
                color: transparent;
            }
        }

        &-subtitle {
            line-height: (38 / 30);

            @include mq($from: $viewport--md) {

                &::after {
                    content: none;
                }
            }
        }

        &-body,
        .Button {
            margin-right: u($spacing-unit);
            margin-left: u($spacing-unit);

            @include mq($from: 1100) {
                @include fluid-type(margin-right, $viewport--sm, $viewport--lg, $spacing-unit, 160);
            }

            @include mq($from: $viewport--md) {
                @include transition(#{background-color, color, opacity}, #{200ms, 200ms, 500ms});
                margin-left: u($spacing-unit * 1.5);
                transition-delay: 0s, 0s, 1250ms;

                html:not(.is-loaded) & {
                    opacity: 0;
                }
            }
        }

        &-body {
            @include grid-area(1, 3, 2, 3);
            @include position(relative, $z: 1);
            align-self: start;
            max-width: u(335);

            @include mq($until: $viewport--md - 1) {
                margin-bottom: u($spacing-unit);
            }

            @include mq($from: $viewport--md) {
                @include grid-area(2, 3, 2, 3);
                align-self: end;
            }
        }

        .Button {
            @include position(relative, $z: 1);
            @include grid-area(1, 3, 3, 4);
            justify-self: start;
            align-self: end;

            @include mq($until: $viewport--md - 1) {
                margin-bottom: u($spacing-unit);
            }

            @include mq($from: $viewport--md) {
                @include grid-area(2, 3, 3, 5);
            }
        }

        .Slider-pagination {
            @include position(relative, $z: 1);
            @include grid-area(2, 3, 1, 2);
            justify-self: end;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @include mq($until: $viewport--md - 1) {
                align-self: center;
                margin-top: u($spacing-unit);
                margin-right: u($spacing-unit - 7);
            }

            @include mq($from: $viewport--md) {
                @include grid-area(2, 3, 1, 4);
            }

            &-item {
                @include dimensions(7);

                + .Slider-pagination-item {
                    margin-top: u(13);
                }

                &:not(.is-active) {
                    background-color: $white;
                    opacity: 0.35;
                }
            }
        }

        .Nav--socialMedia {
            @include position(relative, $z: 1);
            @include grid-area(2, 3, 2, 4);
            align-self: end;
            justify-self: end;
            opacity: 0.8;

            .Nav-list {
                align-items: center;
                margin-top: u($spacing-unit * -0.75);
            }

            .Nav-item {
                padding-top: u($spacing-unit * 0.75);
            }
        }

        .Slider-pagination,
        .Nav--socialMedia {

            @include mq($from: $viewport--md) {
                @include transition(#{opacity, transform}, 500ms);
                transition-delay: 1250ms;
                transform: translateX(100%);
                width: u(40);

                html:not(.is-loaded) & {
                    opacity: 0;
                    transform: translateX(200%);
                }
            }

            @include mq($from: $viewport--lg) {
                width: u(80);
            }
        }
    }
}
