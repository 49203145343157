/* Blocks */
.Block {
    padding-top: u($spacing-unit--lg);
    padding-bottom: u($spacing-unit--lg);

    @include mq($from: $viewport--md) {
        padding-top: u(120);
        padding-bottom: u(120);
    }

    &.is-white {
        background-color: $white;
    }

    &.is-grey {
        background-color: $cc-grey--light;

        .Site-Xperify & {
            background-color: $cc-xperify-grey--light;
        }
    }

    &.is-black {
        background-color: $black;
        color: $white;
    }

    .Site-Xperify & {
        &.Block--title {
            * {
                margin-bottom: 0;
            }

            &.is-grey {
                padding-bottom: u($spacing-unit--lg);
            }
        }

        &.Block--textMedia + .Block--textMedia.is-grey,
        &.Block--textMedia + .Block--Columns.is-grey,
        &.Block--Columns + .Block--textMedia.is-grey,
        &.Block--Columns + .Block--Columns.is-grey {
            padding-top: 0;
        }

        &:not(.Block--cta):not(.Block--banner-xperify):not(.Block--Quote).is-white + .Block.is-white {
            &:not(.Block--cta){
                padding-top: 0;
            }
        }

        &.Block--title.is-grey + .Block.is-grey {
            &:not(.Block--cta){
                padding-top: 0;
            }
        }

        &.Block--info.is-grey + .Block.is-grey {
            margin-top: u($spacing-unit--lg);

            @include mq($from: $viewport--md) {
                margin-top: u(120);
            }
        }

        &.Block--info.is-grey + .Block--Quote {
            margin-top: u($spacing-unit--lg);

            @include mq($from: $viewport--md) {
                margin-top: u(120);
            }
        }
    }

    .Site-Xperify .Container + & {
        &:not(.Block--cta){
            padding-top: 0;
        }
    }
}
