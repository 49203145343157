/* Objects: Structure */

// Namespace
$structure-namespace:                   $namespace !default;

// Widths & Heights
$structure-width:                        1090 !default;
$structure-width--xl:                    1600 !default;
$structure-width--lg:                    1265 !default;
$structure-width--md:                    $structure-width !default;
$structure-width--sm:                    1050 !default;
$structure-width--xs:                    870 !default;

$structure-gutter--l:                    80 !default;
$structure-gutter--r:                    $structure-gutter--l !default;

$structure-header-height:                118 !default;
$structure-header-gutter:                120 !default;

$structure-footer-height:                auto !default;
$structure-footer-gutter:                120 !default;

$structure-leftside-width:               100% / 3 !default;
$structure-rightside-width:              100% / 3 !default;
$structure-side-gutter:                  $spacing-unit--lg !default;

// Responsive
$structure-responsive-gutter--l:         20 !default;
$structure-responsive-gutter--r:         $structure-responsive-gutter--l !default;

$structure-responsive-breakpoint:        $structure-width + if($structure-responsive-gutter--r > 0 and $structure-gutter--r != $structure-responsive-gutter--r, $structure-gutter--r, 0) + if($structure-responsive-gutter--l > 0 and $structure-gutter--l != $structure-responsive-gutter--l, $structure-gutter--l, 0) !default;
