/* Lang */
.Nav--lang {
    // @include transition(#{opacity, transform}, 500ms, #{linear, cubic-bezier(.19,1,.22,1)});
    @include font-size(13, false);
    // @include grid-area(3, 4, 3, 4);
    // align-self: end;
    line-height: (16 / 13);
    // transition-delay: 600ms;

    // html:not(.show-nav) & {
    //     opacity: 0;
    //     transform: translateY(#{u($spacing-unit * -1)});
    // }

    // @include mq($from: $viewport--md) {
    //     @include grid-area(2, 3, 2, 3);
    // }

    // List
    .Nav-list {
        text-transform: uppercase;
    }

    // Item
    .Nav-item {

        & + .Nav-item {
            margin-left: u($spacing-unit--sm);
        }
    }

    // Link
    .Nav-link {
        color: currentColor;

        &.is-active {
            font-weight: 700;
        }
    }
}
