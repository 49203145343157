/* Heading */
h1, .#{$namespace}Alfa,
h2, .#{$namespace}Bravo,
h3, .#{$namespace}Charlie,
h4, .#{$namespace}Delta,
h5, .#{$namespace}Echo,
h6, .#{$namespace}Foxtrot {
    color: $cc-black;
    font-family: $font-sans;
    font-weight: 700;
    line-height: (55 / 45);

    .fonts-loaded & {
        font-family: $heading-font-stack;
    }

    .Site-Ocular & {
        strong {
            color: transparent;
            font-weight: inherit;
            text-shadow: -1px -1px 0 rgba($cc-black, .1), 1px -1px 0 rgba($cc-black, .1), -1px 1px 0 rgba($cc-black, .1), 1px 1px 0 rgba($cc-black, .1);
    
            @supports (-webkit-text-stroke: 1px black) {
                text-shadow: none;
                -webkit-text-stroke: px(1) $cc-black;
            }
        }
    }

    .Site-Xperify & {
        font-family: $font-montserrat;
        
        strong {
            font-weight: inherit;
            color: $cc-xperify-orange;
        }
    }

    small {
        font-weight: normal;
    }
}

h1, .#{$namespace}Alfa {
    // @include font-size($heading-size-1, $heading-spacing-1);
    @include fluid-type(font-size, $viewport--xs, $viewport--lg, $heading-size-1 * .6, $heading-size-1);
    letter-spacing: px(1.5);
    margin-bottom: u($spacing-unit);

    .Site-Xperify & {
        @include fluid-type(font-size, $viewport--xs, $viewport--lg, $heading-size-1--Xperify * .6, $heading-size-1--Xperify);
    }
}

h2, .#{$namespace}Bravo {
    // @include font-size($heading-size-2, $heading-spacing-2);
    @include fluid-type(font-size, $viewport--xs, $viewport--lg, $heading-size-2 * .75, $heading-size-2);
    letter-spacing: inherit;
    margin-bottom: u($spacing-unit);
    
    .Site-Xperify & {
        @include fluid-type(font-size, $viewport--xs, $viewport--lg, $heading-size-2--Xperify * .6, $heading-size-2--Xperify);
    }
}

h3, .#{$namespace}Charlie {
    @include font-size($heading-size-3, $heading-spacing-3);
    font-weight: 500;
    margin-bottom: u($spacing-unit--sm);
    line-height: 1.22;
    
    .Site-Xperify & {
        @include font-size($heading-size-3--Xperify);
        font-weight: 700;
    }
}

h4, .#{$namespace}Delta {
    @include font-size($heading-size-4, $heading-spacing-4);
    font-weight: 500;
    
    .Site-Xperify & {
        @include font-size($heading-size-4--Xperify);
        font-weight: 700;
    }
}

h5, .#{$namespace}Echo {
    @include font-size($heading-size-5, $heading-spacing-5);
    
    .Site-Xperify & {
        @include font-size($heading-size-5--Xperify);
        font-weight: 600;
    }
}

h6, .#{$namespace}Foxtrot {
    @include font-size($heading-size-6, $heading-spacing-6);
    
    .Site-Xperify & {
        @include font-size($heading-size-6--Xperify);
        font-weight: 600;
    }
}


// A series of classes for setting massive type; for use in heroes, mastheads,
// promos, etc.

.#{$namespace}Giga {
    // @include font-size($heading-size-giga, $heading-spacing-giga);
    @include fluid-type(font-size, $viewport--xs, $viewport--lg, $heading-size-giga * .5, $heading-size-giga);

    html:not(.site-Xperify) & {
        font-family: $font-sans;
    }
}

.#{$namespace}Mega {
    @include font-size($heading-size-mega, $heading-spacing-mega);
}

.#{$namespace}Kilo {
    @include font-size($heading-size-kilo, $heading-spacing-kilo);
}

.#{$namespace}Milli {
    @include font-size($heading-size-milli, $heading-spacing-milli);
}

.#{$namespace}Micro {
    @include font-size($heading-size-micro, $heading-spacing-micro);
}
