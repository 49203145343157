/* Header */
.Header {
    @include transition(#{background-color, box-shadow, color, transform});
    @include position(absolute, $top: 0, $right: 0, $left: 0, $z: 9999);
    background-color: $header-bg-color;

    .Site-Xperify & {
        background-color: $white;
    }

    color: $cc-black;
    padding-top: u($spacing-unit);
    padding-bottom: u($spacing-unit);

    @include mq($from: $viewport--md) {
        transition-delay: 1000ms;

        html:not(.is-loaded) .HomePage & {
            transform: translateY(-100%);
        }
    }

    > .Container {
        display: flex;
        align-items: center;

        > .Logo {
            margin-right: auto;
        }

        > .Nav--lang {
            margin-right: u($spacing-unit);
        }

        @include mq($from: $viewport--md) {

            > .Logo {
                margin-right: u($spacing-unit);
            }

            > .Nav--priority {
                margin-right: auto;
                margin-left: auto;
            }

            > .Nav--lang {
                margin-left: u($spacing-unit);
             }
        }
    }

    &--sticky {
        position: fixed;
        will-change: transform;
        transition-delay: 0s;

        &.is-pinned {
            transform: translateY(0%);
        }

        &.is-unpinned {
            transform: translateY(-100%);
        }

        &.is-notTop {
            box-shadow: 0 0 10px 0 rgba($black, 0.25);
        }
    }

    .show-nav &,
    &--black {
        background-color: $black;
        color: $white;
    }

    .show-nav.Site-Xperify &,
    .Site-Xperify &--black {
        background-color: $cc-xperify-grey--light;
        color: $cc-black;
    }

    &::before {
        @include transition(height, 500ms, ease-in-out);
        @include position(fixed, $top: 0, $right: 0, $left: 0, $z: -1);
        background-color: $black;

        .Site-Xperify & {
            background-color: $cc-xperify-grey--light;
        }

        content: '';
        height: 100vh;
        // transition-delay: 1s;

        // .show-nav & {
        //     transition-delay: 0s;
        // }

        html:not(.show-nav) & {
            height: 0;
        }
    }
}
