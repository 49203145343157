.Nav--services {

    .Nav-link {
        @include transition(#{color, text-shadow, padding-right, padding-left});
        display: inline-flex;
        align-items: center;
        white-space: nowrap;

        &:not(:hover):not(.is-active) {
            padding-right: u($spacing-unit * 1.5);
        }

        .Services-category:nth-child(even) & {

            @include mq($from: $viewport--md) {
                flex-direction: row-reverse;

                &:not(:hover):not(.is-active) {
                    padding-right: 0;
                    padding-left: u($spacing-unit * 1.5);
                }
            }
        }

        &,
        &:active,
        &:focus,
        &:hover {
            color: currentColor;
        }

        &.is-active {
            font-weight: 500;
            // text-shadow: 1px 0 0 currentColor;
        }
    }

    .Nav-label {

        &::after {
            display: block;
            content: attr(data-title);
            font-weight: 500;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }
    }

    .Nav-icon {
        @include transition(#{margin-left, opacity});
        display: block;
        flex-shrink: 0;
        text-align: left;
        width: u(30);

        .Services-category:nth-child(even) & {

            @include mq($from: $viewport--md) {
                transform: scale(-1, 1);
            }
        }
    }

    .Nav-link:not(.is-active) .Nav-icon {
        margin-left: u(-30);
        opacity: 0;
    }
}
