.Field {

    &:not(.Field--noGutter) + .Field {
        margin-top: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            margin-top: u(120);
        }
    }

    &-title {
        max-width: u(570);
    }

    &-body {
        max-width: u(725);

        :last-child {
            margin-bottom: 0;
        }
    }

    &-object {
        display: block;

        &:not(:only-child) {

            .Field--images & {
                padding-top: u($spacing-unit * 1.5);
                padding-left: u($spacing-unit * 1.5);

                &.object-fit-polyfill {
                    padding-top: 0;
                    padding-left: 0;
                    margin-top: u($spacing-unit * 1.5);
                    margin-left: u($spacing-unit * 1.5);
                }
            }

        }

        .Field--images &.object-fit-polyfill {
            height: u(554);
        }
    }

    &-objects {
        margin-top: u($spacing-unit * -1.5);
        margin-left: u($spacing-unit * -1.5);

        @include mq($from: $viewport--md) {
            display: flex;
        }

        &.is-full {

            .Field-image {
                @include dimensions(100%);
                max-height: u(554);
                object-fit: cover;
            }
        }

        @include mq($from: $viewport--md) {

            &.is-2by1 {

                .Field-object {

                    &:first-child {
                        width: 70%;
                    }

                    &:last-child {
                        width: 30%;
                    }
                }
            }

            &.is-1by2 {

                .Field-object {

                    &:first-child {
                        width: 30%;
                    }

                    &:last-child {
                        width: 70%;
                    }

                }
            }

            &.is-top {
                align-items: flex-start;
            }

            &.is-middle {
                align-items: center;
            }

            &.is-bottom {
                align-items: flex-end;
            }
        }
    }

    &-author {
        color: $black;
        font-size: (100% / 18 * 15);
        font-style: normal;
    }

    &--quote {
        @include position(relative, $z: 0);

        .Field-object {
            @include position(absolute, $top: 0, $z: -1);
            transform: translateX(-100%) translateX(#{u($spacing-unit)}) translateY(#{u($spacing-unit * -1)});
            opacity: 0.25;
        }
    }

    &--text {

        & + &,
        .Field--title + &,
        & + .Field--link {
            margin-top: u($spacing-unit--lg);
        }

        .Field-body {

            &.has-columns {
                column-count: 2;
                column-width: u(350);
                column-gap: u(90);
                max-width: none;

                .Field-title {
                    column-span: all;
                    max-width: u(900);
                    margin-bottom: u($spacing-unit--lg);
                }

                .Field-author {
                    column-span: all;
                    display: block;
                    margin-top: u($spacing-unit);
                }
            }
        }
    }
}
