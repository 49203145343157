.Block--Columns {
    padding-bottom: u($spacing-unit--lg - 60);

    .Columns-title {
        margin-bottom: u(80);
    }

    @include mq($from: $viewport--md) {
        padding-bottom: u(120 - 80);
    }

    .Grid-cell {
        margin-bottom: u(60);

        @include mq($from: $viewport--md) {
            margin-bottom: u(80);
        }
    }

    .Field-image {
        margin-bottom: u(30);
    }
}