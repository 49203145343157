.cookie-though {

    .Site-Ocular & {
        /* The colors used to style the modal */
        --ct-primary-400: #0026ff;
        --ct-primary-300: #101010;
        --ct-primary-200: #f7f7f7;
        --ct-primary-100: #f7f7f7;

        /* The colors used to style: customizationButton, headerDescription and cookiePolicy */
        --ct-text: #101010;

        /* The colors used to style the title elements */
        --ct-subtitle: var(--ct-primary-300);
        --ct-title: var(--ct-primary-400);

        /* The color used to style the cookie svg */
        --ct-cookie: var(--ct-primary-400);

        /* The colors used to style a policy */
        --ct-policy: var(--ct-white);
        --ct-policy-enabled: #f7f6f8;
        --ct-policy-text: var(--ct-text);
        --ct-policy-text-enabled: var(--ct-text);
        --ct-policy-essential: var(--ct-primary-100);

        /* The colors used to style the sliders */
        --ct-slider-primary: var(--ct-text);
        --ct-slider-secondary: #d1d5db;
        --ct-slider-enabled-primary: var(--ct-primary-400);
        --ct-slider-enabled-secondary: var(--ct-primary-400);

        /* The font color of the text inside a policy when it's enabled  */
        --ct-enabled-option-color: var(--ct-white);

        /* The white color */
        --ct-white: #ffffff;

        /* The margin underneath text elements */
        --ct-text-margin: #{u(4)};

        /* The padding of the modal and margin of the banner, toggleButton and customization */
        --ct-default-padding: #{u(16)};

        /* The padding/margin used to seperate the options and the permission buttons */
        --ct-seperator-padding: #{u(8)};

        /* The font size of the header title */
        --ct-heading-font-size: #{u(20)};

        /* The font size of the header sub title */
        --ct-sub-heading-font-size: #{u(14)};

        /* The font size of text */
        --ct-text-font-size: #{u(12)};

        /* The font size of the customize button and permission button labels */
        --ct-button-font-size: #{u(13)};

        /* The font used in the app */
        --ct-primary-font: inherit;

        /* The font used for header description and policy description */
        --ct-secondary-font: inherit;

        /* The z-index you want the root container to have */
        --ct-z-index: 9999;
    }

    .Site-Xperify & {
        /* The colors used to style the modal */
        --ct-primary-400: #ff8000;
        --ct-primary-300: #FF9933;
        --ct-primary-200: #F7F7F7;
        --ct-primary-100: #F7F7F7;

        /* The colors used to style: customizationButton, headerDescription and cookiePolicy */
        --ct-text: #101010;

        /* The colors used to style the title elements */
        --ct-subtitle: var(--ct-primary-300);
        --ct-title: var(--ct-primary-400);

        /* The color used to style the cookie svg */
        --ct-cookie: var(--ct-primary-400);

        /* The colors used to style a policy */
        --ct-policy: var(--ct-white);
        --ct-policy-enabled: #f7f6f8;
        --ct-policy-text: var(--ct-text);
        --ct-policy-text-enabled: var(--ct-text);
        --ct-policy-essential: var(--ct-primary-100);

        /* The colors used to style the sliders */
        --ct-slider-primary: var(--ct-text);
        --ct-slider-secondary: #d1d5db;
        --ct-slider-enabled-primary: var(--ct-primary-400);
        --ct-slider-enabled-secondary: var(--ct-primary-400);

        /* The font color of the text inside a policy when it's enabled  */
        --ct-enabled-option-color: var(--ct-white);

        /* The white color */
        --ct-white: #ffffff;

        /* The margin underneath text elements */
        --ct-text-margin: #{u(4)};

        /* The padding of the modal and margin of the banner, toggleButton and customization */
        --ct-default-padding: #{u(16)};

        /* The padding/margin used to seperate the options and the permission buttons */
        --ct-seperator-padding: #{u(8)};

        /* The font size of the header title */
        --ct-heading-font-size: #{u(20)};

        /* The font size of the header sub title */
        --ct-sub-heading-font-size: #{u(14)};

        /* The font size of text */
        --ct-text-font-size: #{u(12)};

        /* The font size of the customize button and permission button labels */
        --ct-button-font-size: #{u(13)};

        /* The font used in the app */
        --ct-primary-font: inherit;

        /* The font used for header description and policy description */
        --ct-secondary-font: inherit;

        /* The z-index you want the root container to have */
        --ct-z-index: 9999;
    }
}