.Block--info {
    @include position(relative, $z: 0);
    color: $cc-black;
    overflow: hidden;

    .Site-Xperify .ContactPage & {
        margin-bottom: u(80);
    }

    .ClipPath {
        height: 0rem;
        width: 0rem;
        display: block;
    }

    &:not(.is-black) + .Block {
        margin-top: 0;
    }

    @include mq($from: $viewport--md) {
        padding-top: u(80);

        .CasesDetailPage & {
            padding-top: u(50);
        }
    }

    &.is-black {
        color: $white;
        @include background-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250.35 251.31"><title>icon</title><path d="M126.91,245.41A118.17,118.17,0,1,1,245.08,127.25,118.3,118.3,0,0,1,126.91,245.41Zm0-225.69A107.53,107.53,0,1,0,234.44,127.25,107.65,107.65,0,0,0,126.91,19.72Zm5.25,231.59a113.84,113.84,0,0,1-94.67-50.66A113.81,113.81,0,0,1,113.1,2.41,113.9,113.9,0,0,1,245.69,146.14,114,114,0,0,1,132.16,251.31ZM43.86,206a111.8,111.8,0,0,0,198.73-50.84,114,114,0,0,1-105.34,72.53A113.8,113.8,0,0,1,43.86,206ZM39,199.32a111.39,111.39,0,0,0,74.82,28.78,112.75,112.75,0,0,0,13.2-.77A114,114,0,0,1,22.64,114.79,111.81,111.81,0,0,0,39,199.32ZM25,105.55c-.2,2.74-.3,5.52-.3,8.31A112,112,0,0,0,136.49,225.69H137A111.8,111.8,0,0,0,207,54.41,111.78,111.78,0,0,0,25,105.55ZM212.9,60.15a113.82,113.82,0,0,1-65.85,165,112.12,112.12,0,0,0,96.64-79.43q.3-4.12.3-8.31A111.46,111.46,0,0,0,212.9,60.15ZM103.31,4.93A111.78,111.78,0,0,0,33.12,193.57,113.81,113.81,0,0,1,23,105.18,114.13,114.13,0,0,1,103.31,4.93ZM208.53,53.08A113.61,113.61,0,0,1,246,136.53,111.92,111.92,0,0,0,123.29,2.8,113.94,113.94,0,0,1,208.53,53.08ZM113.32,4.44a112.16,112.16,0,0,0-87.26,91.7,113.8,113.8,0,0,1,176.1-48.43,111.73,111.73,0,0,0-88.3-43.27Z" fill="#{rgba(#fefefe, .1)}"/></svg>');
        background-size: u(559, 558);
        background-repeat: no-repeat;
        background-position: left u(-187) bottom u(-126);

        .CasesDetailPage & {
            padding-bottom: 0;
        }

        &::after {
            @include position(absolute, $right: 0, $bottom: 0, $left: 0, $z: -1);
            content: '';
            height: u(70);
        }

        &[data-next="grey"]::after {
            background-color: $cc-grey--light;
        }

        &[data-next="white"]::after {
            background-color: $white;
        }

        .ContactPage & {
            background-color: $black;
            padding-bottom: u(50);

            @include mq($from: $viewport--md) {
                padding-bottom: u(100);
            }

            &::after {
                content: none;
            }

            .Info-body {
                padding-bottom: 0;
                margin-bottom: 0;

                > :last-child {
                    margin-bottom: auto;
                }
            }
        }

        .Bg-title {
            @include position(absolute, $right: u($spacing-unit--lg * -1), $bottom: u(70));
        }
    }

    &:not(.is-black) {
        padding-bottom: 0;

        &::after {
            @include position(absolute, $right: 0, $bottom: 0, $left: 0, $z: -1);
            content: '';
            height: u(70);

            .Site-Xperify & {
                @include mq($from: $viewport--sm) {
                    height: u(42);
                    background-color: $white;
                }
            }
        }

        &[data-next="white"]::after {
            background-color: $white;
        }

        &[data-next="black"]::after {
            background-color: $black;
        }

        .Bg-title {
            @include position(absolute, $left: u($spacing-unit--lg * -1), $bottom: u(70));
            text-shadow: -1px -1px 0 rgba($cc-black, .1), 1px -1px 0 rgba($cc-black, .1), -1px 1px 0 rgba($cc-black, .1), 1px 1px 0 rgba($cc-black, .1);

            @supports (-webkit-text-stroke: 1px black) {
                text-shadow: none;
                -webkit-text-stroke: px(1) $cc-black;
            }
        }
    }
}

.Info {

    @include mq($from: $viewport--sm) {
        display: flex;

        .is-black & {
            flex-direction: row-reverse;
        }

        .Block--info:not(.is-black) & {
            margin-left: u($spacing-unit--lg * -1);
        }
    }

    &-title {
        color: currentColor;
        max-width: u(440);

        .is-black & {
            max-width: u(330);
        }

        @include mq($from: $viewport--sm) {
            margin-top: auto;
        }
    }

    &-buttons {
        display: flex;
        flex-wrap: wrap;
        margin-top: u($spacing-unit--sm * -1);
        margin-left: u($spacing-unit--sm * -1);
        padding-left: u($spacing-unit * 1.5 + 3px);

        .Site-Xperify & {
            padding-left: 0;
        }

        .Button {
            margin-top: u($spacing-unit--sm);
            margin-left: u($spacing-unit--sm);
        }
    }

    &-body,
    &-object {

        @include mq($from: $viewport--sm) {

            .Block--info:not(.is-black) & {
                padding-left: u($spacing-unit--lg);
            }
        }
    }

    &-object {
        .Block--info:not(.is-black) & {
            .Site-Xperify & {
                @include mq($from: $viewport--sm) {
                    padding-left: 0;
                }

                clip-path: url("#clip-info-media");
            }
        }
    }

    &-image-wrapper {
        @include mq($from: $viewport--sm) {
            padding-left: u($spacing-unit--lg);
        }
    }

    &-body {
        .Site-Xperify .ContactPage & {
            width: 100%;
        }

        .Block--info:not(.is-black) & {
            .Site-Xperify & {
                @include mq($from: $viewport--lg) {
                    padding-left: u(120);
                }
            }
        }

        margin-bottom: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            max-width: u(590);
        }

        .is-black & {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: auto;

            @include mq($from: $viewport--md) {

                .ContactPage & {
                    max-width: px(505);
                    width: 50%;
                }
            }
        }

        .Block--info:not(.is-black) & {
            margin-right: auto;

            @include mq($from: $viewport--md) {
                margin-top: u(80);
            }
        }

        @include mq($from: $viewport--sm) {
            margin-bottom: u(70);
            padding-bottom: u($spacing-unit--lg);
        }

        .Link {
            margin-top: auto;
        }
    }

    &-object {

        @include mq($until: $viewport--md - 1) {
            margin-top: u($spacing-unit--lg);

            .Site-Xperify & {
                margin-bottom: u($spacing-unit--lg);
            }
        }

        @include mq($from: $viewport--sm) {

            .is-black & {
                margin-right: u($spacing-unit--lg);
            }

            .Block--info:not(.is-black) & {
               align-self: flex-end;
            }
        }
    }

    .Info-Contact {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        @include font-size(15, false);
        @include mq($until: $viewport--sm - 1) {
            margin-top: u($spacing-unit);
        }
        margin-bottom: u($spacing-unit);
        width: 100%;


        &-item {
            @include mq($from: $viewport--sm) {
                width: 33.33%;
            }
            @include mq($until: $viewport--sm - 1) {
                width: 100%;
            }
            
            a:not([class]) {
                color: currentColor;
                text-decoration: none;
    
                &:hover {
                    text-decoration: underline;
                }
            }
    
            &:not(:last-child) {
                margin-bottom: u($spacing-unit);
            }

            &-label {
                font-weight: bold;
                opacity: 0.8;
                margin-bottom: u($spacing-unit--xs);
            }
        }
    }
}

.DL {
    @include font-size(15, false);
    margin-bottom: u($spacing-unit);
    max-width: u(430);
    width: 100%;

    .CasesDetailPage & {
        @include grid(1fr auto, repeat(3, auto));

        &-item {

            &--year {
                @include grid-area(2, 3, 1, 2);
                margin-left: u($spacing-unit);
            }

            &--ta {
                @include grid-area(1, 2, 1, 2);
            }

            &--challenge {
                @include grid-area(1, 3, 2, 3);
            }

            &--result {
                @include grid-area(1, 3, 3, 4);
            }
        }
    }

    .ContactPage & {
        column-count: 2;
        width: 100%;
    }

    &-item {

        a:not([class]) {
            color: currentColor;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        &:not(:last-child) {
            margin-bottom: u($spacing-unit);
        }
    }

    &-label {
        font-weight: bold;
        opacity: 0.8;
        margin-bottom: u($spacing-unit--xs);
    }
}
