.Filter {
    display: flex;
    margin-top: u(70);

    &-label {
        flex-shrink: 0;
        margin-right: u($spacing-unit--lg);
    }

    &-items {
        display: flex;
        flex-wrap: wrap;
        margin-top: u($spacing-unit--sm * -1);
        margin-left: u($spacing-unit--sm * -1);
    }

    &-item {
        font-weight: inherit;
        margin-top: u($spacing-unit--sm);
        margin-left: u($spacing-unit--sm);
    }
}
