/* Paragraph */
p {
    margin-bottom: u($line-height);

    &:only-of-type {
        margin-bottom: u($spacing-unit);
    }
}

.#{$namespace}Lead {
    color: $black;
    font-weight: 500;
    margin-bottom: u($line-height);
    // font-size: u($font-size * $paragraph-lead-modifier);
}
