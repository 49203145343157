.Block--carousel {

    .Container {

        @include mq($from: $viewport--md) {

            &::after {
                @include position(absolute, $top: 0, $bottom: 0, $z: 1);
                background-color: $cc-grey--light;
                content: '';
                left: calc(100% - #{u($structure-gutter--r / 2 + 45 + 70)});
                width: u($structure-gutter--r / 2 + 45 + 70);

                @include parent-nth-status(1, '.is-white', suffix) {
                    background-color: $white;
                }

                @include mq($from: $viewport--lg, $until: $structure-responsive-breakpoint - 1) {
                    left: calc(100% - #{u($structure-gutter--r + 45 + 70)});
                    width: u($structure-gutter--r + 45 + 70);
                }

                @include mq($from: $structure-responsive-breakpoint) {
                    left: calc(100% - #{u($structure-gutter--r + 45 + 70)});
                    width: calc((100vw - #{u($structure-width)}) / 2 + #{u(45 + 70)});
                }
            }
        }
    }
}

.Carousel {

    &-title {
        margin-bottom: u($spacing-unit--lg);
    }

    .Slider {
        width: 100%;

        &-buttons {
            @include position(relative, $z: 2);
        }

        &-item {
            user-select: none;
            width: auto;
        }
    }

    @include mq($from: $viewport--md) {
        display: flex;

        .Slider {
            overflow: visible;
            flex-grow: 1;

            &-buttons {
                align-self: center;
                padding-left: u(70);
            }
        }
    }

    &-object,
    &-image {
        display: block;
    }

    &-image {
        max-height: u(410);
    }
}
