.Text {

    @include mq($from: $viewport--md) {
        @include grid(1fr 1fr, 140px auto);

        &-title {
            @include grid-area(1, 2, 1, 3);
        }

        &-body {
            @include grid-area(2, 3, 2, 3);
        }
    }

    &-title {
        max-width: u(360);
        line-height: 1.22;

        @include mq($until: $viewport--md - 1) {
            margin-bottom: u($spacing-unit--lg);
        }
    }

    &-body {
        max-width: u(530);
    }

    &--vertical {
        max-width: u(720);

        .Text {
            &--title {
                margin-bottom: u(30);
            }
        }

        .Button {
            margin-top: u(10);
        }
    }
}
