.Block--related {
    background-color: $cc-black;
    color: $white;
    padding-top: u($spacing-unit--lg);
    padding-bottom: u($spacing-unit--lg);

    .Block + & {
        margin-top: 0;
    }

    @include mq($from: $viewport--md) {
        padding-top: u(120);
        padding-bottom: u(120);
    }
}

.Related {
    margin-top: u(70);

    @include mq($from: $viewport--md) {
        display: flex;

        .Slider {
            flex-grow: 1;

            &-buttons {
                align-self: center;
                margin-left: u(70);
            }
        }
    }

    &-title {
        color: currentColor;
    }
}
