.Block--list {
    @include position(relative, $z: 0);
    color: $white;
    padding-bottom: 0;

    &::after {
        @include position(absolute, $right: 0, $bottom: 0, $left: 0, $z: -1);
        background-color: $white;
        height: u(50);
        content: '';
    }

    &[data-next="white"]::after {
        background-color: $white;
    }

    &[data-next="grey"]::after {
        background-color: $cc-grey--light;
    }
}

.List {
    @include font-size(16, false);

    &-title,
    &-subtitle {
        color: currentColor;
    }

    &-header {
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-bottom: u($spacing-unit);
        user-select: none;
    }

    &-title {
        margin-bottom: u($spacing-unit--lg);
    }

    &-subtitle {
        @include font-size(21, false);
        margin-bottom: 0;

        .List-item:not(.is-active) & {
            color: $cc-grey;
        }
    }

    &-items {
        max-width: u(400);
    }

    &-item {

        + .List-item {
            margin-top: u($spacing-unit * 1.5);
        }

        &:not(.is-active) {

            .List-toggle,
            .List-icon {
                display: none;
            }
        }
    }

    &-icon {
        display: block;
        margin-right: u($spacing-unit--lg);
    }

    @include mq($until: $viewport--md - 1) {

        &-object {
            margin-top: u($spacing-unit--lg);
        }
    }

    @include mq($from: $viewport--sm) {
        display: flex;
        justify-content: space-between;
        margin-left: u($spacing-unit--lg * -1);

        .Block:nth-child(even) & {
            flex-direction: row-reverse;
        }

        &-object,
        &-body {
            padding-left: u($spacing-unit--lg);
            flex-shrink: 0;
            max-width: u(505 + 60);
            width: 50%;
        }

        &-object {
            align-self: flex-end;

            .List--landscape & {
                max-width: u(680 + 60);
            }
        }

        &-body {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding-bottom: u(50);

            .Button:not(.Button--outline) {
                margin-top: auto;
                transform: translateY(50%);
            }
        }
    }
}
