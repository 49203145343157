.Block--videoCarousel {
    .VideoCarousel-object {
        margin-top: u(60);

        @include mq($from: $viewport--md) {
            margin-top: u(80);
        }

        .Slider--videoCarousel {
            padding-left: u(80);
            padding-right: u(80 + 30);

            @include mq($until: $viewport--lg - 1) {
                padding-left: u(40);
                padding-right: u(40 + 30);
            }

            @include mq($until: $viewport--md - 1) {
                padding-left: u($spacing-unit);
                padding-right: u($spacing-unit + 10);
            }

            &-navigation {
                display: flex;
                justify-content: flex-end;
                padding-top: u(50);

                .Slider-button--prev {
                    margin-right: u(30);

                    .Slider-button-icon {
                        transform: scale(-1);
                    }
                }

                .Slider-button-icon {
                    display: block;
                }

                .Slider-button--prev,
                .Slider-button--next {
                    @include transition(opacity);
                }
                .is-disabled {
                    pointer-events: none;
                    opacity: 0.35;
                }
            }
        }

        .videoCarousel-item {
            &-object {
                position: relative;
                z-index: 1;
                margin-bottom: u($spacing-unit);
                padding-bottom: #{(225 / 396 * 100%)};

                video {
                    @include position(absolute, $top: 0, $left: 0);
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                &::before {
                    content: "";
                    @include position(absolute, $top: 0, $left: 0, $z: 2);
                    @include transition(opacity);
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba($cc-black, 0.9);
                    background-blend-mode: darken;
                }

                .Play-button {
                    @include position(absolute, $top: 50%, $left: 50%, $z: 2);
                    @include transition(fill);
                    transform: translate(-50%, -50%);
                    fill: $white;
                }
            }

            &:hover {
                .videoCarousel-item-object {
                    &::before {
                        opacity: 1;
                    }

                    .Play-button {
                        fill: $cc-xperify-pink;
                    }
                }
            }
        }
    }
}